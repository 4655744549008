import React, { useState } from "react";
import ModalComponent from "../ModalComponent";
import DisplayRawHtml from 'components/DisplayRawHtml/DisplayRawHtml';
import toast from "react-hot-toast";
import { useDeleteAppelOffreMutation , useUpdateAppelOffreMutation } from "../../../redux/api/appeloffres/appeloffreApi";
import { useDeleteProjectMutation , useUpdateProjectMutation } from "../../../redux/api/projects/projectApi";

import {
  ApplyButton,
  TitleJob,
  DatePost,
  StyleDescription,
  AllTags,
  Tag,
  ModalBodyStyle,
  StyleI,
  BudgetWrapperDescript,
  Budgetdesc2,
  Bloc3,
  BlocTitle,
  TitleDesription,
  TitleJobModalConfiramtion
} from "./ModalShowMyOffre.style";

const ModalShowMyOffre = (props) => {

  const [confirmationDelete ,SetConfirmationDelete] = useState(false);
  const [confirmationSuspend,SetConfirmationSuspend] = useState(false);
  const [deleteOffre] = useDeleteAppelOffreMutation();
  const [suspendOffre] = useUpdateAppelOffreMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const [suspendProject] = useUpdateProjectMutation();

  const handelCloseSuspend = () => {
    SetConfirmationSuspend(false);

   }

  const handelCloseDelete = () => {
    SetConfirmationDelete(false);
   }

   const openModalDelete = () => { 
    props.closeModal(false);
    SetConfirmationDelete(true);

   }

   const openModalSuspend = () => { 
    props.closeModal(false);
    SetConfirmationSuspend(true);

   }

  // Function to handle offer creation
  const HandleDeleteAppelOffre = async () => {
    try {
      if(props.offre.type === 'Contrat') {
 
      await deleteOffre(props.offre.id).unwrap(); // Handle promise
      props.refetch();
      toast.success("Offre was deleted", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); 
    
    } else {

        await deleteProject(props.offre.id).unwrap(); 
        toast.success("Project was deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      SetConfirmationDelete(false); // Reset or hide the modal upon success
      props.refetch(); // Refresh the project list
    } catch (e) {
      console.error("Error creating offre:", e); // Log error
      // Show specific error message if available
      const errorMessage = e.data?.error || "Please check the inputs";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Function to handle offer creation
 const HandleSuspendAppelOffre = async () => {
  try {
    if(props.offre.type === 'Contrat') {
    await suspendOffre(
      {
        id :props.offre.id,
        body: {
          status : "SUSPEND"
        },
      }
    ).unwrap(); // Handle promise
    props.refetch();
    toast.success("Project was suspended", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); }

    else {
      await suspendProject(
        {
          id :props.offre.id,
          body: {
            status : "SUSPEND"
          },
        }
      ).unwrap(); // Handle promise
      toast.success("Project was suspended", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
    SetConfirmationSuspend(false); // Reset or hide the modal upon success
    props.refetch(); // Refresh the project list
  } catch (e) {
    console.error("Error creating offre:", e); // Log error
    // Show specific error message if available
    const errorMessage = e.data?.error || "Please check the inputs";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};



const RenderHeader = ({props}) => {
  // Initialize variable to store the number of proposals based on conditions
  let proposalsCount = 0;

  if (props.offre.appeloffreProposals && props.offre.appeloffreProposals.length > 0) {
    proposalsCount = props.offre.appeloffreProposals.length; // Get length from appeloffreProposals
  } else if (props.offre.type === 'SHARETASKS' && props.offre.proposals.length > 0) {
    proposalsCount = props.offre.proposals.length; // Get length from proposals
  } else if (props.offre.type === 'COMPOSED_FREELANCE' && props.offre.proposalentreprises.length > 0) {
    proposalsCount = props.offre.proposalentreprises.length; // Get length from proposalentreprises
  }

  return (
    <BlocTitle>
      <TitleJob>{props.offre.title}</TitleJob>
      <BudgetWrapperDescript>
        <Budgetdesc2>Proposals: {proposalsCount > 0 ? proposalsCount : "0"}</Budgetdesc2>
      </BudgetWrapperDescript>
    </BlocTitle>
  );
};

  const RenderHeaderConfirmation = (
       <BlocTitle>
       <TitleJobModalConfiramtion>Confirmation</TitleJobModalConfiramtion>
      </BlocTitle>
  );

  const RenderBodyModalShowMyOffre = ( 
        <ModalBodyStyle>
          <StyleDescription> <DisplayRawHtml content={props.offre.description} />
          </StyleDescription>
          <AllTags>
                 {props.offre.skills.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                 ))}
          </AllTags>    
          <Bloc3>
            <DatePost>Posted: {props.offre.createdAt ? new Date(props.offre.createdAt).toDateString() : 'Date not available'}</DatePost>
            <StyleI>
              <i className="fa fa-map-marker icon"></i>
              {props.offre.location}
            </StyleI>
          </Bloc3>
          </ModalBodyStyle>
  );

  const RenderFooter = (
    <buttonFotterStyle>
      {props.offre.type === 'Contrat' ? (   
        <>
       <ApplyButton onClick={() => openModalDelete()}>
          Delete the offer
        </ApplyButton>

         <ApplyButton onClick={() => openModalSuspend()} >
          Suspend the offer
        </ApplyButton> </>
        ):(
          <>
          <ApplyButton onClick={() => openModalDelete()}>
              Delete the project
          </ApplyButton>

            <ApplyButton onClick={() => openModalSuspend()} >
              Suspend the project
            </ApplyButton> </>
        )}    
    </buttonFotterStyle>
  );



  const RenderBodyConfirmationDelete = (
        <ModalBodyStyle>    
          Did you confirm the Delete ?   
        </ModalBodyStyle>  
  );

  const RenderBodyConfirmationSuspend = (
    <> 
        <ModalBodyStyle>
          Did you confirm the Suspend ?   
         </ModalBodyStyle>
    </>
  );

  const RenderFooterDelete = (
    <buttonFotterStyle>     
       <ApplyButton onClick={() => HandleDeleteAppelOffre()} >
       Yes , i delete 
        </ApplyButton>

         <ApplyButton onClick={() => handelCloseDelete()}>
         No
        </ApplyButton>      
    </buttonFotterStyle>
  );
  const RenderFooterSuspend = (
    <buttonFotterStyle>     
       <ApplyButton onClick={() => HandleSuspendAppelOffre()}  >
          Yes , i suspend 
        </ApplyButton>

         <ApplyButton onClick={() => handelCloseSuspend()}>
          No
        </ApplyButton>
       
    </buttonFotterStyle>
  );


  return (
    <>
      <ModalComponent
        show={props.confirmShow}
        closeModal={props.closeModal}
        body={RenderBodyModalShowMyOffre}
        header={<RenderHeader props={props} />}
        footer={RenderFooter}
        footerpaddingtop={"10px"}
        footerpaddingbottom={"10px"}
        bodyPadding={"15px 10px 0px 10px"}
        minWidth={"61vw"}
        Height={"84vh"}
      />

     {confirmationDelete && ( 
        <ModalComponent
          show={openModalDelete}
          closeModal={handelCloseDelete}
          body={RenderBodyConfirmationDelete}
          header={RenderHeaderConfirmation}
          footer={RenderFooterDelete}
          minWidth={"61vw"}
          footerpaddingtop={"1px"}
          footerpaddingbottom={"1px"}
        />
      )}
      {confirmationSuspend && ( 
        <ModalComponent
          show={openModalSuspend}
          closeModal={handelCloseSuspend}
          body={RenderBodyConfirmationSuspend}
          header={RenderHeaderConfirmation}
          footer={RenderFooterSuspend}
          minWidth={"61vw"}
          footerpaddingtop={"1px"}
          footerpaddingbottom={"1px"}
        />
      )}
    </>
  );
};


export default ModalShowMyOffre;
