import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../core/constants/endpoints";
import { getAccessToken } from "../../../core/helpers/storage";
import { baseQueryConfig } from "../BaseQueryConfig";

const customBaseQuery = fetchBaseQuery({
  ...baseQueryConfig,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const trainingApi = createApi({
  reducerPath: "trainingApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    // UPDATE ENTREPRISE
    addTraining: builder.mutation({
      query: (trainingData) => ({
        url: ENDPOINTS.PRODUCTS,
        method: "POST",
        body: trainingData,
      }),
      invalidatesTags: ["Product"],
    }),
    updateTraining: builder.mutation({
      query: ({ id, trainingData }) => ({
        url: `${ENDPOINTS.PRODUCTS}/${id}`,
        method: "PATCH",
        body: trainingData,
      }),
      providesTags: ["Product"],
    }),
    // Get Trainings
    getTrainings: builder.query({
      query: ({ params, type } = {}) => {
        let url = ENDPOINTS.PRODUCTS;
        if (params) {
          url += params;
        }
        if (
          type &&
          ["VIDEOSTRAINING", "APPLICATION", "CLOUD", "APPELOFFRE"].includes(
            type
          )
        ) {
          url += params ? `&type=${type}` : `?type=${type}`;
        }
        return { url };
      },
    }),

    getTrainingById: builder.query({
      query: ({ id, type }) => {
        let url = `${ENDPOINTS.PRODUCTS}/${id}`;
        if (
          type &&
          ["VIDEOSTRAINING", "APPLICATION", "CLOUD", "APPELOFFRE"].includes(
            type
          )
        ) {
          url += `?type=${type}`;
        }
        return { url };
      },
    }),
    deleteTraining: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.PRODUCTS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

// Export hooks for using the API endpoints
export const {
  useAddTrainingMutation,
  useGetTrainingsQuery,
  useGetEntreprisesQuery,
  useUpdateEntrepriseMutation,
  useDeleteTrainingMutation,
  useGetTrainingByIdQuery,
  useUpdateTrainingMutation,
} = trainingApi;
