import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const typing = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const blinkCaret = keyframes`
  from, to { border-color: transparent; }
  50% { border-color: black; }
`;

export const ChatbotContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const ChatbotMessage = styled.div`
  white-space: pre-wrap;
  margin-left: 10px;
  animation: ${blinkCaret} 15s step-end infinite;
`;
