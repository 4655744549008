import { Modal } from "react-bootstrap"; // Ensure you have react-bootstrap installed
import React from "react";
import {OptionCard ,OptionContainer, StyledModalBody} from "./ModalShowChoiceCandidat.style"

const ModalShowChoiceCandidat = (props) => {
    const handleChoice = (choice) => {
        console.log(`You selected: ${choice}`);

        if (choice === 'Apply for a job') {
            window.location.href = `/projects`;            
        }
        if (choice === 'Buy a Product') {
            window.location.href = `/products`;            
        }

     };

    return (
        <Modal show={props.show} onHide={props.onHide} centered={true}  size="lg">
             <StyledModalBody>
                <OptionContainer>
                    <OptionCard onClick={() => handleChoice('Apply for a job')}>
                        <h3>Apply for a job</h3>
                    </OptionCard>
                    <OptionCard onClick={() => handleChoice('Buy a Product')}>
                        <h3>Buy a Product</h3>
                    </OptionCard>
                    <OptionCard onClick={() => handleChoice('Share a product')}>
                        <h3>Share a product</h3>
                    </OptionCard>
                    <OptionCard onClick={() => handleChoice('ITgalaxy Marketplace')}>
                        <h3>ItGalaxy Marketplace</h3>
                    </OptionCard>
                </OptionContainer>
            </StyledModalBody>
        </Modal>
    );
};


export default ModalShowChoiceCandidat;



