import React, { useEffect, useState } from "react";
import { StyledContainerProfile, StyledContainerWrapper } from "./styled";
import EditProfil from "../../components/ComponnentProfilItems/EditProfil";
import SideBar from "../../components/SideBar/sideBar";
import { getTokenFromLocalStorage } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useGetUserByIdQuery } from "../../redux/api/users/userApi";
import { BlocShowJob }  from "components/ModalITgalaxy/ModalApply/ModalApply.style";


const Profil = () => {
  const token = getTokenFromLocalStorage();
  const decodeToken = token ? jwtDecode(token) : null;
  const role = decodeToken ? decodeToken.role : null;
  const id = decodeToken ? decodeToken.id : null;

  // Use the correct parameters for the query
  const { data: user, isLoading, error } = useGetUserByIdQuery({ role, id });
  const [dataUser, setDataUser] = useState(null);

  // Setting user data based on fetched data
  useEffect(() => {
    if (user?.data?.length) {
      setDataUser(user.data[0]);
    }
  }, [user]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", overflow: "hidden", minHeight: "100vh" }}>
      <SideBar path={'/profil'} isLoggedIn={true} role={role} id={id} />
      <StyledContainerWrapper>
        <StyledContainerProfile>
          {isLoading && <div>Loading...</div>}
          {error && <div>Error fetching user data: {error.message || "Unknown error"}</div>}
          {dataUser ? (
          <BlocShowJob>
            <EditProfil 
              isLoggedIn={true} 
              role={role} 
              id={id} 
              setDataUser={setDataUser} 
              editProfil={true} 
              userData={dataUser} 
            />
           </BlocShowJob>
          ) : (
            <div>No user data found.</div>
          )}
        </StyledContainerProfile>
      </StyledContainerWrapper>
    </div>
  );
};

export default Profil;
