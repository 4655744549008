import { Button } from "react-bootstrap";
import styled from "styled-components";

export const FlexCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InlineFlexContainer = styled.div`
  display: inline-flex;
`;
export const InlineBlockW45PContainer = styled.div`
  display: inline-block;
  width: 60%;
`;
export const MarginBottom20PxContainer = styled.div`
  margin-bottom: 20px;
`;
export const FontSize30PXContainer = styled.b`
  font-size: 30px;
`;
export const FontSize20PxGrayContainer = styled.b`
  font-size: 20px;
  color: gray;
`;
export const SelectedItemContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  height: 40vh;
  width: 70%;
  margin-left: 2%;
  color: "white";
`;

export const ItemContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  height: 40vh;
  width: 70%;
  margin-left: 2%;
`;
export const Padding10PxContainer = styled.p`
  padding: 10%;
`;
export const FontSize40PxContainer = styled.b`
  font-size: 40px;
`;
export const FontSize25Px495057Color = styled.p`
  font-size: 25px;
  color: #495057;
`;
export const FontSize20PxContainer = styled.p`
  font-size: 20px;
`;
export const BorderedFWPad2BR10Container = styled.div`
  border: 2px solid;
  width: 50%;
  padding: 2%;
  border-radius: 20px;
  margin: 2%;
`;
export const ArrowBlock = styled.div`
  margin-left: 2rem;
`;
export const CenteredH5vhContainer = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  max-height: 8vh;
  width: auto;
  margin-bottom: 10px;
  border: 1px solid #51bcf9;
  background-color: #009ff5;
`;
export const InlineFlexFwMt30px = styled.div`
  display: inline-flex;
  width: 100%;
  margin-top: 30px;
`;
export const InlineBlockContainer = styled.div`
  display: inline-block;
`;
export const ButtonGroupsContainer = styled.div`
  display: inline-block;
  border: 1px solid;
  border-radius: 10px;
  height: 100vh;
  margin-left: 20px;
  width: 55%;
  margin-top: 6%;
  padding: 2%;
`;
