import PaginatedComponent from 'components/PaginationComponent/PaginationComponent';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import SideBar from '../SideBar/sideBar';
import { getAccessToken } from '../../core/helpers/storage';
import { useGetUserByIdQuery } from '../../redux/api/users/userApi';
import {
  BackCard,
  CardBalance,
  CardContainer,
  CardDetails,
  CardInfo,
  FrontCard,
  Header,
  InvoicingLink,
  StatusBadge,
  StyledTransactionsContainer,
  StyledYourCardContainer,
  TotalBalance,
  TransactionRow,
  TransactionsTable,
  WalletPageContainer,
  WithdrawButton
} from './WalletComponnents.style';

const WalletTransactionComponnents = () => {
  const [token, setToken] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      setToken(token);
      if (typeof token === 'string') {
        try {
          const decodedToken = jwtDecode(token);
          setUserToken(decodedToken);
        } catch (error) {
          console.error('Invalid token', error);
        }
      }
    };

    fetchToken();
  }, []);

  const { data: user } = useGetUserByIdQuery(userToken?.id, {
    skip: !userToken,
  });

  if (!token || !userToken) {
    return <div>Loading...</div>;
  }

  const transactions = [
    { num: 1, date: '13/05/2022', amount: '$4.95', mode: 'Transfer Bank', status: 'Delivered', invoicing: 'Click for detail' },
    { num: 2, date: '22/05/2022', amount: '$8.95', mode: 'Cash on Delivery', status: 'Delivered', invoicing: 'Click for detail' },
    { num: 3, date: '15/06/2022', amount: '$1,149.95', mode: 'Cash on Delivery', status: 'Process', invoicing: 'Click for detail' },
    { num: 4, date: '06/09/2022', amount: '$899.95', mode: 'Transfer Bank', status: 'Process', invoicing: 'Click for detail' },
    { num: 5, date: '25/09/2022', amount: '$22.95', mode: 'Cash on Delivery', status: 'Canceled', invoicing: 'Click for detail' },
    { num: 6, date: '04/10/2022', amount: '$54.95', mode: 'Transfer Bank', status: 'Delivered', invoicing: 'Click for detail' },
    { num: 7, date: '17/10/2022', amount: '$174.95', mode: 'Transfer Bank', status: 'Delivered', invoicing: 'Click for detail' },
    { num: 8, date: '24/10/2022', amount: '$249.95', mode: 'Transfer Bank', status: 'Delivered', invoicing: 'Click for detail' },
    { num: 9, date: '09/11/2022', amount: '$899.95', mode: 'Transfer Bank', status: 'Canceled', invoicing: 'Click for detail' },
    { num: 10, date: '22/11/2022', amount: '$6.94', mode: 'Transfer Bank', status: 'Process', invoicing: 'Click for detail' },
    { num: 11, date: '22/11/2022', amount: '$6.94', mode: 'Transfer Bank', status: 'Process', invoicing: 'Click for detail' }
  ];

  // Filter transactions based on search term
  const filteredTransactions = transactions.filter((transaction) =>
    transaction.mode.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedTransactions = filteredTransactions.slice(startIndex, startIndex + itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <WalletPageContainer>

      <StyledTransactionsContainer>
        <div className="table-header">
          <div className="show-selector">
            <label>Show</label>
            <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="explore-btn">Explore</button>
          </div>
        </div>
        <TransactionsTable>
          <thead>
            <tr>
              <th>Num</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Payment Mode</th>
              <th>Status</th>
              <th>Invoicing</th>
            </tr>
          </thead>
          <tbody>
            {displayedTransactions.map((transaction, index) => (
              <TransactionRow key={index}>
                <td>{transaction.num}</td>
                <td>{transaction.date}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.mode}</td>
                <td>
                  <StatusBadge status={transaction.status}>
                    {transaction.status}
                  </StatusBadge>
                </td>
                <td>
                  <InvoicingLink href="#">{transaction.invoicing}</InvoicingLink>
                </td>
              </TransactionRow>
            ))}
          </tbody>
        </TransactionsTable>

        {/* Pagination component */}
        <PaginatedComponent
          itemsPerPage={itemsPerPage}
          totalItems={filteredTransactions.length}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </StyledTransactionsContainer>
    </WalletPageContainer>
  );
};

export default WalletTransactionComponnents;
