import SideBar from "components/SideBar/sideBar";
import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formations } from "utils/fake_data";
import sampleVideo from '../../assets/SampleVideo_1280x720_1mb.mp4';
import ChapterDetails from '../../components/ChapterDetails/ChapterDetails';
import ChaptersCard from '../../components/ChaptersCard/ChaptersCard';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { chaptersData } from './MyFormationDetail.data';
import { BackButton, StyledFormationDetailContainer, StyledFormationDetailContentContainer } from './MyFormationDetail.style';

const FormationDetail = () => {
  const { id } = useParams();
  const [formation, setFormation] = useState(null);
  const token = getAccessToken();
  const decodedToken = token ? jwtDecode(token) : null;
  // useEffect(() => {
  //   // Fetch formation data based on the id
  //   const fetchFormation = async () => {
  //     try {
  //       const response = await fetch(`/api/formations/${id}`);
  //       const data = await response.json();
  //       setFormation(data);
  //     } catch (error) {
  //       console.error("Failed to fetch formation:", error);
  //     }
  //   };

  //   fetchFormation();
  // }, [id]);
  useEffect(() => {
    setFormation(formations.find((formation) => formation.id === parseInt(id)));
  }, [id]);

  const handleBackButton = () => {
    window.history.back();
  };

  if (!formation) {
    return <p>Loading formation details...</p>;
  }

  return (
    <StyledFormationDetailContainer>
      <SideBar path={'/formations'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
      <StyledFormationDetailContentContainer>
        <BackButton onClick={handleBackButton}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </BackButton>
        <div style={{ width: "100%", display: 'flex' }}>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <VideoPlayer src={sampleVideo} />
            <ChapterDetails />
          </div>
          <ChaptersCard chapters={chaptersData} />
        </div>
      </StyledFormationDetailContentContainer>
    </StyledFormationDetailContainer>
  );
};

export default FormationDetail;
