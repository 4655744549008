import { useState } from "react";
import CustomText from "../CustomText/CustomText";
import CustomPaginator from "./CustomPaginator/CustomPaginator.jsx";
import {
  ButtonStyle,
  RootStyle,
  RowStyle,
  StackStyle,
} from "./Formations.style";
import FormationsCard from "./FormationsCard/FormationsCard";
import {formations} from "../../../utils/fake_data.js";
function Formations() {
  const [page, setPage] = useState(1);
  const [animateDirection, setAnimateDirection] = useState("inRight");


  return (
    <RootStyle spacing={5}>
      <CustomText
        headingText={"Explore our featured training courses!"}
        subHeadingText={"Browse our training courses"}
        supportingText={`Sign up for our most popular class, and gain
          essential knowledge that will be very useful to you.`}
      />

      <StackStyle style={{ alignItems: "center" }} spacing={5}>

        <RowStyle spacing={3}>
          {formations?.map((value) => (
            <FormationsCard
              key={value.id}
              formation={value}
              animateDirection={animateDirection}
            />
          ))}
        </RowStyle>

        <CustomPaginator
          formationsLength={formations?.pagination?.totalPages}
          page={page}
          setAnimateDirection={setAnimateDirection}
          setPage={setPage}
        />
        
      </StackStyle>
    </RootStyle>
  );
}

export default Formations;
