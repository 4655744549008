import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../core/constants/endpoints";
import { baseQueryConfig } from "../BaseQueryConfig";
import { getAccessToken } from "../../../core/helpers/storage";

const customBaseQuery = fetchBaseQuery({
  ...baseQueryConfig,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const soldedProductsApi = createApi({
  reducerPath: "soldedProductsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    getSoldedProducts: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS.SOLDED_PRODUCTS}/${params}`,
      }),
    }),
  }),
});

export const { useGetSoldedProductsQuery } = soldedProductsApi;
