import { CDBSidebarMenuItem } from "cdbreact";
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import { TopContainer } from "components/DashboardTrainerComponnents/TrainerComponnents/ListTrainings/ListTrainings.style";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formConfig } from "./GenericSwitcherLayout.config";
import {
  ButtonStyle,
  Container,
  ContainerWrapper,
  RowStyle,
  SearchBar,
  StyledSearchBarContainer,
  Wrapper
} from "./GenericSwitcherLayout.style";

const GenericSwitcherLayout = (props) => {
  const [selectedElement, setSelectedElement] = useState(0);
  const getChildrenByContent = () => {
    return props?.items[props.selectedItemHeader]?.children;
  };
  const [animateDirection, setAnimateDirection] = useState("inRight");

  const navigate = useNavigate();
  const handleSelect = (e, index) => {
    e.preventDefault();
    props.setSelectedItemHeader(index);
  };

  const formMethods = useForm({});
  const type = formMethods.watch("type");
  useEffect(() => {
    if (type && type !== props.type) {
      props.setType(type);
    } else if (props.type) {
      formMethods.setValue("type", props.type);
    }
    console.log("type", type);
  }, [type]);

  const handleNavigate = (id) => {
    if (id) {
      if (props.type === "Video trainings") {
        if (props.selectedItemHeader === 0) {
          navigate(`/training/${id}`, { replace: true });
        } else if (props.selectedItemHeader === 1) {
          navigate(`/my-training/${id}`, { replace: true });
        }
      } else {
        if (props.selectedItemHeader === 0) {
          navigate(`/application/${id}`);
        } else if (props.selectedItemHeader === 1) {
          navigate(`/my-application/${id}`);
        }
      }
    }
  }

  if (props.isLoading) {
    return <div>Loading...</div>;
  }

  if (props.error) {
    return <div>Error loading data: {props.error.message}</div>;
  }

  return (
    <Wrapper>
      <ContainerWrapper className="container">
        <SearchBar>
          <FormProvider {...formMethods}>
            <StyledSearchBarContainer childCount={props.userRole === "CANDIDAT" ? 4 : 3}>
              <GenericInput
                inputObject={{
                  ...formConfig.title,
                  label: "filterType"
                }}
                onChange={(e) => props.setType(e.target.value)}
                disabledForm={false}
              />
              <GenericInput
                inputObject={{
                  ...formConfig.skills,
                  label: "filterType"
                }}
                disabledForm={false}
              />
              <GenericInput
                inputObject={{
                  ...formConfig.type,
                  label: "filterType"
                }}
                disabledForm={false}
              />
              {props.userRole === "CANDIDAT" && (
                <Button style={{ backgroundColor: "black", border: "none", width: "fit-content" }}>
                  {type === "Video trainings" ? "Create Training" : "Create Application"}
                </Button>
              )}
            </StyledSearchBarContainer>
          </FormProvider>
        </SearchBar>
        <TopContainer>
          <Container>
            {props?.items?.map((item, index) => (
              <ButtonStyle
                key={index}
                selected={
                  props.isSelected === true
                    ? props.selectedItemHeader === index
                    : false
                }
                onClick={(e) => handleSelect(e, index)}
              >
                <CDBSidebarMenuItem>
                  {item.label}
                </CDBSidebarMenuItem>
              </ButtonStyle>
            ))}
          </Container>
          <RowStyle spacing={3}>
            {props.data?.map((value) => (
              <FormationsCard
                key={value.id}
                formation={{
                  id: value.id,
                  title: value.title,
                  description: value.description,
                  image: value.image || value.thumbnail,
                  price: value.price,
                  // Add any other properties your FormationsCard expects
                }}
                animateDirection={animateDirection}
                category={type}
                onClick={() => handleNavigate(value.id)}
                onDelete={() => props.handleDelete && props.handleDelete(value.id)}
              />
            ))}
          </RowStyle>
        </TopContainer>
      </ContainerWrapper>
    </Wrapper>
  );
};
export default GenericSwitcherLayout;
