import { CDBSidebarMenuItem } from "cdbreact";
import { useEffect, useState, useCallback } from "react";
import ChatbotTextEffect from "../ChatbotTextEffect/ChatbotTextEffect";
import { FormItemChatbot } from "./FormItemChatBot/FormItemChatbot";
import {
  InlineBlockMarginTop20PxContainer,
  InlineFlexContainer,
  MarginLeft40PxFullWidthInlineBlockContainer,
  GlobalChatBotContainer
} from "./GenericFormChatbot.style";

import chatBotIcon from "../../assets/images/chatbox.png";


export const GenericFormChatbot = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationStep, setAnimationStep] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [textData, setTextData] = useState();
  const [textTyped, setTextTyped] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    skills: [],
  });
 
  const [shownItems, setShownItems] = useState([props.items[0]]);

  useEffect(() => {
    const formValuesArray = Object.keys(formValues).map(
      (key) => formValues[key]
    );
    const value = formValuesArray[currentIndex - 1];

    if (
      (animationComplete &&
        currentIndex - 1 < formValuesArray.length &&
        value !== undefined &&
        value !== null &&
        value !== "" &&
        !Array.isArray(value)) ||
      (Array.isArray(value) && value.length > 0)
    ) {
      setShownItems((prevItems) => [...prevItems, props.items[currentIndex]]);
      setCurrentIndex(currentIndex + 1);
      setTextData(undefined);
      if (animationStep === 3) {
        setShownItems([props.items[0]]);
      }
    }
  }, [currentIndex, props.items]);

  
  useEffect(() => {
    if (animationStep === 3) {
      setShownItems([props.items[0]]);
    }
  }, [animationStep]);
  
  const handleFormValuesChange = useCallback((newValues) => {
    setFormValues((prev) => ({ ...prev, ...newValues }));
  }, []);

  const handleNext = useCallback(() => {
    if (currentIndex < props.items.length - 1) {
      setShownItems((prevItems) => [
        ...prevItems,
        props.items[currentIndex + 1],
      ]);
      setCurrentIndex(currentIndex + 1);
    }
  }, [animationComplete, currentIndex, textData, props.items]);

  const handleFirstAnimationComplete = () => {
    setAnimationStep(1);
  };

  const handleSecondAnimationComplete = () => {
    setAnimationStep(2);
  };

  const handleThirdAnimationComplete = () => {
    setAnimationStep(3);
  };

  return (
    <GlobalChatBotContainer>
      <InlineFlexContainer>
        <CDBSidebarMenuItem
          onClick={() => props.setSelected(false)}
          icon="fa-solid fa-chevron-left"
        />
        <img
          src={chatBotIcon}
          style={{ marginLeft: "40px" }}
          width={100}
          height={80}
          alt="image"
        />
        <MarginLeft40PxFullWidthInlineBlockContainer>
          <h1>
            <b>
              {animationStep >= 0 && (
                <ChatbotTextEffect
                  message="Hi, I'm Anakin"
                  onComplete={handleFirstAnimationComplete}
                />
              )}
            </b>
          </h1>
          {animationStep >= 1 && (
            <p style={{ width: "60%" }}>
              <ChatbotTextEffect
                message="To get started, let's define your search criteria together in different steps"
                onComplete={handleSecondAnimationComplete}
                delay={500}
              />
            </p>
          )}
        </MarginLeft40PxFullWidthInlineBlockContainer>
      </InlineFlexContainer>
      <br />
      <hr />
      {animationStep >= 2 && (
        <InlineBlockMarginTop20PxContainer>
          <div>
            {shownItems.map((item, index) => (
              <div key={index}>
                <FormItemChatbot
                  formValues={formValues}
                  setFormValues={setFormValues}
                  img={item?.img}
                  index={index} // Use the map index instead of currentIndex
                  items={props.items}
                  name={item?.name}
                  placeholder={item.placeholder}
                  textTyped={textTyped}
                  setTextTyped={setTextTyped}
                  textData={textData}
                  setTextData={setTextData}
                  title={item?.title}
                  chattype={props.selectedOption}
                  onNext={handleNext}
                  question={item?.question}
                  onComplete={handleThirdAnimationComplete}
                />
                <br />
              </div>
            ))}
          </div>
        </InlineBlockMarginTop20PxContainer>
      )}
    </GlobalChatBotContainer>
  );
};
