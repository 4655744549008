import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainerStyle = styled(Button)`
  border-radius: 10px;
`;

export const VerticalLine = styled.div`
  border-left: 2px solid black;
  height: 100vh;
  margin: 20px;
`;

export const ButtonStyle = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  font-weight: ${({ selected }) => (selected ? "600" : "400")}; // bold if selected
  color: ${({ selected }) => (selected ? "#000000" : "#A0A0A0")}; // black if selected, gray otherwise
  margin-right: 20px; // space between buttons
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${({ selected }) => (selected ? "#000000" : "transparent")}; // underline if selected
  }

  &:hover {
    color: #000000; // turn black on hover
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  min-height: 100vh; /* Full height to avoid padding issues */
  background: #f1f1fd; /* Background color */
  width: 100%; /* Full width */

`;

export const ContainerWrapper = styled.div`
  padding-top: 30px;
  width: 100%;
  max-width: 100%; /* Prevents any max width constraints */
  padding-left: 150px;
`;

export const SearchBar = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0; /* Top and bottom margin for spacing */
`;

export const StyledSearchBarContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 cards per row */
  gap: 16px; /* Space between cards */
  margin-top: 40px; /* Space from search bar */
  padding-left : 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row on medium screens */
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on mobile screens */
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr; /* 1 card per row on very small screens */
  }
`;


export const CreateTrainingButtonContainer = styled.button`
    background-color: black;
    height: 40px;
    margin-top: 2px;
    width: 250px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    gap: 15px;
    margin-left: 20px;

  &:hover {
    border: none;
    background-color: green; // Keep hover background color
  }
`;

export const SearchFilter = styled.div`
    padding-left: 10px;
    width: 250px;
    display: flex;
    height:  41px;
`;

export const SearchFilterBar  = styled.div`
    width: 600px;
    display: flex;
    height:  45px;
`;

export const SearchFilterSkills = styled.div`
    padding-left: 10px;
    width: 180px;
    display: flex;
    padding-top: 4px;

`;
