export const VIDEO_TRAINING = "Video training";
export const APPLICATION = "Application";
export const VIDEO_TRAINING_ITEMS = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "Video training Description" },
  {
    id: 3,
    title: "Videos training content",
    subModules: [], // Initially empty to allow dynamic additions
  },
  { id: 4, title: "Videos training price" },
  { id: 5, title: "Publish your Videos training" },
];

export const APPLICATION_ITEMS = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "Application Description" },
  { id: 3, title: "Application content" },
  { id: 4, title: "Application price" },
  { id: 5, title: "Publish your Application" },
];
