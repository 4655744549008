import { Modal } from "react-bootstrap"; // Ensure you have react-bootstrap installed
import React from "react";
import {OptionCard ,OptionContainer, StyledModalBody} from "./ModalShowChoiceRecruter.style"

const ModalShowChoiceRecruter = (props) => {
    const handleChoice = (choice) => {
        console.log(`You selected: ${choice}`);

        if (choice === 'Jobs') {
            window.location.href = `/projects`;            
        }
        if (choice === 'ItGalaxy Contactor') {
            window.location.href = `/itgalaxycontactor`;            
        }
        if (choice === 'ItGalaxy Recrutments') {
            window.location.href = `/itgalaxyrecruitment`;            
        }
     };

    return (
        <Modal show={props.show} onHide={props.onHide} centered={true}  size="lg">
             <StyledModalBody>
                <OptionContainer>
                    <OptionCard onClick={() => handleChoice('Jobs')}>
                        <h3>Jobs</h3>
                    </OptionCard>
                    <OptionCard onClick={() => handleChoice('ItGalaxy Contactor')}>
                        <h3>ItGalaxy Contactor</h3>
                    </OptionCard>
                    <OptionCard onClick={() => handleChoice('ItGalaxy Recrutments')}>
                        <h3>ItGalaxy Recrutments</h3>
                    </OptionCard>
                </OptionContainer>
            </StyledModalBody>
        </Modal>
    );
};


export default ModalShowChoiceRecruter;



