import styled from "styled-components";
export const FlexContainer = styled.div`
  display: flex;
`;
export const FlexPadding20Container = styled.div`
  flex: 1;
  padding: 20px;
`;
export const InlineFlexFullWidthCenteredContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
export const Width80Container = styled.div`
  width: 80%;
`;
export const LinkContainer = styled.div`
  text-decoration: underline;
  margin-left: 20px;
`;
export const InlineFlexFullWidthMT50Container = styled.div`
  margin-top: 50px;
  width: 100%;
  display: inline-flex;
`;
export const FlexColumnsBorderedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 20%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: "10px";
`;
export const FlexSpaceBetweenMB20Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const FlexCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
`;
