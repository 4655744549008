import styled from "styled-components";

export const TitleStyle = styled.p`
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #101828;
  margin-bottom: 0px;
`;

export const TypographyStyle = styled.p`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: left;
  color: #979797;
  margin-bottom: 0px;
`;

export const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48.96px;
  height: 48.96px;
  border-radius: 12.24px;
  angle: -0 deg;
  background: #2684ff;
`;
