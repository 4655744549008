import { ReactComponent as StarsIcon } from "../../../assets/IconITgalaxy/Burst-pucker.svg";
import { ReactComponent as BackgroundIcon } from "../../../assets/IconITgalaxy/background.svg";
import { ReactComponent as GradientIcon } from "../../../assets/IconITgalaxy/gradient-glass.svg";
import image from "../../../assets/IconITgalaxy/portrait-confident-bearded.png";
import { useEffect, useState } from "react";
import RegisterAccounting from "../../../components/Authentification/modals/registerAccounting";

import {
  ButtonStyle,
  ContainerStyle,
  IconStyle,
  ImageStyle,
  RootStyle,
  StackStyle,
  TitleStyle,
  TypographyStyle,
} from "./Landing.style";

function Landing() {
  const isMobile = window.innerWidth <= 768;
  const [openModalRegister, setOpenModalRegister] = useState(false);
  console.log("isMobile", isMobile);
  
  const handleModalRegister = () => {
    setOpenModalRegister(!openModalRegister);
  };



  return (
    <RootStyle>
      <IconStyle top={0} right={20}>
        <BackgroundIcon />
      </IconStyle>
      <ContainerStyle>
        <IconStyle top={5} right={73}>
          <StarsIcon />
        </IconStyle>
        <StackStyle>
          <TitleStyle> Are you IT Worker ?</TitleStyle>
          <TypographyStyle>
            Join the community of the best It Worker in the world on our
            commission-free platform, with over $30 million in
            projects. Keep 100% of your income with commission fees
            of 0%.
          </TypographyStyle>
          <ButtonStyle onClick={handleModalRegister} >Get Started Now</ButtonStyle>
        </StackStyle>
        <ImageStyle src={image} isMobile={isMobile} />
        <IconStyle>
          <GradientIcon />
        </IconStyle>
      </ContainerStyle>

      <RegisterAccounting
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalRegister}
        switchBetweenModals={false}
        proxy={"dashboard"}
      />

    </RootStyle>
  );
}

export default Landing;
