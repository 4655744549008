
import { InputTypes } from "../../Inputs/input.type";
import { skillsData } from "../../../data/skillData";
import { languagesData } from "../../../data/languagesData";
import { countriesData }  from "../../../data/countriesData";

const fetchCountries = async () => {
  try {
    const countriesArray = countriesData.map((skill, index) => ({
      id: index + 1,
      title: skill.title, 
      value: skill.title,    
      label: skill.title, 
     }));
    return(countriesArray);
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
};

const fetchLanguages = async () => {
  try {
  
    const languageArray = languagesData.map((skill, index) => ({
      id: index + 1,
      title: skill.title, 
      value: skill.title, 
      label: skill.title,   
    }));

    return(languageArray);
    
  } catch (error) {
    console.error("Error fetching languages:", error);
  }
};

const fetchSkills = async () => {
  try {
    // Map retrieved skills to the format needed for the dropdown
    const skillsArray = skillsData.map((skill, index) => ({
      id: index + 1,
      title: skill.title, // Assuming the skill object has a name property
      value: skill.title,
      label: skill.title,     // Using the skill ID as the value
    }));

    return(skillsArray); // Update state with fetched skills
  } catch (error) {
    console.error("Error fetching skills:", error);
  }
};

const locations   = await fetchCountries();
const languages = await fetchLanguages();
const skills    = await fetchSkills();

export const formConfig = {

position: {
  inputType: InputTypes.MULTISELECT,
  fieldName: "position",
  isMulti: true,
  config: {
    required: false,
    placeholder:
      "",
   },
   defaultValue:[],
   options: [
    { id: "0", title: "DEVELOPER BACKEND" , value: "DEVELOPER_BACKEND"},
    { id: "1", title: "DEVELOPER FRONTEND", value: "DEVELOPER_BACKEND" },
    { id: "2", title: "DEVOPS", value: "DEVOPS" },
    { id: "3", title: "DEVELOPER MOBILE", value: "DEVELOPER_MOBILE" },
    { id: "4", title: "DESIGN", value: "DESIGN" },
    { id: "5", title: "DATA ENGINEER", value: "DATA ENGINEER" }, 
   ],
  },

  skills: {
      inputType: InputTypes.MULTISELECT,
      fieldName: "skills",
      isMulti: true,
      defaultValue:[],
      config: {
        required: false,
        placeholder:
          "",
       },
       options: skills,
      },
  country_details: {
        inputType: InputTypes.MULTISELECT,
        fieldName: "country_details",
        isMulti: false,
        config: {
          required: false,
          placeholder:
            "",
         },
        defaultValue: [],
        options: locations,
      },
    languages: {
        inputType: InputTypes.MULTISELECT,
        fieldName: "languages",
        isMulti: true,
        config: {
          required: false,
          placeholder:
            "",
         },
         defaultValue:[],
         options: languages,
        },

};

