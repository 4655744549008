
import { getTypeFromLocalStorage ,getIsloggedFromLocalStorage } from '../../core/helpers/storage';
import NotFound from '../../pages/404/notFound';


function ControllerRecruter() {
  const type = getTypeFromLocalStorage();
  const isLoggedIn = getIsloggedFromLocalStorage();

if(isLoggedIn && type !== 'RECRUTER'){

    return (<NotFound />);
  }

}
export default ControllerRecruter;

