import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  extractFileAttributes,
  s3UrlToFile,
} from "../../../../utils/fileConvertion";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./AddFolderApplication.constants";
import {
  ButtonGroupContainer,
  ContentFormContainer,
} from "./AddFolderApplication.style";

export default function AddFolderApplication({
  onValidate,
  onCancel,
  formMethods,
  disabled,
}) {
  const dispatch = useDispatch();
  const watchSupportCourses       = formMethods.watch('supportCourses') ;



  const handleSubmit = async (e) => {
    onValidate();
  };

  return (
    <FormProvider {...formMethods}>
      <small style={{ color: '#6c757d', marginBottom: '10px', display: 'block' }}>
        Please ensure that your file is in ZIP or PDF format before uploading. Maximum file size: 10MB.
      </small>
      <ContentFormContainer>
            <GenericInput
              inputObject={{
                ...formConfig.supportCourses,
                accept: "application/zip",
              }}
              disabledForm={false}
            />
      </ContentFormContainer>
    </FormProvider>
  );
}
