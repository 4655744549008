import { useState } from "react";
import ViewCandidatProfil from "./Profils/Candidat/ViewCandidatProfil";
import ViewEntrepriseProfil from "./Profils/Entreprise/ViewEntrepriseProfil";
import ViewRecruterProfil from "./Profils/Recruter/ViewRecruterProfil";
import ViewAccountingProfil from "./Profils/Accounting/ViewAccountingProfil";

export default function EditProfil( props ) {

  return (
    <> 
    {props.role === "CANDIDAT" && (
      <ViewCandidatProfil
        data={props.userData}
        editProfil={props.editProfil}
        setDataUser={props.setDataUser}
      />)}
      
    {props.role === "ENTREPRISE" && (
      <ViewEntrepriseProfil
        data={props.userData}
        editProfil={props.editProfil}
        setDataUser={props.setDataUser}
      />)}

   {props.role === "RECRUTER" && (
      <ViewRecruterProfil
        data={props.userData}
        editProfil={props.editProfil}
        setDataUser={props.setDataUser}
      />)}
    {props.role === "ACCOUNTING" && (
      <ViewAccountingProfil
        data={props.userData}
        editProfil={props.editProfil}
        setDataUser={props.setDataUser}
      />)}
  </>
    
  );
}
