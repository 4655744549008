import { CustomAvatarStyle } from "./CustomAvatar.style";
import image from "../../../assets/IconITgalaxy/people/imageAvatar.png";
import { generateArray } from "../../../utils/helpers/array.helpers";
import { StackStyle, BoxStyle } from "./CustomAvatar.style";

function CustomMultiAvatar({ avatarNumber, maxAvatar = 7, size = "40px" }) {
  return (
    <StackStyle direction={"row"}>
      {generateArray(maxAvatar - 1).map((value) => (
        <CustomAvatarStyle
          key={value}
          style={{ marginLeft: value ? "-10px" : 0 }}
          src={image}
          size={size}
          roundedCircle
          alt="rounded-circle"
        />
      ))}
      {avatarNumber > 8 && <BoxStyle size={size}>+{avatarNumber - 7}</BoxStyle>}
    </StackStyle>
  );
}

export default CustomMultiAvatar;
