import styled from "styled-components";

export const WalletPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
`;

export const BalanceContainer = styled.div`
  background-color: #0056d2;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
`;

export const StyledTransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  height: 100%;

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .show-selector {
    display: flex;
    align-items: center;
    label {
      margin-right: 5px;
    }
    select {
      padding: 5px;
      border-radius: 4px;
      margin-right: 5px;
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    input {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-right: 10px;
    }
    .explore-btn {
      padding: 8px 12px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #0056d2;
      }
    }
  }
`;

export const TransactionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 12px;
    text-align: left;
  }
`;

export const TransactionRow = styled.tr`
  &:nth-child(even) {
    background-color: #fcfcfc;
  }
`;

export const StatusBadge = styled.span`
  padding: 5px 10px;
  border-radius: 12px;
  color: ${(props) => {
    switch (props.status) {
      case "Delivered":
        return "#28A562";
      case "Process":
        return "#CD6200";
      case "Canceled":
        return "#A30D11";
      default:
        return "#28A562";
    }
  }};
  background-color: ${(props) => {
    switch (props.status) {
      case "Delivered":
        return "#EBF9F1";
      case "Process":
        return "#FEF2E5";
      case "Canceled":
        return "#FBE7E8";
      default:
        return "#EBF9F1";
    }
  }};
`;

export const InvoicingLink = styled.a`
  color: #009ff5;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
export const StyledYourCardContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .add-account {
    color: #1890ff;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const Card = styled.div`
  background: linear-gradient(135deg, #0061f2, #9c00fa);
  border-radius: 15px;
  color: white;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;

  .card-balance {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .card-details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .visa {
    font-size: 16px;
  }
`;

export const CardInfo = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 40px;

  .title-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .card-info-title {
    font-size: 16px;
    font-weight: 600;
  }
  .info-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .card-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  .eye-icon {
    cursor: pointer;
  }
`;

export const TotalBalance = styled.div`
  text-align: center;
  margin-bottom: 20px;

  .balance {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .percentage {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 10px;
  }

  .earnings,
  .withdrawals {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .earnings span,
  .withdrawals span {
    font-weight: 600;
  }
`;

export const WithdrawButton = styled.button`
  background-color: #000;
  color: white;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  text-align: center;

  &:hover {
    background-color: #333;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  width: 320px;
  height: 200px;
  margin-top: 40px;
`;

export const BackCard = styled.div`
  position: absolute;
  top: 10px; /* Offset to create layering effect */
  left: 10px;
  width: 100%;
  height: 100%;
  // top left corner #6e6e70 bottom right corner black
  background: linear-gradient(140deg, #6e6e70, black);
  border-radius: 15px;
  rotate: -6deg;
  translate: -9px -10px;
`;

export const FrontCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // top right corner: #6a67d6  middle: #3f85f9 bottom left corner #28b1f5
  background: linear-gradient(-90deg, #6a67d6, #3f85f9, #28b1f5);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* Circle pattern at the top right corner */
  &::after {
    content: "";
    position: absolute;
    top: -80px;
    right: -80px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
  /* Another circle pattern with slight difference */
  &::before {
    content: "";
    position: absolute;
    top: -80px;
    right: -80px;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
`;

export const CardBalance = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  opacity: 0.9; /* Lighter for subtle details */
`;

export const CardType = styled.span`
  font-size: 16px;
`;
