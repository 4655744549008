import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  StyledModal,
  Header,
  BackButton,
  Title,
  Subtitle,
  Label,
  Input,
  InputContainerForgotPawwsord,
  LoginButton,
  StyledModalFooter,
} from "../../../components/Authentification/modals/styled.js"; 
import Vector from "../../../assets/IconITgalaxy/Vector.svg";
import { useUserForgotPasswordMutation } from "../../../redux/api/auth/authApi";
import { sendForgotPaswordEmail } from "../../../core/helpers/storage.js"

const ModalForgotPassword = (propos) => {
  const [email, setEmail] = useState("");
  const [errorMessageForgotPassword, setErrorMessageForgotPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [getTokenforgotPassword] = useUserForgotPasswordMutation(); // Use the mutation hook

  const sendMailForgotPassword = async () => {
    // Reset previous messages
    setErrorMessageForgotPassword("");
    setSuccessMessage("");

    // Check if the email field is empty
    if (!email) {
        setErrorMessageForgotPassword("Email field cannot be empty.");
        return;
    }

    try {
        // Use the mutation function to send the email
        const response = await getTokenforgotPassword(email);
        // Check if the response status is ok (200)
        if (!response.data || !response.data.resetToken) {
            setErrorMessageForgotPassword("No user with this email found."); // Adjust error message based on status
            return;
        }
        const userData = response.data; // Access the data assuming it's returned correctly        
        // Send the reset password email
        await sendForgotPaswordEmail('' , email, userData.type ,  response.data.resetToken);        
        // Set success message
        setSuccessMessage("A link has been sent to reset your password. Please check your email.");

    } catch (error) {
        console.error("Error occurred while sending password reset email:", error);
        setErrorMessageForgotPassword("An error occurred, please try again later.");
    }
};


  return (
    <StyledModal
      size="lg"
      centered
      show={propos.show}
      onHide={propos.onHide}
      animation={true}
    >
      <Modal.Body>
        <Header>
          <BackButton onClick={propos.onBack}>
            <img src={Vector} style={{ width: "0.83vw" }} alt="vector" />
          </BackButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "23px",
            }}
          >
            <Title>Forgot Password?</Title>
            <Subtitle>Please enter your email to get a link to reset your password.</Subtitle>
          </div>
        </Header>
        
        <InputContainerForgotPawwsord>
          <Label>Email</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessageForgotPassword(""); // Clear previous error
              setSuccessMessage(""); // Clear success message on input change
            }}
          />
        </InputContainerForgotPawwsord>

        {errorMessageForgotPassword && (
          <div style={{ color: "red", marginTop: "10px" }}>
            {errorMessageForgotPassword}
          </div>
        )}

        {successMessage && (
          <div style={{ color: "green", marginTop: "10px" }}>
            {successMessage}
          </div>
        )}
        
        <LoginButton onClick={sendMailForgotPassword}>
          Validate
        </LoginButton>
      </Modal.Body>
      <StyledModalFooter>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ModalForgotPassword;
