import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode if it’s not imported yet
import { getIsloggedFromLocalStorage, getTokenFromLocalStorage } from "core/helpers/storage";

import SideBar from "components/SideBar/sideBar";
import MyWorkingFolder from "components/MyWorkingFolderComponnents/MyWorkingFolder";

const MyWorkingFolders = () => {
  const token = getTokenFromLocalStorage();
  const decodeToken = token ? jwtDecode(token) : null;
  const role = decodeToken ? decodeToken.role : null;
  const id = decodeToken ? decodeToken.id : null;


  return (
    <div style={{ display: "flex" ,  overflowY: 'hidden'  , height: '100vh',  overflowX: 'hidden'}} >
      <SideBar path={'/myworkingfolder'} isLoggedIn={true} role={role} id={id} style={{ overflowY: 'hidden'}}  />     
      <MyWorkingFolder id={id} />
      </div>
  );
}

export default MyWorkingFolders;
