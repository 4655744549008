import React from "react";
import { generateArray } from "../../utils/helpers/array.helpers";

const StarRating = ({
  count,
  value,
  inactiveColor = "#ddd",
  size = 24,
  activeColor = "#f00",
  onChange,
}) => {
  const handleChange = (value) => {
    onChange?.(value);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {generateArray(count).map((val, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              // className={'star'}

              style={{
                color: i < value ? activeColor : inactiveColor,
                width: size,
                height: size,
                fontSize: size,
                display: "inline-block",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: '1px solid #000',
              }}
            >
              <i
                class="fa-solid fa-star"
                onClick={() => {
                  handleChange(i + 1);
                }}
              ></i>
            </span>
          </div>
        );
      })}
      <span style={{ fontSize: 12, color: "#717171", marginLeft: 5 }}>
        {" "}
        ({value}){" "}
      </span>
    </div>
  );
};

export default StarRating;
