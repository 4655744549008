import React, { useState , useEffect } from 'react';
import GenericInput from 'components/Inputs/GenericInput/GenericInput';
import { Button } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { depositCompanyFormConfig } from '../DepositCompanyForm.config';
import { StyledIdentityInputsContainer } from './CompanyNewIdentityForm.style';
import { StyledLabel, SearchFilterRegion } from '../DepositCompanyForm.style';
import { useDispatch } from 'react-redux';
import { getURL } from '../../../redux/api/uploads/uploadSlice';

const CompanyNewIdentityForm = ({isEdit=true,companyType, data, onValidate, onCancel, formMethods, disableStep ,refetchCompany ,refetchCompanyRequestCreation }) => {
    const watchIdentityType = formMethods.watch('identityType');
    const watchIdentityNumber = formMethods.watch('identityNumber');
    const watchIdentityProofAttachment = formMethods.watch('cardIdentityFrontProofAttachment'); // Fixed variable name
    const defaultValue= data ? [{'title' : data[0]?.identity_type, 'label' : data[0]?.identity_type , 'value' : data[0]?.identity_type}] : [] ; // Default value here if your GenericInput supports it
    const defaultValueNumber= data ?  data[0]?.identity_number : '' ; // Default value here if your GenericInput supports it
    const [previewIdentityFrontCard, setPreviewIdentityFrontCard] = useState(null);
    const [previewIdentityBackCard , setPreviewIdentityBackCard] = useState(null);
    const folder   = companyType === 'Company Exist' ? 'company'  : 'request_company_creation' ;
    const [loading, setLoading] = useState(true); 
    const dispatch = useDispatch();

  
    const getUrlData = async () => {
     setLoading(true); // Set loading state to true
 
      const fetchUrlData = async (location) => {
            try {
                const result = await dispatch(getURL({ location }));
                return result;
            } catch (error) {
                console.error(`Failed to fetch data from ${location}`, error);
                return null;
            }
        };
          try {
             const urlIdentityFrontCard = data ? await fetchUrlData(`${folder}/${data[0]?.id}/identityFront/`) : null ;
             const IdentityFrontCardPath = urlIdentityFrontCard?.Contents?.[0]?.Key;

            if (IdentityFrontCardPath) {
                setPreviewIdentityFrontCard(`${process.env.REACT_APP_S3_URL}/${IdentityFrontCardPath}`);
            } else {
                console.error("Company Location contents not found");
                setPreviewIdentityFrontCard(null);
            }
             const urlIdentityBackCard = data ? await fetchUrlData(`${folder}/${data[0]?.id}/identityFront/`) : null ;
             const IdentityBackCardPath = urlIdentityBackCard?.Contents?.[0]?.Key;

           if (IdentityFrontCardPath) {
               setPreviewIdentityBackCard(`${process.env.REACT_APP_S3_URL}/${IdentityBackCardPath}`);
           } else {
               console.error("Company Location contents not found");
               setPreviewIdentityBackCard(null);
           }
            
        } finally {
            setLoading(false);
        }
      };
  
      useEffect(() => {
        getUrlData();
      }, []);


    // State for error message visibility
    const [showError, setShowError] = useState(false);

    const handleValidateClick = () => {
        // Check if inputs are empty
        if (!watchIdentityType || !watchIdentityNumber || !watchIdentityProofAttachment) {
            setShowError(true); // Trigger error message if fields are empty
        } else {
            setShowError(false); // Clear error if valid
            onValidate(); // Proceed to validation logic
        }
    };

    return (
        <FormProvider {...formMethods}>
            <StyledLabel>1. What's your identity card type?</StyledLabel>
            <StyledIdentityInputsContainer>
                <SearchFilterRegion>
                    <GenericInput
                        inputObject={{
                            ...depositCompanyFormConfig.identityType,
                             defaultValue: defaultValue, // Default value here if your GenericInput supports it
                        }}
                        disabledForm={disableStep}
                        value={watchIdentityType || 'CIN'}
                    />
                </SearchFilterRegion>
            </StyledIdentityInputsContainer>

            <StyledLabel>2. What's your identity Number?</StyledLabel>
            <StyledIdentityInputsContainer>
                <GenericInput
                    inputObject={{
                        ...depositCompanyFormConfig.identityNumber,
                        defaultValue: defaultValueNumber
                    }}
                    disabledForm={disableStep}
                    value={watchIdentityNumber || ''}
                />
            </StyledIdentityInputsContainer>

            <StyledLabel>3. Upload your identity proof (Front):</StyledLabel>
            <GenericInput
                inputObject={{
                    ...depositCompanyFormConfig.identityFrontCardProofAttachment,
                }}
                disabledForm={false}
                isEdit={isEdit}
                previewInput={previewIdentityFrontCard}                  
                placeholder="Upload identity proof document"
            />

            {watchIdentityType && watchIdentityType.value !== 'Passport' && (
                <>
                    <StyledLabel>4. Upload your identity proof (Back):</StyledLabel>
                    <GenericInput
                        inputObject={{
                            ...depositCompanyFormConfig.identityBackCardProofAttachment,
                        }}
                        disabledForm={false}
                        previewInput={previewIdentityBackCard}                  
                        isEdit={isEdit}
                        placeholder="Upload identity proof document"
                    />
                </>
            )}

            {/* Error message section */}
            {showError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    <strong>Please fill in all required fields.</strong>
                </div>
            )}

            <div style={{ float: "right", marginTop: "10%" }}>
                <Button variant="light" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    style={{ marginLeft: "15px" }}
                    variant="primary"
                    onClick={handleValidateClick}
                    disabled={!watchIdentityType || !watchIdentityNumber || !watchIdentityProofAttachment} // Disable if inputs are empty
                >
                    Confirm
                </Button>
            </div>
        </FormProvider>
    );
}

export default CompanyNewIdentityForm;
