import styled from "styled-components";

export const Background = styled.div`
 background:  black ;
 background-image: url(/static/media/homeBackground.3901b58aa5016f29c2f2.png);
 height: 100vh;
 width: 100%;
 color: white;
 background-size: contain;
`;

export const StyleCard = styled.p`
font-size: 30px; /* large text size */
font-family: 'Arial', sans-serif; /* Change this to a more beautiful font if desired */
/* border: 2px solid white;  border around the text */
margin-top: 50px;
padding: 120px; /* spacing inside the border */
text-align: center; /* center text */
`;
export const Logo = styled.img`
width: 408px;
padding-top: 50px;
height: 140px;
cursor: pointer;


`;

