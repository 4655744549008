import React from 'react';
import { Link } from 'react-router-dom'; // If you want to link back to your home page or other sections

export const TermsOfService = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1 style={{ textAlign: 'center' }}>Terms of Service</h1>
      <p>Last updated: 01/10/2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using the ItGalaxy services, you agree to be bound by these Terms of Service.
        If you do not agree, you may not use the services. 
      </p>

      <h2>2. Changes to the Terms</h2>
      <p>
        ItGalaxy reserves the right to modify these Terms at any time. Changes will be effective immediately upon posting the updated Terms on our website.
        Your continued use of the services after changes indicates your acceptance of the new Terms.  
      </p>

      <h2>3. Use of Services</h2>
      <p>
        You may use the services only for lawful purposes and in accordance with these Terms.
        You agree not to use the services in any way that violates any applicable national or international law.
      </p>

      <h2>4. User Accounts</h2>
      <p>
        You are responsible for maintaining the confidentiality of your account information, including your password.
        You agree to notify ItGalaxy immediately of any unauthorized use of your account or any other breach of security.
      </p>

      <h2>5. Intellectual Property Rights</h2>
      <p>
        The content, layout, design, data, and graphics on this website are the property of ItGalaxy or its content suppliers.
        You may not reproduce, modify, distribute, or display any part of the services without our prior written consent.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, ItGalaxy shall not be liable for any indirect, incidental, special, or consequential damages
        arising from your use of the services or any content provided therein.
      </p>

      <h2>7. Indemnification</h2>
      <p>
        You agree to defend, indemnify and hold harmless ItGalaxy, its affiliates, and its employees from any claims, liabilities, damages,
        losses, and expenses arising out of or related to your violation of these Terms.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at support@itgalaxy.com.
      </p>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Link to="/">Go to Home Page</Link>
      </div>
    </div>
  );
};

export default TermsOfService;
