import { Controller, useFormContext } from "react-hook-form";
import { isObjectExist } from "../../../core/helpers/object";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete";
import CustomCheckboxInput from "../CustomCheckboxInput/CustomCheckboxInput";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import CustomFileMultipleInput from "../CustomMultipleFileInput/CustomFileInput";
import CustomMultiRadioInput from "../CustomMultiRadioInput/CustomMultiRadioInput";
import MultiSelectCompoent from "../CustomMultiSelectAutoComplet/multiSelect";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";
import CustomRadioCard from "../CustomRadioCard/CustomRadioCard";
import CustomRadioCard2 from "../CustomRadioCard2/CustomRadioCard2";
import CustomRadioCard3 from "../CustomRadioCard3/CustomRadioCard3";

import CustomSelect from "../CustomSelect/CustomSelect";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import { InputTypes } from "../input.type";

function GenericInput({previewInput,isEdit ,inputObject, disabledForm, styles, onChange, selectedFiles, setSelectedFiles }) {
  const {
    inputType,
    fieldName,
    defaultValue,
    config = {},
    accept,
    placeholder,
    options,
  } = inputObject;


  const rules = {
    ...config.rules,
    required: isObjectExist(config.required) ? config.required : false,
  };


  const handleFieldChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const isDisabled = disabledForm ?? false;
  const { control, formState: { errors } } = useFormContext(); // Extract errors from form state

  const errorMessage = errors[inputObject.fieldName]?.message;

  switch (inputType) {
    case InputTypes.TEXT:

      return (
        <Controller
          render={({ field }) => (
            <CustomTextInput
              label={field.label}
              value={field.value}
              defaultValue={inputObject.defaultValue}
              onChange={(e) => {
                const value = e.target.value; // if it’s a text input
                field.onChange(value);
                handleFieldChange(value); // Propagate the change
              }}
              placeholder={inputObject.placeholder}
              name={inputObject.label}
              disabled={isDisabled}
              error={errorMessage}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
        />
      );

    case InputTypes.TEXTAREA:
      return (
        <Controller
          render={({ field }) => (
            <CustomTextArea
              label={inputObject.label}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
              placeholder={inputObject.placeholder}
              name={inputObject.label}
              disabled={isDisabled}
              error={errorMessage}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.NUMBER:
      return (
        <Controller
          render={({ field }) => (
            <CustomNumberInput
              label={inputObject.label}
              value={field.value}
              defaultValue={inputObject.defaultValue}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
              placeholder={inputObject.placeholder}
              name={inputObject.label}
              disabled={isDisabled}
              error={errorMessage}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.RADIO:
      return (
        <Controller
          render={({ field }) => (
            <CustomRadioCard
              id={field.id || inputObject.id}
              name={field.name || inputObject.name}
              checked={inputObject.checked}
              onChange={inputObject.onChange}
              icon={inputObject.icon}
              label={inputObject.label}
              classStyle={inputObject.classStyle}
              classContainer={inputObject.classContainer}
              error={errorMessage}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.RADIO_2:
      return (
        <Controller
          render={({ field }) => (
            <CustomRadioCard2
              id={field.id || inputObject.id}
              name={field.name || inputObject.name}
              checked={inputObject.checked}
              onChange={inputObject.onChange}
              icon={inputObject.icon}
              label={inputObject.label}
              error={errorMessage}

            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.RADIO_3:
        return (
          <Controller
            render={({ field }) => (
              <CustomRadioCard3
                id={field.id || inputObject.id}
                name={field.name || inputObject.name}
                checked={inputObject.checked}
                onChange={inputObject.onChange}
                icon={inputObject.icon}
                label={inputObject.label}
                error={errorMessage}
  
              />
            )}
            name={inputObject.fieldName}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
          />
        );
    case InputTypes.FILE:
      return (
        <Controller
          render={({ field }) => (
            <CustomFileInput
              label={inputObject.label}
              value={field.value}
              onChange={(file) => { // Capture the actual file object
                field.onChange(file); // Store the file in the form state
                handleFieldChange(file); // Propagate the change with the file
              }}
              accept={accept}
              placeholder={inputObject.placeholder}
              error={errorMessage}
              previewInput={previewInput}
              isEdit={isEdit}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          accept={accept}
        />
      );
    case InputTypes.MULTIPLE_FILE:
      return (
        <Controller
          render={({ field }) => (
            <CustomFileMultipleInput
              label={field.name}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                setSelectedFiles(value); // Store selected files in parent state
              }}
              selectedFiles={selectedFiles} // Pass selected files to the CustomFileMultipleInput component
              setSelectedFiles={setSelectedFiles} // Pass the setter function
              accept=".pdf"
              placeholder="Enter please your budget for this project"
              error={errorMessage}

            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          accept={accept}
        />
      );
    case InputTypes.AUTOCOMPLETE:
      return (
        <Controller
          render={({ field }) => <CustomAutoComplete name={fieldName} />}
          name={inputObject.fieldNameName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.SELECT:
      return (
        <Controller
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              label={inputObject.label}
              options={inputObject?.options?.map((option) => ({
                value: option.title, // Used as the value of the select
                label: option.title, // Displayed in the dropdown
              })) || []}
              error={errorMessage}
              placeholder={inputObject.placeholder}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
              defaultValue={defaultValue}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          options={inputObject.options}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.SELECT_PROJECT:
      return (
        <Controller
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              label={inputObject.label}
              options={inputObject?.options?.map((option) => ({
                value: option.id, // Used as the value of the select
                label: option.title, // Displayed in the dropdown
              })) || []}
              error={errorMessage}
              placeholder={inputObject.placeholder}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
              defaultValue={defaultValue}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          options={inputObject.options}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.CHECKBOX:
      return (
        <Controller
          render={({ field }) => (
            <CustomCheckboxInput
              value={field.value}
              label={inputObject.label}
              error={errorMessage}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          options={inputObject.options}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.MULTISELECT:
      return (
        <Controller
          render={({ field }) => (
            <MultiSelectCompoent
              value={field.value}
              label={inputObject.label}
              isMulti={inputObject.isMulti}
              options={inputObject.options.map((input) => ({
                value: input.value,
                label: input.title,
              }))}
              defaultValue={inputObject.defaultValue.map((input) => ({
                value: input.value,
                label: input.title,
              }))}
              placeholder={inputObject.placeholder}
              name={inputObject.fieldName}
              error={errorMessage}
              styles={styles}
              onChange={(value) => {
                field.onChange(value);
                handleFieldChange(value);
              }}
              disabled={isDisabled}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          options={inputObject.options}
          defaultValue={inputObject.defaultValue}

        />
      );
    case InputTypes.MULTI_RADIO:
      return (
        <Controller
          render={({ field }) => (
            <CustomMultiRadioInput
              options={options} // Pass options prop to the CustomMultiRadioInput
              name={field.name}
              selectedValue={field.value || ""} // Handle the selected value from the form
              onChange={(value) => {
                field.onChange(value); // Update form state
                handleFieldChange(value); // Propagate the change to parent component
              }}
              disabled={isDisabled}
              error={errorMessage}
            />
          )}
          name={inputObject.fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue || ""} // Default to empty string if no value is provided
        />
      );

    default:
      return null;
  }
}

export default GenericInput;
