export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader?.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};
export const extractFileAttributes = (fileBlob, fileKey) => {
  const file = new File([fileBlob], fileKey.split("/").pop(), {
    type: fileBlob.type,
  });

  const fileAttributes = {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: fileBlob.type,
    webkitRelativePath: file.webkitRelativePath || "",
  };

  return fileAttributes;
};
export const s3UrlToFile = async (s3Url) => {
  const response = await fetch(s3Url);
  const fileName = `${response?.url}`?.split("/")?.pop();

  if (!response.ok) {
    throw new Error(`Failed to fetch the S3 URL: ${response.statusText}`);
  }

  const blob = await response.blob();

  const file = new File([blob], fileName, { type: blob.type });
  return file;
};
