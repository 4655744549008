import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowUpRight } from "../../../assets/IconITgalaxy/akar-icons-arrow-up-right.svg";
import { ReactComponent as ArrowUpRightBlack } from "../../../assets/IconITgalaxy/akar-icons-arrow-up-right-black.svg";

import {
  ButtonStyle,
  RootStyle,
  StackStyle,
  TitleStyle,
  TypographyStyle,
} from "./HomeSliderText.style";

function HomeSliderText({ title, text, buttonLabel, buttonUrl, active }) {
  const navigate = useNavigate();

  return (
    <RootStyle>
      <TitleStyle active={active}>{title}</TitleStyle>
      <TypographyStyle active={active}>{text}</TypographyStyle>
      <ButtonStyle
        // icon={active==='FREELANCERS' ? ArrowUpRightBlack :ArrowUpRightBlack}
        onClick={() => navigate("/join-us")}
        active={active}
      >
        <StackStyle
          direction={"row"}
          style={{ justifyContent: "space-between" }}
          active={active}
        >
          {buttonLabel}
          {active==='FREELANCERS' ? <ArrowUpRightBlack /> :<ArrowUpRight />}
          
        </StackStyle>
      </ButtonStyle>
    </RootStyle>
  );
}

export default HomeSliderText;
