import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./assets/fontawesome-pro-6.1.0-web/css/all.css";
import { Router } from "./routes/routes";
import Layout from "./layout";
// import ProtectedRoutes from "./pages/Authentification/AuthGard/ProtectedRoutes";

function App() {
  return (
    <div className="App content-desk" id="app">
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
