import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { StyledLoaderContainer } from "../../styeldComponants";
import EditImageProfilEntreprise from "./EditImageProfilEntreprise";
import EditFormEntrepriseProfil from "./EditFormEntrepriseProfil";
import ImageProfilCard from "components/ImageProfilCard/ImageProfilCard";
import {
  StyledAboutMeCard,
  StyledAboutMeText,
  StyledAvailableText,
  StyledBlackBackground,
  StyledDot,
  StyledEditImageIcon,
  StyledEditProfileButton,
  StyledLeftSideBar,
  StyledLeftSideBarAvailable,
  StyledLeftSideBarContainer,
  StyledLeftSideBarImage,
  StyledLeftSideBarLightParagraph,
  StyledLeftSideBarLocationContainer,
  StyledLeftSideBarName,
  StyledLeftSideBarNameStyle,
  StyledLeftSideBarProfileDesc,
  StyledLeftSideBarUSerOtherInfo,
  StyledLinkButton,
  StyledLink,
  StyledPortfolioSectionContainer,
  StyledProfilImgSquare,
  StyledReadMoreButton,
  StyledRightSideBarContainer,
  StyledSideBarHeader,
  StyledSidebarContent,
  CommentedStyle,
  TextComment,
  StarContainer,
  StyledViewEntrepriseProfil,
  StyledWebView,
  StyledWebViewCard,
  CommentUser,
  TitleProfile,
  CompetenceArea,
  CompetenceLabel,
  FullScreenIcon,
  StyleWithIcon,
  StarImg,
  StyledReviewNumber,
  StyledComments,
  StyledCommentsContainer,
  CompetenceAreaSkills,
} from "./styled";
import { useGetCommentsQuery } from "../../../../redux/api/comments/commentsApi";
import RenderStars from "components/RenderStars/RenderStars"; 

const ViewEntrepriseProfil = ({ data, setDataUser, editProfil }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isFullScreenExp, setIsFullScreenExp] = useState(false);
  const [editModalProfilePictureOpen, setEditModalProfilePictureOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const params = '?CommentedType=ENTREPRISE&commentedId='+data.id;
  const { data: comments, error, isLoading } = useGetCommentsQuery(params);

 

  if (!data) {
    return (
      <StyledLoaderContainer>
        <Spinner style={{ width: "100px", height: "100px" }} />
      </StyledLoaderContainer>
    );
  }

  const toggleFullScreenExp = () => setIsFullScreenExp(prev => !prev);
  const toggleReadMore = () => setIsExpanded(prev => !prev);

  const handleModalOpen = (setOpenFunc) => () => setOpenFunc(true);
  const handleModalClose = (setOpenFunc) => () => setOpenFunc(false);

  const handleSaveChanges = (updatedData) => {
    setDataUser((prevData) => ({ ...prevData, ...updatedData }));
    handleModalClose(setEditModalOpen)();
  };

  const handleSaveChangesProfileImage = (updatedData) => {
    setDataUser((prevData) => ({ ...prevData, ...updatedData }));
    handleModalClose(setEditModalProfilePictureOpen)();
  };

  const showReadMore = data?.entreprise_description?.length > 300;
  return (
    <StyledViewEntrepriseProfil>
      <StyledLeftSideBar isFullScreenExp={isFullScreenExp}>
        <StyledSideBarHeader>
          <StyledBlackBackground />
          <StyledSidebarContent>
            <StyledLeftSideBarImage style={{ position: "relative" }}>
            <ImageProfilCard
                  id={data.id}
                  type={"entreprises"}
                  typeimg={"profil"}
                />
              {editProfil && (
                <StyledEditImageIcon onClick={handleModalOpen(setEditModalProfilePictureOpen)}>
                  <i className="bi bi-pencil" />
                </StyledEditImageIcon>
              )}
            </StyledLeftSideBarImage>
          </StyledSidebarContent>
        </StyledSideBarHeader>
  
  
        <StyledLeftSideBarName>
          <StyledLeftSideBarNameStyle>{data.username} </StyledLeftSideBarNameStyle>
        </StyledLeftSideBarName>
  
        <StyledLeftSideBarAvailable>
         <StyledDot isActive={data.status === 'ACTIVE'} />
        <StyledAvailableText>
            {data.status === 'ACTIVE' ? "AVAILABLE" : "UNAVAILABLE"}
          </StyledAvailableText>
        </StyledLeftSideBarAvailable>

        <StyledLeftSideBarUSerOtherInfo>
         
          {editProfil && (
            <StyledLeftSideBarLightParagraph>
              {data.email}
            </StyledLeftSideBarLightParagraph>
          )}
  
          <StyledLeftSideBarLocationContainer>
            <i className="bi bi-geo-alt" style={{ paddingRight: "10px" }}></i>
            <StyledLeftSideBarLightParagraph>
              {data.country_details}
            </StyledLeftSideBarLightParagraph>
          </StyledLeftSideBarLocationContainer>
        </StyledLeftSideBarUSerOtherInfo>
  
        <StyledLeftSideBarContainer>
        <StyledLink href={data.website} target="_blank" rel="noopener noreferrer">
          <span>
            <i className="fas fa-globe" style={{ paddingRight: "10px" }}></i>
            {data.website}
          </span>
          <i className="fas fa-external-link-alt"></i>
        </StyledLink>
  
          <hr style={{ borderTop: "1px solid", opacity: "1", width: "100%" }} />
  
          {editProfil && (
            <StyledEditProfileButton onClick={handleModalOpen(setEditModalOpen)} >
              <i className="bi bi-pencil" /> Edit your profile
            </StyledEditProfileButton>
          )}
        </StyledLeftSideBarContainer>
      </StyledLeftSideBar>
  
      <StyledRightSideBarContainer>
        <StyledAboutMeCard isFullScreenExp={isFullScreenExp}>
          <TitleProfile>About Me</TitleProfile>
          <StyledAboutMeText isExpanded={isExpanded}>
            {isExpanded
              ? data.entreprise_description
              : data.entreprise_description
              ? `${data.entreprise_description.slice(0, 200)}${data.entreprise_description.length > 200 ? "..." : ""}`
              : "No description available."}
          </StyledAboutMeText>
          
          {showReadMore && (
            <StyledReadMoreButton onClick={toggleReadMore}>
              {isExpanded ? "Read Less" : "Read More"}
              <i className={`fas fa-chevron-${isExpanded ? "up" : "down"}`} />
            </StyledReadMoreButton>
          )}
        </StyledAboutMeCard>
  
        <StyledPortfolioSectionContainer>
          <StyledWebViewCard className={isFullScreenExp ? 'full-screen' : ''}>
            <StyleWithIcon>
              <TitleProfile>ItGalaxy Experiences</TitleProfile>
              <FullScreenIcon className={`fas ${isFullScreenExp ? 'fa-compress' : 'fa-expand'}`} onClick={toggleFullScreenExp} />
            </StyleWithIcon>
            <CompetenceArea>
            <StyledCommentsContainer>
                {comments?.length > 0 ? ( // Check if there are any comments
                    comments.map((c) => (
                        <StyledComments key={c.id}>
                            <CommentedStyle>
                                <CommentUser>
                                    {c.Project.project.title} - {new Date(c.createdAt).toLocaleDateString()}
                                </CommentUser>
                                <ImageProfilCard
                                    id={c.userId}
                                    type={"candidates"}
                                    typeimg={"cercel"}
                                    src={c.userProfileImageUrl} // Add a path for user profile image URL
                                    alt={`${c.userId}'s profile`} // Alt text for the image
                                />
                                <span>{c.Project.price} $</span>
                            </CommentedStyle>
                            <TextComment>{c.comment_text}</TextComment>
                            <StarContainer>
                                <RenderStars stars={c.stars} /> {/* Render stars for this comment */}
                            </StarContainer>
                        </StyledComments>
                    ))
                ) : (
                    <div style={{ textAlign: 'center', color: '#666', margin: '20px 0' }}>
                        <p>No comments or experiences yet.</p>
                    </div>
                )}
            </StyledCommentsContainer>
            </CompetenceArea>
          </StyledWebViewCard>    
        </StyledPortfolioSectionContainer>
    </StyledRightSideBarContainer>

    {editModalOpen && (
      <EditFormEntrepriseProfil
        data={data}
        editModalOpen={editModalOpen}
        onSaveChanges={handleSaveChanges}
        onClose={handleModalClose(setEditModalOpen)}
      />
    )}
    
    {editModalProfilePictureOpen && (
      <EditImageProfilEntreprise
        data={data}
        editModalProfilePictureOpen={editModalProfilePictureOpen}
        onSaveChanges={handleSaveChangesProfileImage}
        onClose={handleModalClose(setEditModalProfilePictureOpen)}
      />
    )}
  </StyledViewEntrepriseProfil>
 ); 
}
export default ViewEntrepriseProfil;
