import { useEffect, useState } from "react";
import { ChatbotContainer, ChatbotMessage } from "./ChatbotTextEffect.style";

const ChatbotTextEffect = ({ message, onComplete }) => {
  const [displayedText, setDisplayedText] = useState(message);

  useEffect(() => {
    setDisplayedText(message);
    if (onComplete) {
      onComplete();
    }
  }, [message, onComplete]);

  return (
    <ChatbotContainer>
      <ChatbotMessage>{displayedText}</ChatbotMessage>
    </ChatbotContainer>
  );
};

export default ChatbotTextEffect;
