import ItGalaxyMobile from "../../assets/logo/ItGalaxy-LogoWhite.png";
import { useState } from "react";
import {
  Background,
  Logo,
  StyleCard,
} from "./AfterSignup.style.js";
import Login from "components/Authentification/modals/login";

function AfterSignup() {


   return (
    <>
    <Background>
      <Logo src={ItGalaxyMobile} />

     <StyleCard> Welcome Aboard  ,<br/>

      Thank you for joining our community! <br/>

      To complete your registration, please check your email inbox for a confirmation message. Just click the link inside to validate your account and unlock all the wonderful features we have to offer.

     
      </StyleCard>
     
     </Background>

    </>
   );
  }

export default AfterSignup;

