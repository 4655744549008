import styled from 'styled-components';
import CoursePlaceholder from '../../assets/course-image-placeholder.jpg';
import CoursePreviewCard from '../CoursePreviewCard/CoursePreviewCard';

const Container = styled.div`
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
`;

const CourseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
`;

const CourseInfo = styled.div`
width: 55%;
`;

const CourseTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #222;
`;

const University = styled.p`
  font-size: 14px;
  color: #009FF5;
  margin: 5px 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
`;


const InstructorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const InstructorAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
`;

const EnrollButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const Price = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #009FF5;
  margin: 0;
`;

const EnrollContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

const handleModulesClick = () => {
  // Handle the "View course modules" link click
  console.log("Modules link clicked");
};

const CourseIntro = () => {
  return (
    <Container>
      <CourseHeader>
        <CourseInfo>
          <University>Course by University of MICHIGAN</University>
          <CourseTitle>Programming for Everybody (Getting Started with Python)</CourseTitle>
          <Subtitle>Créez des applications Web et mobiles élégantes et puissantes à l'aide AJAX.</Subtitle>
          <InstructorInfo>
            <InstructorAvatar>JA</InstructorAvatar>
            <p>John Abady<br /><span>Instructor</span></p>
          </InstructorInfo>
          <EnrollContainer><EnrollButton>Enroll for the course ➔</EnrollButton><Price>$17.84</Price></EnrollContainer>
        </CourseInfo>

        {/* Replace inline card elements with CoursePreviewCard */}
        <CoursePreviewCard
          imageSrc={CoursePlaceholder}
          duration="18 hr 12 mins"
          onModulesClick={handleModulesClick}
        />
      </CourseHeader>
    </Container>
  );
};

export default CourseIntro;
