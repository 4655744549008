import image from "../../../assets/IconITgalaxy/people/imageAvatar.png";
import image2 from "../../../assets/IconITgalaxy/people/imageAvatar2.png";
import image3 from "../../../assets/IconITgalaxy/people/imageAvatar3.png";
import image4 from "../../../assets/IconITgalaxy/people/imageAvatar4.png";
import image5 from "../../../assets/IconITgalaxy/people/imageAvatar5.png";
import image6 from "../../../assets/IconITgalaxy/people/imageAvatar6.png";
export const LANDING = [
  {
    user: { photo: image, firstName: "Susan", lastName: "Lee" },
    content: `  "Freelancing on this platform has given me the flexibility and control I craved after years in a corporate environment. The access to high-quality projects and supportive community has been invaluable. Now, I'm building a successful career on my own terms, shaping my work life around my passions and priorities."`,
  },
  {
    user: { photo: image2, firstName: "Maria", lastName: "Rodriguez" },
    content: `"Finding steady freelance work used to be a struggle, filled with endless job boards and unreliable leads. This platform's streamlined process and pre-vetted clients have made all the difference. I can focus on delivering exceptional work and growing my skills, knowing I have reliable projects lined up, allowing me to confidently plan my freelance future."`,
  },
  {
    user: { photo: image3, firstName: "Jane", lastName: "Thompson" },
    content: `  "This platform has been a game-changer for me! The online courses helped me refine my design skills and learn the ins and outs of freelancing. Now, I'm attracting dream clients with my honed skills and collaborative approach, collaborating on exciting projects from anywhere in the world, fostering a sense of freedom and creative fulfillment."`,
  },
  {
    user: { photo: image4, firstName: "Linda", lastName: "Evans" },
    content: `  "This platform isn't just about finding work – it's about building a career. The learning resources and community support have been instrumental in helping me expand my skillset and stay ahead of the curve. I feel empowered to succeed as a freelance data analyst, constantly evolving my expertise to deliver exceptional client outcomes."`,
  },
  {
    user: { photo: image5, firstName: "Carol", lastName: "Martinez" },
    content: `"Managing freelance projects used to be a logistical nightmare, filled with scattered communication and inefficient workflows. This platform's intuitive tools streamline communication and collaboration with freelancers. It's saved me countless hours and allows me to focus on delivering successful project outcomes for my clients, ensuring a smooth and productive freelance experience for everyone involved."`,
  },
  {
    user: { photo: image6, firstName: "Patricia", lastName: "Wilson" },
    content: `  "As a single mom, freelancing allows me to balance work and family life. This platform provides the perfect combination of flexibility and security. I can choose projects that fit my schedule and access resources to help me excel as a freelancer,empowering me to be both a successful professional and a present parent."`,
  },
];
