export const chaptersData = [
  {
    id: 1,
    title: "Chapter One - Why We Program?",
    duration: "1 hour to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "The basics of python",
        duration: "2 mins",
        completed: true,
      },
      {
        id: 2,
        type: "Video",
        title: "The basics of python",
        duration: "2 mins",
        completed: true,
      },
      {
        id: 3,
        type: "Reading",
        title: "The basics of python",
        duration: "2 mins",
        completed: true,
      },
      {
        id: 4,
        type: "Practice Quiz",
        title: "The basics of python",
        duration: "2 mins",
        completed: false,
      },
      {
        id: 5,
        type: "Video",
        title: "The basics of python",
        duration: "2 mins",
        completed: false,
      },
      {
        id: 6,
        type: "Reading",
        title: "The basics of python",
        duration: "2 mins",
        completed: false,
      },
      {
        id: 7,
        type: "Video",
        title: "The basics of python",
        duration: "2 mins",
        completed: false,
      },
    ],
  },
  {
    id: 2,
    title: "Installing Python",
    duration: "2 hours to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "Installing Python",
        duration: "3 mins",
        completed: false,
      },
      {
        id: 2,
        type: "Reading",
        title: "Installation Guide",
        duration: "4 mins",
        completed: false,
      },
      {
        id: 3,
        type: "Practice Quiz",
        title: "Installing Python Quiz",
        duration: "5 mins",
        completed: false,
      },
    ],
  },
  {
    id: 3,
    title: "Chapter Two: Variables and Expressions",
    duration: "3 hours to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "Understanding Variables",
        duration: "6 mins",
        completed: false,
      },
      {
        id: 2,
        type: "Reading",
        title: "Variable Types",
        duration: "5 mins",
        completed: false,
      },
      {
        id: 3,
        type: "Video",
        title: "Expressions and Statements",
        duration: "7 mins",
        completed: false,
      },
      {
        id: 4,
        type: "Practice Quiz",
        title: "Variables Quiz",
        duration: "5 mins",
        completed: false,
      },
    ],
  },
  {
    id: 4,
    title: "Chapter Three: Conditional Code",
    duration: "3 hours to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "If Statements",
        duration: "6 mins",
        completed: false,
      },
      {
        id: 2,
        type: "Video",
        title: "Else and Elif",
        duration: "5 mins",
        completed: false,
      },
      {
        id: 3,
        type: "Reading",
        title: "Conditional Expressions",
        duration: "8 mins",
        completed: false,
      },
      {
        id: 4,
        type: "Practice Quiz",
        title: "Conditional Code Quiz",
        duration: "4 mins",
        completed: false,
      },
    ],
  },
  {
    id: 5,
    title: "Chapter Four: Functions",
    duration: "2 hours to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "Defining Functions",
        duration: "5 mins",
        completed: false,
      },
      {
        id: 2,
        type: "Video",
        title: "Function Parameters",
        duration: "7 mins",
        completed: false,
      },
      {
        id: 3,
        type: "Reading",
        title: "Return Statements",
        duration: "6 mins",
        completed: false,
      },
      {
        id: 4,
        type: "Practice Quiz",
        title: "Functions Quiz",
        duration: "6 mins",
        completed: false,
      },
    ],
  },
  {
    id: 6,
    title: "Chapter Five: Loops and Iteration",
    duration: "2 hours to complete",
    completed: false,
    modules: [
      {
        id: 1,
        type: "Video",
        title: "Introduction to Loops",
        duration: "5 mins",
        completed: false,
      },
      {
        id: 2,
        type: "Video",
        title: "While Loops",
        duration: "8 mins",
        completed: false,
      },
      {
        id: 3,
        type: "Video",
        title: "For Loops",
        duration: "6 mins",
        completed: false,
      },
      {
        id: 4,
        type: "Reading",
        title: "Loop Control Statements",
        duration: "4 mins",
        completed: false,
      },
      {
        id: 5,
        type: "Practice Quiz",
        title: "Loops Quiz",
        duration: "5 mins",
        completed: false,
      },
    ],
  },
];
