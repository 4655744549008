import AddExercicesForm from "components/DashboardTrainerComponnents/TrainerComponnents/AddExercicesForm/AddExercicesFrom";
import AddPriceForm from "components/DashboardTrainerComponnents/TrainerComponnents/AddPriceForm/AddPriceForm";
import Content from "components/DashboardTrainerComponnents/TrainerComponnents/Content/Content";
import CreateApplication from "components/DashboardTrainerComponnents/TrainerComponnents/CreateApplication/CreateApplication";
import {
  InlineBlockContainer,
  InlineBlockMarginTopContainer,
} from "components/DashboardTrainerComponnents/TrainerComponnents/TrainerForm/TraininerForm.style";
import TrainerNewPaymentForm from "../../components/DashboardTrainerComponnents/TrainerComponnents/TrainerNewPaymentForm/TrainerNewPaymentForm";
import TrainingType from "../../components/DashboardTrainerComponnents/TrainerComponnents/TrainingType/TrainingType";

export const renderContentList = (
  type,
  trainingType,
  selectedElement,
  setSelectedElement,
  setConfirmShow,
  setItems,
  formMethods,
  addChapter,
  currentSubModule,
  handleConfirmChapter,
  totalChapters
) => {
  if (type !== "training") return null;

  const handleCancel = () => {
    if (selectedElement > 1) setSelectedElement((prev) => prev - 1);
  };

  const handleValidate = () => setSelectedElement((prev) => prev + 1);

  if (trainingType === "Video Training") {
    switch (selectedElement) {
      case 1:
        return (
          <TrainingType
            setItems={setItems}
            trainingType={trainingType}
            onCancel={handleCancel}
            onValidate={handleValidate}
          />
        );
      case 2:
        return (
          <InlineBlockContainer>
            <CreateApplication
              onValidate={handleValidate}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockContainer>
        );
      case 3:
        if (!currentSubModule) return null;

        return (
          <InlineBlockContainer>
            <Content
              onValidate={handleValidate}
              onCancel={handleCancel}
              formMethods={formMethods}
              currentSubModule={currentSubModule}
              onConfirmChapter={handleConfirmChapter}
              isLastChapter={currentSubModule?.id === `3.${totalChapters}`}
            />
          </InlineBlockContainer>
        );
      case 4:
        return (
          <InlineBlockContainer>
            <AddPriceForm
              onValidate={() => {
                handleValidate();
                setConfirmShow(true);
              }}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockContainer>
        );
      case 5:
        return (
          <InlineBlockMarginTopContainer>
            <AddExercicesForm
              onValidate={handleValidate}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockMarginTopContainer>
        );
      case 6:
        return (
          <InlineBlockMarginTopContainer>
            <TrainerNewPaymentForm />
          </InlineBlockMarginTopContainer>
        );
      default:
        return null;
    }
  } else if (trainingType === "Application") {
    switch (selectedElement) {
      case 1:
        return (
          <TrainingType
            setItems={setItems}
            onCancel={handleCancel}
            onValidate={handleValidate}
          />
        );
      case 2:
        return (
          <InlineBlockContainer>
            <CreateApplication
              onValidate={handleValidate}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockContainer>
        );
      case 3:
        return (
          <InlineBlockContainer>
            <Content
              onValidate={handleValidate}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockContainer>
        );
      case 4:
        return (
          <InlineBlockContainer>
            <AddPriceForm
              onValidate={() => {
                setConfirmShow(true);
              }}
              onCancel={handleCancel}
              formMethods={formMethods}
            />
          </InlineBlockContainer>
        );
      case 5:
        return (
          <InlineBlockMarginTopContainer>
            <TrainerNewPaymentForm />
          </InlineBlockMarginTopContainer>
        );
      default:
        return null;
    }
  }

  return null;
};
