import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addDetailsTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import {
  extractFileAttributes,
  s3UrlToFile,
} from "../../../../utils/fileConvertion";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./CreateApplication.constants";
import { ContentFormContainer } from "./CreateApplication.style";
import { StyledLabel } from "../TrainerForm/TraininerForm.style";

export default function CreateApplication({ formMethods , data, onValidate, onCancel }) {

  const watchTrainingTitle         = formMethods.watch('trainingTitle') ;
  const watchTrainingTkills        = formMethods.watch('skills') ;
  const watchTrainingTDescription  = formMethods.watch('trainingDescription') ;
  const watchTrainingTDuration     = formMethods.watch('durationTraining') ;
  const watchTrainingTImage        =  formMethods.watch('imageTraining') ;


  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    onValidate();
  };

  return (
    <>
         <FormProvider {...formMethods}>
              <ContentFormContainer>
                    <StyledLabel>1. Training title</StyledLabel>

                      <GenericInput
                        inputObject={{
                          ...formConfig.title,
                        }}
                        disabledForm={false}
                      />
                  <StyledLabel>2. Training Description</StyledLabel>

                    <GenericInput
                      inputObject={{
                        ...formConfig.description,
                      }}
                      disabledForm={false}
                    />
                  <StyledLabel>2. Training Duration (hours) </StyledLabel>
                    <GenericInput
                      inputObject={{
                        ...formConfig.durationTraining,
                      }}
                      disabledForm={false}
                    />
 
                    <StyledLabel>3. Training Skills</StyledLabel>

                    <GenericInput
                      inputObject={{
                        ...formConfig.skills,
                      }}
                      disabledForm={false}
                    />

                    <StyledLabel>4. Training Image</StyledLabel>
                    <GenericInput
                      inputObject={{
                        ...formConfig.image,
                        accept: "image/*",
                      }}
                      disabledForm={false}
                    />
  
                  <div style={{ float: "right", marginTop: "10%" }}>
                    <Button variant="light" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !watchTrainingTitle || !watchTrainingTDescription || !watchTrainingTDuration || !watchTrainingTkills || !watchTrainingTImage
                      }
                      style={{ marginLeft: "15px" }}
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      Confirm
                    </Button>
                  </div>
              </ContentFormContainer>
            </FormProvider>
    </>
  );
}
