import { InputTypes } from "../../../Inputs/input.type";

export const formConfig = {
  radio1: {
    inputType: InputTypes.RADIO_2,
    fieldName: "session_training",
    config: {
      required: true,
     
    },
  },
  productType: {
    inputType: InputTypes.MULTI_RADIO,
    fieldName: "productType",
    config: {
      required: true,
    },
    options: [
      {
        id: "1",
        label: "Video Training",
        value: "Video Training",
      },
      {
        id: "2",
        label: "Source Code",
        value: "Application",
      },
    ],
  },  
  radio3: {
    inputType: InputTypes.RADIO_2,
    fieldName: "Application",
    config: {
      required: true,
     
    },
  },
  

};
