export const StorageKeys = {
  TOKEN: "token",
  STREAMTOKEN: "getstreamtoken",
  USER: "user",
  ENTREPRISE: "entreprise",
  RESOURCES: "resources",
  TYPE: "type",
  ISLOGGEDIN: "isloggedin",
  PROJECTS:"projects",
  PROJECT_CREATED: "project_created",
  PROPOSAL_ENTREPRISE_CREATION:"proposal_entreprise_creation",
  PROJECT_PROPOSAL_ENTREPRISE_CREATION:"project_proposal_entreprise_creation",
  PROPOSALS:"proposals",
  PROXY:"proxy",
  PROXY_TYPE:"proxy_type",
  PROJECT_CREATION: "project_creation",
  PROPOSAL_CREATION: "proposal_creation",
  CHATID: "chatId",
  DASHBOARDDETAILS: "dashboardDetails",
  COUNT_SHARE_PROJECT: "count_share_project",
  COUNT_COMPOSE_FREELANCE: "count_compose_freelance",
  COUNT_PRODUCT: "count_product",
  COUNT_OFFRE: "count_offre",
  BALANCE:"balance",
  COUNT_FOLDER_INPROGRESS: "folder_nbr_inprogress"
};
