import styled from "styled-components";

export const StyledFormationDetailContainer = styled.div`
  display: flex;
  // flex grow
  flex-grow: 1;
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
`;

export const StyledFormationDetailContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-top: 20px;
  width: 100%;
  // change padding according to screen width
  @media (max-width: 768px) {
    padding-inline: 20px;
  }
  @media (min-width: 768px) {
    padding-inline: 50px;
  }
  @media (min-width: 1024px) {
    padding-inline: 80px;
  }
  @media (min-width: 1440px) {
    padding-inline: 10%;
  }
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
`;
