import { useEffect } from "react";
import BestTools from "./BestTools/BestTools";
import { RootStyle } from "./DashboardHome.style";
import FooterHome from "./FooterHome/FooterHome";
import Formations from "./Formations/Formations";
import Landing from "./Landing/Landing";
import LandingContent from "./LandingContent/LandingContent";
import Recruitment from "./recruitment/Recruitment";
import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

function DashboardHome({active, isMobile}) {
  const navigate = useNavigate();


  useEffect(() => {
    // Define an async function within useEffect
    const fetchTokenAndNavigate = async () => {
      const token = await getAccessToken();
      if (token) {
        try {
          const decodeToken = jwtDecode(token);
          const typeUser = decodeToken.role;

          switch (typeUser) {
            case 'CANDIDAT':
              navigate(`/dashboardCandidat`, { replace: true });
              break;
            case 'ENTREPRISE':
              navigate(`/DashboardCompany`, { replace: true });
              break;
            case 'RECRUTER':
              navigate(`/dashboardRecruter`, { replace: true });
              break;
            case 'ACCOUNTING':
              navigate(`/dashboardAccounting`, { replace: true });
              break;
            default:
              console.warn("User role not recognized");
              break;
          }
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      } else {
        console.warn("No valid token found");
      }
    };

    // Call the async function
    fetchTokenAndNavigate();
  }, [navigate]);

  return (
    <RootStyle spacing={12.5}>
      <BestTools active={active} isMobile={isMobile}  />
      <Formations />
      <Recruitment />
      <Landing />
      <LandingContent />
      <FooterHome />
    </RootStyle>
  );
}

export default DashboardHome;
