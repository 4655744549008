import React from 'react';
import EditProfil from "components/ComponnentProfilItems/EditProfil";
import { useGetSharedCandidatQuery } from "../../redux/api/candidat/candidatApi";
import { useGetSharedEntrepriseQuery } from "../../redux/api/entreprise/entrepriseApi"; // Adjust the import according to your structure
import { useGetSharedRecruterQuery } from "../../redux/api/recruter/recruterApi"; // Adjust the import according to your structure
import { BlocShowJob }  from "components/ModalITgalaxy/ModalApply/ModalApply.style";
export default function ShareCandidat() { // Capitalized component name

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const type = queryParams.get('type');

    // Fetch shared candidate data
    const { data: candidateData, isLoading: isCandidateLoading, isError: isCandidateError } = useGetSharedCandidatQuery(token, {
        skip: !(type === "CANDIDAT" && token), // Skip if type is not "CANDIDAT" or token is not available
    });

    // Fetch shared enterprise data
    const { data: entrepriseData, isLoading: isEntrepriseLoading, isError: isEntrepriseError } = useGetSharedEntrepriseQuery(token, {
        skip: !(type === "ENTREPRISE" && token), // Skip if type is not "ENTREPRISE" or token is not available
    });

    // Fetch shared recruiter data
    const { data: recruterData, isLoading: isRecruterLoading, isError: isRecruterrror } = useGetSharedRecruterQuery(token, {
        skip: !(type === "RECRUTER" && token), // Skip if type is not "RECRUTER" or token is not available
    });

    // Handle loading state for all queries
    if (isCandidateLoading || isEntrepriseLoading || isRecruterLoading) {
        return <div>Loading...</div>; // Display a loading state
    }

    // Handle error state for all queries
    if (isCandidateError || isEntrepriseError || isRecruterrror) {
        return <div>Error occurred while fetching data</div>; // Display an error message
    }

    // Determine which data to pass to EditProfil based on the type
    let userData;
    if (type === "CANDIDAT") {
        userData = candidateData;
    } else if (type === "ENTREPRISE") {
        userData = entrepriseData;
    } else if (type === "RECRUTER") {
        userData = recruterData;
    }

    // Handle the case when user data is not available
    if (!userData) {
        return <div>No user data available</div>;
    }

    return (
         <BlocShowJob>
                <EditProfil
                userData={userData} // now it will use the correct data based on type
                editProfil={false}
                role={type} // Adjust role according to type
            />
        </BlocShowJob>
    );
}
