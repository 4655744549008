import { useState } from "react";
import styled from "styled-components";

const ContainerCard = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 5px 5px 0px 0px black;
`;

const CardContainer = styled.div`
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
`;

const ChapterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 5px;
`;

const ChapterTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

const Duration = styled.span`
  font-size: 0.9rem;
  color: #666;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
`;

const ModuleList = styled.div`
  padding-left: 15px;
  display: ${(props) => (props.expanded ? "block" : "none")};
`;

const ModuleItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: ${(props) => (props.completed ? "#666" : "#000")};
`;

const ModuleCheckbox = styled.input`
  margin-right: 10px;
`;

const ChaptersCard = ({ chapters }) => {
  const [expandedChapters, setExpandedChapters] = useState({});
  const [chapterData, setChapterData] = useState(chapters);

  const toggleChapter = (id) => {
    setExpandedChapters((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleModuleCompletion = (chapterId, moduleId) => {
    setChapterData((prevChapters) =>
      prevChapters.map((chapter) =>
        chapter.id === chapterId
          ? {
            ...chapter,
            modules: chapter.modules.map((module) =>
              module.id === moduleId
                ? { ...module, completed: !module.completed }
                : module
            ),
          }
          : chapter
      )
    );
  };

  return (
    <ContainerCard>
      {chapterData.map((chapter) => {
        const isExpanded = expandedChapters[chapter.id];
        const allCompleted = chapter.modules.every((module) => module.completed);

        return (
          <CardContainer key={chapter.id}>
            <ChapterHeader onClick={() => toggleChapter(chapter.id)}>
              <div>
                <ChapterTitle>{chapter.title}</ChapterTitle>
                <Duration>{chapter.duration}</Duration>
              </div>
              <ArrowButton>{isExpanded ? "∧" : "∨"}</ArrowButton>
            </ChapterHeader>
            <ModuleList expanded={isExpanded}>
              {chapter.modules.map((module) => (
                <ModuleItem key={module.id} completed={module.completed}>
                  <ModuleCheckbox
                    type="checkbox"
                    checked={module.completed}
                    onChange={() => toggleModuleCompletion(chapter.id, module.id)}
                  />
                  {module.type} : {module.title} - {module.duration}
                </ModuleItem>
              ))}
              {allCompleted && <p>Chapter completed!</p>}
            </ModuleList>
          </CardContainer>
        );
      })}
    </ContainerCard>
  );
};

export default ChaptersCard;
