import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./TrainingType.constants";
import { ContentFormContainer, StyledLabel } from "./TrainingType.style";
import { VIDEO_TRAINING, APPLICATION, VIDEO_TRAINING_ITEMS , APPLICATION_ITEMS } from "../../../../core/constants/trainerForm.constants";


const TrainingType = ({ trainingType, onValidate, onCancel, setItems }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formMethods = useForm({ mode: "onChange", shouldFocusError: true });
  
  const watchProductType = formMethods.watch('productType');
  const [showError, setShowError] = useState(false);

  const handleValidateClick = () => {
      // Check if a product type is selected
      if (!watchProductType) {
          setShowError(true);
      } else {
          setShowError(false); 
          onValidate(); 
      }
  };

  return (
    <FormProvider {...formMethods}>
      <ContentFormContainer>
        <GenericInput
          inputObject={{
            ...formConfig.productType,
          }}
          label="Select Training Type" // Adjust label as needed
          value={watchProductType} // The currently selected value
          onChange={(value) => {
            if (value === "Video Training") {
              //  dispatch(addTypeTraining(VIDEO_TRAINING));
                setItems(VIDEO_TRAINING_ITEMS);
            } else if (value === "Application") {
               // dispatch(addTypeTraining(APPLICATION));
                setItems(APPLICATION_ITEMS);
            }
          }}
          disabledForm={false}
        />

        {/* Buttons for Cancel and Validate */}
        <div style={{ float: "right", marginTop: "10%" }}>
          <Button variant="light" onClick={onCancel}>Cancel</Button>
          <Button
            disabled={!watchProductType} // Disable if no type selected
            style={{ marginLeft: "15px" }}
            variant="primary"
            onClick={handleValidateClick}
          >
            Confirm
          </Button>
        </div>
      </ContentFormContainer>
    </FormProvider>
  );
};

export default TrainingType;

       
