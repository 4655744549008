import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { formConfig } from "../editProfil.constants";
import {
  InputContainer,
  StayledLabel,
  StyledSubmitEditProfileButton,
} from "../Entreprise/styled";
import { useUpdateEntrepriseMutation } from "../../../../redux/api/entreprise/entrepriseApi";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { BackButton } from "components/NewComposeyourTeam/NewComposeyourTeam.style";
import Vector from "assets/IconITgalaxy/Vector.svg";

const EditFormEntrepriseProfile = ({ data, editModalOpen, onSaveChanges, onClose }) => {
  const methods = useForm();
  const [updateEntreprise, { isLoading }] = useUpdateEntrepriseMutation();
  
  const [country_details, setCountryDetails] = useState();
  const [charCount, setCharCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  
  const maxChars = 200;

  useEffect(() => {
    if (data) {
      methods.reset({
        username: data.username,
        email: data.email,
        entreprise_description: data.entreprise_description,
        website: data.website || '', // Fallback for null
      });
      setCountryDetails(data.country_details);
      setCharCount(data?.entreprise_description?.length || 0);
    }
  }, [data, methods]);

  const handleCountryDetailsChange = (selectedLocations) => {
    setCountryDetails(selectedLocations.value);
  };

  const onSubmit = async (formData) => {
    if (charCount > maxChars) {
      setErrorMessage(`Description cannot exceed ${maxChars} characters.`);
      return; // Prevent submission
    }

    const updatedData = {
      ...formData,
      country_details,
    };

    try {
      const updatedEntreprise = await updateEntreprise({ entrepriseId: data.id, entrepriseData: updatedData }).unwrap();
      onSaveChanges(updatedEntreprise);
      onClose();
    } catch (error) {
      console.error("Failed to update the entreprise:", error);
    }
  };

  const handleChangeDesc = (event) => {
    const { value } = event.target;
    setCharCount(value.length);
    if (value.length <= maxChars) {
      setErrorMessage(""); // Clear error message if within limits
    }
  };

  return (
    <Modal show={editModalOpen} onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>  
          <BackButton onClick={onClose}>
            <img src={Vector} style={{ width: "0.50vw" }} alt="vector" />
          </BackButton>
          <span style={{ marginLeft: '20px' }}>Edit Profile</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                {["username",  "email"].map((field) => {
                  const isDisabled = field === "username" || field === "email";
                  return (
                    <InputContainer key={field}>
                      <Form.Group controlId={`form${field.charAt(0).toUpperCase() + field.slice(1)}`}>
                        <StayledLabel>{field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</StayledLabel>
                        <Form.Control 
                          type={field === "email" ? "email" : "text"} 
                          {...methods.register(field)} 
                          disabled={isDisabled}
                        />
                      </Form.Group>
                    </InputContainer>
                  );
                })}
              </Col> 
              <Col md={6}>
                <InputContainer>
                  <StayledLabel>Location</StayledLabel>
                  <GenericInput
                    inputObject={{
                      ...formConfig.country_details,
                      label: "country_details"
                    }}
                    onChange={handleCountryDetailsChange}
                    disabledForm={false}
                  />
                </InputContainer>

                <InputContainer>
                  <Form.Group controlId="formWebsite">
                    <StayledLabel>WEBSITE OR LINKEDIN</StayledLabel>
                    <Form.Control
                      type="website" // Set the correct input type
                      placeholder="https://yourprofile.com"
                      {...methods.register("website")}
                    />
                    {methods.formState.errors.website && (
                      <span style={{ color: 'red' }}>
                        {methods.formState.errors.website.message}
                      </span>
                    )}
                  </Form.Group>
                </InputContainer>
              </Col>
            </Row>

            <InputContainer>
              <Form.Group controlId="formEntrepriseDescription">
                <StayledLabel>About Me</StayledLabel>
                <Form.Control
                  as="textarea"
                  rows={9}
                  {...methods.register("entreprise_description", {
                    onChange: handleChangeDesc,
                  })}
                />
                <div style={{ marginTop: '5px', fontSize: '12px', color: charCount >= maxChars ? 'red' : 'black' }}>
                  {charCount}/{maxChars} characters
                </div>
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form.Group>
            </InputContainer>

            <StyledSubmitEditProfileButton variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Changes"}
            </StyledSubmitEditProfileButton>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default EditFormEntrepriseProfile;
