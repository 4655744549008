import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 5px 5px 0px 0px var(--BaseBaseBlack);
  width: 100%;
  margin: 20px auto;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const TabNavigation = styled.div`
  display: flex;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
`;

const Tab = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  color: ${(props) => (props.active ? "#007bff" : "#666")};
  border-bottom: ${(props) => (props.active ? "2px solid #007bff" : "none")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
`;

const ChapterDetails = () => {
    const [activeTab, setActiveTab] = useState("Notes");

    const tabContent = {
        Notes: (
            <Content>
                <p>
                    This course aims to teach everyone the basics of programming computers using Python. We cover the basics of how one constructs a program from a series of simple instructions in Python. The course has no prerequisites and avoids all but the simplest mathematics. Anyone with moderate computer experience should be able to master the materials in this course. This course will cover Chapters 1-5 of the textbook "Python for Everybody". Once a student completes this course, they will be ready to take more advanced programming courses. This course covers Python 3.
                </p>
            </Content>
        ),
        Resources: (
            <Content>
                <ul>
                    <li>Python Documentation: <a href="https://docs.python.org/3/">https://docs.python.org/3/</a></li>
                    <li>Python for Everybody Textbook</li>
                    <li>Additional tutorials and exercises</li>
                </ul>
            </Content>
        ),
        Downloads: (
            <Content>
                <ul>
                    <li>Course Slides (PDF)</li>
                    <li>Code Samples (ZIP)</li>
                    <li>Assignments (ZIP)</li>
                </ul>
            </Content>
        ),
        Discuss: (
            <Content>
                <p>Join the course discussion forum to ask questions, share ideas, and get help from other students and instructors.</p>
            </Content>
        ),
    };

    return (
        <Container>
            <Title>Chapter One: Why We Program (continued)</Title>
            <TabNavigation>
                {["Notes", "Resources", "Downloads", "Discuss"].map((tab) => (
                    <Tab
                        key={tab}
                        active={activeTab === tab}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </Tab>
                ))}
            </TabNavigation>
            {tabContent[activeTab]}
        </Container>
    );
};

export default ChapterDetails;
