export const skillsData = [
  {
    id: "1",
    title: "HTML",
  },
  {
    id: "2",
    title: "CSS",
  },
  {
    id: "3",
    title: "CSS tools",
  },
  {
    id: "4",
    title: "Photoshop",
  },
  {
    id: "5",
    title: "JavaScript",
  },
  {
    id: "6",
    title: "Java",
  },
  {
    id: "7",
    title: "JavaFX",
  },
  {
    id: "8",
    title: "NodeJS",
  },
  {
    id: "9",
    title: "Go",
  },
  {
    id: "10",
    title: "Python",
  },
  {
    id: "11",
    title: "NumPy",
  },
  {
    id: "12",
    title: "Scrum",
  },
  {
    id: "13",
    title: "Coaching",
  },
  {
    id: "14",
    title: "Machine Learning",
  },
  {
    id: "15",
    title: "UI/UX Design",
  },
  {
    id: "16",
    title: "React",
  },
  {
    id: "17",
    title: "Search Engine Optimization",
  },
  {
    id: "18",
    title: "Project Coordination",
  },
  {
    id: "19",
    title: "Stakeholder Engagement",
  },
  {
    id: "20",
    title: "Microsoft Excel",
  },
  {
    id: "21",
    title: "Microsoft Outlook",
  },
  {
    id: "22",
    title: "Microsoft Word",
  },
  {
    id: "23",
    title: "Commercial Awareness",
  },
  {
    id: "24",
    title: "Robot Framework",
  },
  {
    id: "25",
    title: "Scaled Agile Framework",
  },
  {
    id: "26",
    title: "Test-driven development",
  },
  {
    id: "27",
    title: "Conflict resolution",
  },
  {
    id: "28",
    title: "Negotiation",
  },
  {
    id: "29",
    title: "HR Policies",
  },
  {
    id: "30",
    title: "Spring framework",
  },
  {
    id: "31",
    title: "Git",
  },
  {
    id: "32",
    title: "Docker",
  },
  {
    id: "33",
    title: "Redis",
  },
  {
    id: "34",
    title: "Jira",
  },
  {
    id: "35",
    title: "PostgreSQL",
  },
  {
    id: "36",
    title: "Facilitation",
  },
  {
    id: "37",
    title: "Building Trust",
  },
  {
    id: "38",
    title: "Professional Networking",
  },
  {
    id: "39",
    title: "Identifying External Influences",
  },
  {
    id: "40",
    title: "Managing Talent",
  },
  {
    id: "41",
    title: "Creating Company Culture",
  },
  {
    id: "42",
    title: "Effective Listening",
  },
  {
    id: "43",
    title: "Being Approachable & Accessible",
  },
  {
    id: "44",
    title: "Change Management",
  },
  {
    id: "45",
    title: "Persuasion",
  },
  {
    id: "46",
    title: "Negotiating",
  },
  {
    id: "47",
    title: "Problem-Solving",
  },
  {
    id: "48",
    title: "Training",
  },
  {
    id: "49",
    title: "Consulting",
  },
  {
    id: "50",
    title: "Angular",
  },
  {
    id: "51",
    title: "Vue",
  },
  {
    id: "52",
    title: "Express",
  },
  {
    id: "53",
    title: "Laravel",
  },
  {
    id: "54",
    title: "Php",
  },
  {
    id: "55",
    title: "Typescript",
  },
  {
    id: "56",
    title: "Symfony",
  },
];
