import AddExercices from 'components/DashboardTrainerComponnents/TrainerComponnents/AddExercices/AddExercices';
import './AddExercicesForm.css';


export default function AddExercicesForm({ onValidate, onCancel }) {

    const data = {
        type: 'composeyourteam',
        modal: false,
        titreForm: 'Veuillez entrez les information sur les exercices ',
        classnameFirst: 'pagechanel',
        classname: 'container-fluid  d-flex justify-content-end bloc',
        id: 'publicchanel',

    };
    return (
        <div>
            <AddExercices inputForm={data} onValidate={onValidate} onCancel={onCancel} />
        </div>
    );
}