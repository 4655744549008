import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./AddExercices.constants";
import { Button } from "react-bootstrap";
import { ContentFormContainer } from "./AddExercices.style";
import { useDispatch, useSelector } from "react-redux";
import { addExercicesTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import { useParams } from "react-router-dom";
import { getFileFromS3, getURL } from "../../../../redux/api/uploads/uploadSlice";
import {
  extractFileAttributes,
  fileToBase64,
  s3UrlToFile,
} from "../../../../utils/fileConvertion";

export default function AddExercices({
  inputForm,
  onValidate,
  onCancel,
  disabled,
}) {
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [editedFile, setEditedFile] = useState();
  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const trainingTypeProduct = useSelector(
    (state) => state.trainingCreation.type_product
  );
  const getUrlData = async () => {
    let location = "";
    switch (trainingTypeProduct) {
      case "APPLICATIONS":
        location = "applications";
        break;
      case "VIDEOSTRAINING":
        location = "videos_training";
        break;
      case "SESSIONTRAINING":
        location = "session_training";
        break;
    }
    if (id) {
      const url = await dispatch(
        getURL({
          location: `${location}/${id}/exercice/`,
        })
      );
      setEditedFile(url?.Contents[0].Key);
    }
  };
  useEffect(() => {
    getUrlData();
  }, []);
  const getExerciceFile = async () => {
    return await extractFileAttributes(getFileFromS3(editedFile), editedFile);
  };
  const loadExercice = async () => {
    dispatch(
      addExercicesTraining({
        exercice: {
          exercicedData: await getExerciceFile(editedFile),
          base64URL: await fileToBase64(await s3UrlToFile(editedFile)),
        },
      })
    );
    setFiles(
      await s3UrlToFile(`${process.env.REACT_APP_S3_URL}/${editedFile}`)
    );
  };
  useEffect(() => {
    if (editedFile) loadExercice();
  }, [editedFile]);

  const onChangeFiles = (e) => {
    const fileList = e.target.files;

    setFiles(fileList);
  };

  const handleSubmit = async (e) => {
    const base64URL = await fileToBase64(files[0]);
    onValidate();
    dispatch(
      addExercicesTraining({
        exercice: {
          exerciceData: {
            lastModified: files.lastModified,
            lastModifiedDate: files.lastModifiedDate,
            name: files.name,
            size: files.size,
            type: files.type,
            webkitRelativePath: files.webkitRelativePath,
          },
          base64URL: base64URL,
        },
        github: formMethods.getValues("github_link"),
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formMethods.setValue(name, value);
    setInputValue(value);
  };

  return (
    <>
      <div className={inputForm.classnameFirst}>
        <div className={inputForm.classname} id={inputForm.id}>
          <div
            className="itemprofil"
            id="Createprojet"
            style={{ width: "100%" }}
          >
            <FormProvider {...formMethods}>
              <ContentFormContainer>
                <div className="formulaire">
                  <div className="mb-4">
                    <GenericInput
                      inputObject={{
                        ...formConfig.file,
                        label: "Fichier",
                        accept: "application/zip",
                      }}
                      onChange={onChangeFiles}
                      disabledForm={false}
                    />
                    {files && <li>{files}</li>}
                  </div>
                  <div className="mb-4">
                    <GenericInput
                      inputObject={{
                        ...formConfig.link,
                        label: "Lien github",
                      }}
                      onChange={handleChange}
                      disabledForm={false}
                    />

                    <div style={{ float: "right", marginTop: "10%" }}>
                      <Button variant="light" onClick={onCancel}>
                        Annuler
                      </Button>
                      <Button
                        disabled={
                          files?.length == 0 ||
                          !formMethods.getValues("github_link")
                        }
                        style={{ marginLeft: "15px" }}
                        variant="primary"
                        onClick={handleSubmit}
                      >
                        Valider
                      </Button>
                    </div>
                  </div>
                </div>
              </ContentFormContainer>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
}
