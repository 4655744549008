import { statusesContent } from "./SideBarStatusCard.constants";
import { CardContent, CardWrapper, Content, IconWrapper, InfoIcon, Title } from "./SideBarStatusCard.style";



const SideBarStatusCard = ({ status ,folder_nbr_inprogress ,share_offres}) => {
    const { title, content, color } = statusesContent[status] || {
        title: 'Unknown Status',
        content: 'Unknown status.',
        color: '#000', // default black color
    };

    return (
        <CardWrapper>
            <IconWrapper>

                <CardContent>
                    <Title><InfoIcon color={color}>ℹ️</InfoIcon>{title} {" "}  {share_offres} {folder_nbr_inprogress} .</Title>
                    <Content>{content} {" "}  {share_offres} {folder_nbr_inprogress}. </Content>
                </CardContent>
            </IconWrapper>
        </CardWrapper>
    );
};

export default SideBarStatusCard;