import styled from "styled-components";

export const StyledModalBodyContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
`;

export const StyledPaymentFormContainer = styled.div`
  width: 100%;
  height: auto;
`;
