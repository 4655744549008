import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import GenericInput from "components/Inputs/GenericInput/GenericInput"; 
import { formConfig } from "./NewComposeyourTeam.constants";
import RichTextEditor from "components/RichTextEditor/RichTextEditor"; 
import {
  LocationStyleModal,
  TitleForm,
  ButtonSubmitStyle,
  ButtonSubmit,
  ButtonSubmitDisable,
  TextStyleModal,
  InputContainer,
  ButtonContainer,
  InputLabel,
  LocationStyle,
  ContainerStyleCompose,
  BackButton
} from "./NewComposeyourTeam.style";
import {
  AddProjectProposalEntrepriseCreation
} from "../../redux/slice/propsoalEntrepriseCreationSlice/proposalEntrepriseCreationSlice";
import toast from "react-hot-toast";
import Profilfreelances from "../ComponnentProfilItems/profilfreelances/profilfreelances";
import { useDispatch } from "react-redux";

export default function AddNewComposeyourTeam({ id, setNewComposeyourTeam }) {

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      projectDescription: "",
    },
  });

  const [projectDescription, setProjectDescription] = useState("");
  const [candidatesProfils, setCandidatesProfils] = useState(false);
  const [titleLength, setTitleLength] = useState(0);
  const { handleSubmit, control, setValue, formState: { errors, isValid }} = methods;
  const dispatch = useDispatch();

  const handlebacktoProjectList = () => {
    setNewComposeyourTeam(false);
  };

  const [formData, setFormData] = useState({
    entrepriseId: id,
    projectTitle: "",
    projectDescription: projectDescription,
    competencesselected: [],
    location: "",
    languagesselected: [],
  });

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      projectDescription: projectDescription 
    }));
  }, [projectDescription]);

  const onSubmit = () => {
    try {
      setCandidatesProfils(true);
      dispatch(AddProjectProposalEntrepriseCreation(formData));

    } catch (e) {
      console.error("Failed to create project:", e);
      const errorMessage = e.data?.error || "Please check the inputs";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleChangeTitle = (value) => {
    setTitleLength(value.length);
    setFormData(prevFormData => ({
      ...prevFormData,
      projectTitle: value
    }));
  };

  const handleChangeLocations = (value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      location: value.value
    }));
  };

  const handleChangeLanguagesSelected = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      languagesselected: selectedOptions.map(option => option.value)
    }));
  };


  const handleChangeCompetencesSelected = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      competencesselected: selectedOptions.map(option => option.value)
    }));
  };

  const handleChangeProjectDescription = (value) => {
    setProjectDescription(value); // Update local state
    setValue("projectDescription", value); // Set form value

    // Manual validation check
    if (value.length < 200) { // Assume minimum is 200 characters
      methods.setError("projectDescription", { type: "manual", message: `Description must be at least 200 characters. You entered ${value.length}/200.` });
    } else {
      // Clear error if valid
      methods.clearErrors("projectDescription");
    } 
  };

  return (
    <>
      {!candidatesProfils ? (
        <ContainerStyleCompose>
          <BackButton onClick={handlebacktoProjectList}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </BackButton>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TitleForm>
                Describe your Project
                <hr />
              </TitleForm>
  
              <InputContainer>
                <InputLabel>Project Title*</InputLabel>
                <GenericInput
                  inputObject={{
                    ...formConfig.projectTitle
                  }}
                  onChange={handleChangeTitle} // Adjusted to expect single value
                  disabledForm={false}
                  control={control}
                />                     
                <p style={{ fontSize: '15px', textAlign: 'right', color: titleLength >= 50 ? 'red' : 'black' }}>
                  {titleLength}/50
                </p>
              </InputContainer>
  
              <LocationStyle>
                <InputContainer>
                  <InputLabel>Project Location*</InputLabel>
                  <GenericInput
                    inputObject={{
                      ...formConfig.location,
                    }}
                    control={control}
                    onChange={handleChangeLocations}
                    disabledForm={false}
                  />
                </InputContainer>
            
                <InputContainer>
                  <InputLabel>Languages Selected*</InputLabel>
                  <GenericInput
                    inputObject={{
                      ...formConfig.languagesselected
                    }}
                    onChange={handleChangeLanguagesSelected}
                    disabledForm={false}
                  />
                </InputContainer>
              </LocationStyle>
  
              <InputContainer>
                <InputLabel>Project Description*</InputLabel>
                <RichTextEditor
                  value={projectDescription}
                  onChange={handleChangeProjectDescription} // Ensure that validation and setter are passed
                />
                {errors.projectDescription && (
                  <p style={{ color: 'black', fontSize: 'small', textAlign: 'right', marginTop: '4px' }}>
                    {errors.projectDescription.message}
                  </p>
                )}
              </InputContainer>
  
              <InputContainer>
                <InputLabel>Competences Selected*</InputLabel>
                <GenericInput
                  inputObject={{
                    ...formConfig.competencesselected
                  }}
                  control={control}
                  onChange={handleChangeCompetencesSelected}
                  disabledForm={false}
                />
              </InputContainer>
  
              <ButtonContainer>
                {isValid && Object.keys(errors).length === 0 ? (
                  <ButtonSubmit onClick={onSubmit} >
                    {'Compose your team'}
                  </ButtonSubmit>
                ) : (
                  <ButtonSubmitDisable disabled>
                    Compose your team
                  </ButtonSubmitDisable>
                )}
              </ButtonContainer>
            </form>
          </FormProvider>
        </ContainerStyleCompose> 
      ) : (
        <Profilfreelances setCandidatesProfils={setCandidatesProfils}/>
      )}
    </>
  );
}
