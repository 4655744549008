import styled from "styled-components";
export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;
`;

export const TextContentStyle = styled.p`
  font-family: "Chilanka", cursive, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.52px;
  text-align: left;
  color: #ffffff;
`;
