import 'font-awesome/css/font-awesome.min.css'; // Import FontAwesome if not already
import { useRef, useState, useEffect } from "react";
import bouttonadd from "../../../assets/IconITgalaxy/bouttonadd.png";
import { FilePlaceholder, linkStyle, PreviewWrapper, StyledInput, StyledUploadButton } from "./CustomFileInput.style";

const CustomFileInput = ({
  id,
  name,
  placeholder,
  onChange,
  accept,
  label,
  previewInput,
  isEdit = true
}) => {

  const fileInput = useRef();
  const [preview, setPreview] = useState(previewInput); // State for file preview
  const [fileType, setFileType] = useState(null); // State for file type
  const [fileName, setFileName] = useState(null); // State for file type


  // Update preview and fileType whenever previewInput changes
  useEffect(() => {
    if (previewInput) {
      setPreview(previewInput);
      // Check if the previewInput is an image or PDF to set fileType
      const type = previewInput.endsWith('.pdf') ? 'application/pdf' : 'image/*';
      setFileType(type);
    }
  }, [previewInput]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFileName(file.name); // Set the file name
      setFileType(file.type);
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);

      onChange(file);
    }
  };

  return (
    <StyledInput>
      <input
        type="file"
        id={label}
        name={name}
        onChange={isEdit ? handleFileChange : undefined} // Call handleFileChange only if isEdit is true
        accept={accept}
        ref={fileInput}
        disabled={!isEdit} // Disable input if not editable
        style={{ display: 'none' }} // Hide the actual input
      />

      {/* Preview Wrapper */}
      <PreviewWrapper>
        {preview ? (
          fileType.startsWith("image/") ? (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "0.25rem" }}
            />
   
          ) : fileType === "application/pdf" ? (
            <FilePlaceholder style={{ backgroundColor: 'transparent' }}>
              <p>This file is a PDF:</p>
              <a href={preview} target="_blank" rel="noopener noreferrer" style={linkStyle} download>
                {fileName}
              </a>
            </FilePlaceholder>
          ) : fileType === "application/zip" ? (
            <FilePlaceholder style={{ backgroundColor: 'transparent' }}>
              <p>This file is a ZIP archive:</p>
              <a href={preview} target="_blank" rel="noopener noreferrer" style={linkStyle} download>
                {fileName}
              </a>
            </FilePlaceholder>
          ) : (
            <FilePlaceholder style={{ backgroundColor: 'transparent' }}>
              <p>Unsupported file type</p>
            </FilePlaceholder>
          )
        ) : (
          <h6 style={{ textAlign: "center" }}>{placeholder}</h6>
        )}

        {isEdit && ( // Render the upload button only if editable
          <StyledUploadButton onClick={() => fileInput.current.click()}>
            <img
              src={bouttonadd}
              style={{ width: "28px", height: "28px" }}
              alt="Update button"
            />
          </StyledUploadButton>
        )}
      </PreviewWrapper>


    </StyledInput>
  );
};

export default CustomFileInput;
