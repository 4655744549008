import image from "../../../../../assets/IconITgalaxy/people/imageAvatar.png";
import { CustomAvatarStyle } from "../../../CustomAvatar/CustomAvatar.style";
import { ReactComponent as VerticalDotIcon } from "../../../../../assets/IconITgalaxy/dot-vertical.svg";
import {
  DateStyle,
  StackStyle,
  TextStyle,
  TypographyStyle,
} from "./Project.style";

function Project() {
  return (
    <>
      <StackStyle spacing={0.5}>
        <StackStyle
          direction={"row"}
          spacing={2.5}
          style={{ justifyContent: "space-between" }}
        >
          <TypographyStyle>Project Schedule Management</TypographyStyle>
          <VerticalDotIcon />
        </StackStyle>

        <DateStyle>Jan 31 - Feb 4</DateStyle>
      </StackStyle>
      <StackStyle direction={"row"} style={{ justifyContent: "space-between" }}>
        <TextStyle>Days Remaining: 4</TextStyle>
        <StackStyle margin={-2} direction={"row"}>
          <CustomAvatarStyle
            src={image}
            size={"20px"}
            roundedCircle
            alt="avatar"
          />
          <CustomAvatarStyle
            src={image}
            size={"20px"}
            style={{ marginLeft: "-10px" }}
            roundedCircle
            alt="rounded-circle"
          />
          <CustomAvatarStyle
            src={image}
            size={"20px"}
            style={{ marginLeft: "-10px" }}
            roundedCircle
            alt="rounded-circle"
          />
        </StackStyle>
      </StackStyle>
    </>
  );
}

export default Project;
