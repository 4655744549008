import React, { useState } from 'react';
import GenericInput from 'components/Inputs/GenericInput/GenericInput';
import { Button } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { depositCompanyFormConfig } from '../DepositCompanyForm.config';

const CompanyExistNameForm = ({ isEdit=true , onValidate, onCancel, formMethods, disableStep ,refetchCompany , refetchCompanyRequestCreation}) => {
    const [showError, setShowError] = useState(false);
    const watchCompanyName = formMethods.watch('companyName');

    const handleValidateClick = () => {
        // Check if the company name is empty
        if (!watchCompanyName) {
            setShowError(true); // Show error message if the field is empty
        } else {
            setShowError(false); // Clear error if valid
            onValidate(); // Proceed to validation logic
        }
    };

    return (
        <FormProvider {...formMethods}>
            <GenericInput
                inputObject={{
                    ...depositCompanyFormConfig.companyName,
                }}
                disabledForm={disableStep}
                placeholder="What's the name of your company?"
            />
            {/* Error message section */}
            {showError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    <strong>Please enter the name of your company.</strong>
                </div>
            )}
            <div style={{ float: "right", marginTop: "10%" }}>
                <Button variant="light" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    style={{ marginLeft: "15px" }}
                    variant="primary"
                    onClick={handleValidateClick} // Use the validation handler
                    disabled={disableStep || !watchCompanyName} // Disable if company name is empty
                >
                    Confirm
                </Button>
            </div>
        </FormProvider>
    );
}

export default CompanyExistNameForm;
