import { InputTypes } from "../../Inputs/input.type";

export const formConfig = {
  description: {
    inputType: InputTypes.TEXTAREA,
    fieldName: "description",
    config: {
      placeholder: "Expérience : j'ai une entreprise spécialisée dans ...",
    },
    row: 2,
  },
};
