import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import {
  getAccessToken,
  getIsloggedFromLocalStorage
} from "../../../core/helpers/storage.js";
import ListCandidatContainer from "./ListCandidatContainer/ListCandidatContainer.jsx";
import SideBar from "../../../components/SideBar/sideBar.jsx";
import {
  StyledITgalaxyServicesContainer,
  Styleddashboard,
} from "./ChatCandidat.style.js";
import ListServicesContainer from "./ListServicesContainer/ListServicesContainer.jsx";
import ThemeSwitcher from "./ThemeSwitcher.jsx";

const ChatCandidat = () => {
  
  const [theme, setTheme] = useState("light");
  const [usernameChat, setUsernameChat] = useState("");
  const [idCandidat, setIdCandidat] = useState("");
  const [chatId , setChatId] = useState("");
  const [role , setRole] = useState("");
  const [ isLoggedIn, setIsLoggedIn] = useState("");


  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      const candidatToken = token ? jwtDecode(token) : null;
      if (candidatToken) {
        const id           = candidatToken.id.toString();
        const usernamechat = candidatToken.usernamechat.toString();
        const Idchat       = candidatToken.chatid.toString();
        const role         = candidatToken.role.toString();
        const isLoggedIn   = getIsloggedFromLocalStorage();
        setUsernameChat(usernamechat);
        setIdCandidat(id);
        setChatId(Idchat);
        setIsLoggedIn(isLoggedIn);
        setRole(role);
      }
    };
    fetchToken();
  }, []);

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" ,  overflow: "hidden"  ,  maxHeight: "100vh"}}
    >
      <SideBar isLoggedIn={isLoggedIn} path={'/DashboardCandidat'} role={role}  id={idCandidat} />
      <ThemeSwitcher toggleTheme={toggleTheme} theme={theme} />
      <StyledITgalaxyServicesContainer theme={theme}>
        <Styleddashboard>
          <ListCandidatContainer
            idCandidat={idCandidat}
            usernameChat={usernameChat}
            chatId={chatId}
            theme={theme}
          />
          <ListServicesContainer theme={theme} />
        </Styleddashboard>
      </StyledITgalaxyServicesContainer>
    </div>
  );
};

export default ChatCandidat;
