import SideBar from "components/SideBar/sideBar";
import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useParams } from "react-router-dom";
import CourseIntro from '../../components/CourseIntro/CourseIntro';
import CourseModules from '../../components/CourseModules/CourseModules';
import CourseReviews from '../../components/CourseReviews/CourseReviews';
import { useGetTrainingByIdQuery } from "../../redux/api/training/trainingApi";
import { BackButton, StyledFormationDetailContainer, StyledFormationDetailContentContainer } from './FormationDetail.style';

const FormationDetail = () => {
  const { id } = useParams();
  const token = getAccessToken();
  const decodedToken = token ? jwtDecode(token) : null;
  console.log("id", id);
  // Use the RTK Query hook to fetch the training by ID
  const { data: formation, error, isLoading } = useGetTrainingByIdQuery({ id, type: "VIDEOSTRAINING" });

  const handleBackButton = () => {
    window.history.back();
  };

  if (isLoading) {
    return <p>Loading formation details...</p>;
  }

  if (error) {
    return <p>Failed to load formation details: {error.message}</p>;
  }

  if (!formation) {
    return <p>Formation not found.</p>;
  }

  return (
    <StyledFormationDetailContainer>
      <SideBar path={'/formations'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
      <StyledFormationDetailContentContainer>
        <BackButton onClick={handleBackButton}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </BackButton>
        <CourseIntro />
        <CourseModules />
        <CourseReviews />
      </StyledFormationDetailContentContainer>
    </StyledFormationDetailContainer>
  );
};

export default FormationDetail;
