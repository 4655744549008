import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { formConfig } from "../editProfil.constants";
import {
  InputContainer,
  StayledLabel,
  StyledSubmitEditProfileButton,
} from "../Candidat/styled";
import { useUpdateCandidatMutation } from "../../../../redux/api/candidat/candidatApi";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { BackButton } from "components/NewComposeyourTeam/NewComposeyourTeam.style";
import Vector from "assets/IconITgalaxy/Vector.svg";

const EditFormCandidateProfile = ({ data, editModalOpen, onSaveChanges, onClose }) => {
  const methods = useForm(); 
  const [updateCandidate, { isLoading }] = useUpdateCandidatMutation();
  
  const [country_details, setCountryDetails] = useState('');
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  
  const maxChars = 200;

  useEffect(() => {
    if (data) {
      methods.reset({
        name: data.name,
        first_name: data.first_name,
        email: data.email,
        github: data.github || '',
        linkedin: data.linkedin || '',
        job: data.job,
        daily_rate: data.daily_rate,
        profile_description: data.profile_description || '',
      });
      setLanguages(data.languages || []);
      setCountryDetails(data.country_details);
      setSkills(data.skills || []);
      setCharCount(data?.profile_description?.length || 0);
    }
  }, [data, methods]);

  const handleCountryDetailsChange = (selectedLocations) => {
    setCountryDetails(selectedLocations.value);
  };

  const handleSkillsChange = (selectedSkills) => {
    const valuesArray = selectedSkills.map(option => option.value);
    setSkills(valuesArray);
  };

  const handleLanguagesChange = (selectedLanguages) => {
    const valuesArray = selectedLanguages.map(option => option.value);
    setLanguages(valuesArray);
  };

  const onSubmit = async (formData) => {
    if (charCount > maxChars) {
      setErrorMessage(`Description cannot exceed ${maxChars} characters.`);
      return; // Prevent submission
    }

    const updatedData = {
      ...formData,
      country_details,
      skills,
      languages,
    };

    try {
      const updatedCandidate = await updateCandidate({ userId: data.id, candidatData: updatedData }).unwrap();
      onSaveChanges(updatedCandidate);
      onClose();
    } catch (error) {
      console.error("Failed to update the candidate:", error);
    }
  };

  const handleChangeDesc = (event) => {
    const { value } = event.target;
    setCharCount(value.length);
    if (value.length <= maxChars) {
      setErrorMessage(""); // Clear error message if within limits
    }
  };

  return (
    <Modal show={editModalOpen} onHide={onClose} centered size="xl" fullscreen>
      <Modal.Header closeButton>
        <Modal.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>  
          <BackButton onClick={onClose}>
            <img src={Vector} style={{ width: "0.50vw" }} alt="vector" />
          </BackButton>
          <span style={{ marginLeft: '20px' }}>Edit Profile</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                {["name", "first_name", "email", "job"].map((field) => {
                  const isDisabled = field === "name" || field === "first_name" || field === "email";            
                  return (
                    <InputContainer key={field}>
                      <Form.Group controlId={`form${field.charAt(0).toUpperCase() + field.slice(1)                      }`}>
                        <StayledLabel>{field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</StayledLabel>
                        <Form.Control 
                          type={field === "email" ? "email" : "text"} 
                          {...methods.register(field)} 
                          disabled={isDisabled} // Disable certain inputs
                        />
                      </Form.Group>
                    </InputContainer>
                  );
                })}

                <InputContainer>
                  <Form.Group controlId="formSkills">
                    <StayledLabel>Skills</StayledLabel>
                    <GenericInput
                      inputObject={{
                        ...formConfig.skills,
                        label: "skills",
                      }}
                      onChange={handleSkillsChange}
                      disabledForm={false}
                    />
                  </Form.Group>
                </InputContainer>
              </Col> 
              <Col md={6}>
                <InputContainer>
                  <StayledLabel>Location</StayledLabel>
                  <GenericInput
                    inputObject={{
                      ...formConfig.country_details,
                      label: "country_details"
                    }}
                    onChange={handleCountryDetailsChange}
                    disabledForm={false}
                  />
                </InputContainer>

                <InputContainer>
                  <Form.Group controlId="formLanguages">
                    <StayledLabel>Languages</StayledLabel>
                    <GenericInput
                      inputObject={{
                        ...formConfig.languages,
                        label: "Languages"
                      }}
                      onChange={handleLanguagesChange}
                      disabledForm={false}
                    />
                  </Form.Group>
                </InputContainer>

                {["linkedin", "github", "daily_rate"].map((field) => (
                  <InputContainer key={field}>
                    <Form.Group controlId={`form${field.charAt(0).toUpperCase() + field.slice(1)}`}>
                      <StayledLabel>
                        {field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                      </StayledLabel>

                      <Form.Control
                        type={field === 'daily_rate' ? 'number' : 'text'}
                        {...methods.register(field, {
                          validate: {
                            isUrl: value => {
                              if (field === 'linkedin' || field === 'github') {
                                if (value === '') return true; 
                                return value.startsWith('www.') || `${field.charAt(0).toUpperCase() + field.slice(1)} URL must start with 'www.'`;
                              }
                              return true; // No URL validation for daily_rate
                            },
                            isNumber: value => {
                              if (field === 'daily_rate') {
                                return !isNaN(value) && Number(value) >= 0 || 'Daily rate must be a positive number';
                              }
                              return true;
                            }
                          }
                        })}
                        placeholder={
                          field === 'linkedin' ? 'www.linkedin.com/in/yourprofile' : 
                          field === 'github' ? 'www.github.com/yourusername' : 
                          'Enter daily rate'
                        }
                      />
                      {methods.formState.errors[field] && (
                        <span style={{ color: 'red' }}>
                          {methods.formState.errors[field].message}
                        </span>
                      )}
                    </Form.Group>
                  </InputContainer>
                ))}
              </Col>
            </Row>

            <InputContainer>
              <Form.Group controlId="formCandidateDescription">
                <StayledLabel>About Me</StayledLabel>
                <Form.Control
                  as="textarea"
                  rows={9}
                  {...methods.register("profile_description", {
                    onChange: handleChangeDesc, // Track character count
                  })}
                />
                <div style={{ marginTop: '5px', fontSize: '12px', color: charCount >= maxChars ? 'red' : 'black' }}>
                  {charCount}/{maxChars} characters
                </div>
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form.Group>
            </InputContainer>

            <StyledSubmitEditProfileButton variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Changes"}
            </StyledSubmitEditProfileButton>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default EditFormCandidateProfile;

