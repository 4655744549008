import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetTrainingByIdQuery } from "../../../../redux/api/training/trainingApi.js";
import { addPriceTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice.js";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./AddNumberVideo.constants";
import { ContentFormContainer } from "./AddNumberVideo.style.js";

export default function AddNumberVideo({ inputForm, onValidate, onCancel }) {
  const { id } = useParams();
  const training = useSelector((state) => state.trainingCreation);
  const dispatch = useDispatch();
  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const { data, error, isLoading, refetch } = useGetTrainingByIdQuery(id);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    formMethods.setValue(name, value);
    setInputValue(value);
  };
  const handleSubmit = (e) => {
    onValidate();
    dispatch(
      addPriceTraining({
        price: formMethods.getValues("price"),
        currency: formMethods.getValues("currency"),
      })
    );
  };
  useEffect(() => {
    if (id) {
      setInputValue(data?.data?.price);
    }
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <ContentFormContainer>
          <div
            className="formulaire"
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div className="mb-4">
                <GenericInput
                  inputObject={{
                    ...formConfig.price,
                    label: inputForm.titreForm,
                    defaultValue: data?.data?.price,
                  }}
                  onChange={(e) => handleChange(e)}
                  disabledForm={false}
                />
              </div>
              <div className="mb-4">
                <div style={{ float: "right", marginTop: "10%" }}>
                  <Button variant="light" onClick={onCancel}>
                    Annuler
                  </Button>
                  <Button
                    disabled={!inputValue}
                    style={{ marginLeft: "15px" }}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ContentFormContainer>
      </FormProvider>
    </>
  );
}
