import { InputTypes } from "../../../Inputs/input.type";

export const formConfig = {

  price: {
    inputType: InputTypes.NUMBER,
    fieldName: "price",
    config: {
      required: true,
      placeholder:
        "Créez un site wordpress réactif avec une fonctionnalité de réservation/paiement",
    },
  },
  currency: {
    inputType: InputTypes.SELECT,
    fieldName: "currency",
    config: {
      required: true,
      placeholder:
        "Créez un site wordpress réactif avec une fonctionnalité de réservation/paiement",
    },
  },

};
