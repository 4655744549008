import { createSlice } from "@reduxjs/toolkit";

export const trainingCreationSlice = createSlice({
  name: "trainingCreation",
  initialState: {
    type_product: "",
    videos: [],
    files: {},
    details: {},
    type: "Video Training",
    level: "",
    exercices: {},
    price: "",
    image_url: "",
    current_image_url: "",
    current_video_url: "",
    current_exercices_url: "",
  },

  reducers: {
    clearVideosTraining: (state) => {
      state.videos = [];
    },
    addTypeTraining: (state, action) => {
      state.type = action.payload;
    },
    addImageURL: (state, action) => {
      state.image_url = action.payload;
    },
    getFileURL: (state, action, type) => {
      switch (state.type_product) {
        case "APPLICATIONS":
          switch (type) {
            case "VIDEO":
              state.current_video_url = `/applications/${action.id}/videos/${action.file_name}`;
              break;
            case "EXERCICE":
              state.current_exercices_url = `/applications/${action.id}/exercices/${action.file_name}`;
              break;
            case "IMAGE":
              state.current_exercices_url = `/applications/${action.id}/images/${action.file_name}`;
              break;
          }
          break;
        case "VIDEOSTRAINING":
          switch (type) {
            case "VIDEO":
              state.current_video_url = `/video_training/${action.id}/videos/${action.file_name}`;
              break;
            case "EXERCICE":
              state.current_exercices_url = `/video_training/${action.id}/exercices/${action.file_name}`;
              break;
            case "SUPPORT":
              state.current_exercices_url = `/video_training/${action.id}/supports/${action.file_name}`;
              break;
            case "IMAGE":
              state.current_exercices_url = `/video_training/${action.id}/images/${action.file_name}`;
              break;
          }
          break;
        case "SESSIONTRAINING":
          switch (type) {
            case "VIDEO":
              state.current_video_url = `/session_training/${action.id}/videos/${action.file_name}`;
              break;

            case "SUPPORT":
              state.current_exercices_url = `/session_training/${action.id}/supports/${action.file_name}`;
              break;
            case "IMAGE":
              state.current_exercices_url = `/session_training/${action.id}/images/${action.file_name}`;
              break;
          }
      }
    },
    getVideoURL: (state, action) => {
      switch (state.type_product) {
        case "APPLICATIONS":
          state.current_video_url = `/applications/${action.id}/videos/${action.file_name}`;
          break;
        case "VIDEOSTRAINING":
          state.current_video_url = `/video_trainings/${action.id}/videos/${action.file_name}`;
          break;
        case "SESSIONTRAINING":
          state.current_video_url = `/session_trainings/${action.id}/videos/${action.file_name}`;
          break;
        default:
          state.current_video_url = "";
          break;
      }
    },
    getImageURL: (state, action) => {
      switch (state.type_product) {
        case "APPLICATIONS":
          state.current_image_url = `/applications/${action.id}/image/${action.file_name}`;
          break;
        case "VIDEOSTRAINING":
          state.current_image_url = `/video_trainings/${action.id}/image/${action.file_name}`;
          break;
        case "SESSIONTRAINING":
          state.current_image_url = `/session_trainings/${action.id}/image/${action.file_name}`;
          break;
        default:
          state.current_exercices_url = "";
          break;
      }
    },
    getExerciceURL: (state, action) => {
      switch (state.type_product) {
        case "APPLICATIONS":
          state.current_exercices_url = `/applications/${action.id}/exercices/${action.file_name}`;
          break;
        case "VIDEOSTRAINING":
          state.current_exercices_url = `/video_trainings/${action.id}/exercices/${action.file_name}`;
          break;
        case "SESSIONTRAINING":
          state.current_exercices_url = `/session_trainings/${action.id}/exercices/${action.file_name}`;
          break;
        default:
          state.current_exercices_url = "";
      }
    },
    addVideoURL: (state, action) => {
      state.image_url = action.payload;
    },
    addTypeProductTraining: (state, action) => {
      state.type_product = action.payload;
    },
    addLevelTraining: (state, action) => {
      state.level = action.payload;
    },
    addVideoTraining: (state, action) => {
      state.videos = [...state.videos, action.payload];
    },
    addFilesTraining: (state, action) => {
      state.files = action.payload;
    },
    addDetailsTraining: (state, action) => {
      state.details = action.payload;
    },
    addExercicesTraining: (state, action) => {
      state.exercices = action.payload;
    },
    addPriceTraining: (state, action) => {
      state.price = action.payload;
    },
    editVideoTraining: (state, action) => {
      state.videos = state.videos.map((item) =>
        item.videoUrl === action.payload.videoUrl
          ? {
              ...item,
              titleVideo: action.payload.titleVideo,
              descriptionVideo: action.payload.descriptionVideo,
              videoUrl: action.payload.videoUrl,
            }
          : item
      );
    },
  },
});

export default trainingCreationSlice.reducer;
export const {
  addVideoTraining,
  editVideoTraining,
  addFilesTraining,
  addDetailsTraining,
  addPriceTraining,
  addExercicesTraining,
  addTypeTraining,
  addLevelTraining,
  addTypeProductTraining,
  clearVideosTraining,
  addImageURL,
} = trainingCreationSlice.actions;
