import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";
import { AuthRoutes } from "../../../core/constants/routes.constants";
import "./Topbars.css";
import Navbar from "react-bootstrap/Navbar";
import {
  Logo,
  LogoWrapper,
  ButtonInscription,
  ButtonConnexion,
  NavbarButtonWrapper,
  ContainerWrapper,
} from "../styled";
import Login from "../../Authentification/modals/login";
import Register from "../../Authentification/modals/register";

export default function Topbars({ openLogin, openRegister, loginToProducts }) {
  const navigate = useNavigate();
  const [openModalLogin, setOpenModalLogin] = useState(openLogin);
  const [openModalRegister, setOpenModalRegister] = useState(openRegister);

  const handleModalLogin = () => {
    setOpenModalLogin(!openModalLogin);
  };

  const handleModalRegister = () => {
    setOpenModalRegister(!openModalRegister);
  };

  const switchBetweenModals = () => {
    if (openModalLogin) {
      setOpenModalLogin(false);
      setOpenModalRegister(true);
    } else if (openModalRegister) {
      setOpenModalRegister(false);
      setOpenModalLogin(true);
    }
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <ContainerWrapper fluid>
        <Navbar.Brand href="#">
          <LogoWrapper>
            <Logo src={logo} onClick={() => navigate("/")} />
          </LogoWrapper>
        </Navbar.Brand>
        <NavbarButtonWrapper>
          <ButtonInscription onClick={handleModalRegister}>
            S'inscrire
          </ButtonInscription>
          <ButtonConnexion
            // onClick={(e) => {
            //   e.preventDefault();
            //   navigate("/login");
            // }}
            onClick={handleModalLogin}
          >
            Connexion
          </ButtonConnexion>
        </NavbarButtonWrapper>
      </ContainerWrapper>
      <Login
        openModalLogin={openModalLogin}
        loginToProducts={loginToProducts}
        setOpenModalLogin={setOpenModalLogin}
        handleModalLogin={handleModalLogin}
        switchBetweenModals={switchBetweenModals}
      />
      <Register
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalRegister}
        switchBetweenModals={switchBetweenModals}
      />
    </Navbar>
  );
}
