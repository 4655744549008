import styled from 'styled-components';

export const FooterContent = styled.div`
	background-color: #152440;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	height: 313px;
	width: 100%;
`;

export const TopFooterContainer = styled.div`
	grid-area: 1 / 1 / 4 / 2;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #000;
`;

export const BottomFooterContainer = styled.div`
	grid-area: 4 / 1 / 5 / 2;
	background-color: #332f65;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
`;

export const TopFooterContent = styled.div`
	width: 90%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;

export const FooterItem = styled.div`
	width: 22.5%;
`;

export const FooterLink = styled.a`
	display: flex;
	// justify-content: space-between;
	align-items: center;
	color: #fff;
	font-size: 14px;
	text-decoration: none;
`;
