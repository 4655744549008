import { ReactComponent as GitHubIcon } from "../../../assets/IconITgalaxy/githubIcon.svg";
import { ReactComponent as GitLabIcon } from "../../../assets/IconITgalaxy/gitlabIcon.svg";
import { ReactComponent as JiraIcon } from "../../../assets/IconITgalaxy/jiraIcon.svg";
import { ReactComponent as SplineIcon } from "../../../assets/IconITgalaxy/splineIcon.svg";
import { ReactComponent as WebflowIcon } from "../../../assets/IconITgalaxy/webflowIcon.svg";
import { ReactComponent as FigmaIcon } from "../../../assets/IconITgalaxy/figmaIcon.svg";

export const getToolIcon = (toolsId) => {
  switch (toolsId) {
    case 1:
      return <GitHubIcon />;
    case 2:
      return <GitLabIcon />;
    case 3:
      return <JiraIcon />;
    case 4:
      return <SplineIcon />;
    case 5:
      return <WebflowIcon />;
    case 6:
      return <FigmaIcon />;
    default:
      return null;
  }
};
