export const priceAccountingJobsData = [
      { "id": 1, "title": "China" ,"price" : 10 },
      { "id": 2, "title": "India" ,"price" : 10},
      { "id": 3, "title": "United States","price" : 10 },
      { "id": 4, "title": "Indonesia" ,"price" : 10},
      { "id": 5, "title": "Pakistan","price" : 10 },
      { "id": 6, "title": "Brazil" ,"price" : 10},
      { "id": 7, "title": "Nigeria","price" : 10 },
      { "id": 8, "title": "Bangladesh" ,"price" : 10},
      { "id": 9, "title": "Russia","price" : 10 },
      { "id": 10, "title": "Mexico" ,"price" : 10},
      { "id": 11, "title": "Japan" ,"price" : 10},
      { "id": 12, "title": "Ethiopia" ,"price" : 10},
      { "id": 13, "title": "Philippines" ,"price" : 10},
      { "id": 14, "title": "Egypt","price" : 10 },
      { "id": 15, "title": "Vietnam" ,"price" : 10 },
      { "id": 16, "title": "DR Congo" ,"price" : 10 },
      { "id": 17, "title": "Turkey" ,"price" : 10},
      { "id": 18, "title": "Iran","price" : 10 },
      { "id": 19, "title": "Germany","price" : 10 },
      { "id": 20, "title": "Thailand","price" : 10 },
      { "id": 21, "title": "United Kingdom" ,"price" : 10},
      { "id": 22, "title": "France","price" : 10 },
      { "id": 23, "title": "Italy","price" : 10 },
      { "id": 24, "title": "Tanzania" ,"price" : 10},
      { "id": 25, "title": "South Africa" ,"price" : 10},
      { "id": 26, "title": "Myanmar" ,"price" : 10},
      { "id": 27, "title": "Kenya" ,"price" : 10},
      { "id": 28, "title": "South Korea","price" : 10 },
      { "id": 29, "title": "Colombia","price" : 10 },
      { "id": 30, "title": "Spain" ,"price" : 10},
      { "id": 31, "title": "Uganda" ,"price" : 10},
      { "id": 32, "title": "Argentina" ,"price" : 10},
      { "id": 33, "title": "Algeria" ,"price" : 10},
      { "id": 34, "title": "Sudan","price" : 10 },
      { "id": 35, "title": "Ukraine","price" : 10 },
      { "id": 36, "title": "Iraq","price" : 10 },
      { "id": 37, "title": "Afghanistan" ,"price" : 10},
      { "id": 38, "title": "Poland","price" : 10 },
      { "id": 39, "title": "Canada" ,"price" : 10},
      { "id": 40, "title": "Morocco","price" : 10 },
      { "id": 41, "title": "Saudi Arabia" ,"price" : 10},
      { "id": 42, "title": "Uzbekistan" ,"price" : 10},
      { "id": 43, "title": "Peru","price" : 10 },
      { "id": 44, "title": "Angola" ,"price" : 10},
      { "id": 45, "title": "Malaysia" ,"price" : 10},
      { "id": 46, "title": "Mozambique" ,"price" : 10},
      { "id": 47, "title": "Ghana","price" : 10 },
      { "id": 48, "title": "Yemen" ,"price" : 10},
      { "id": 49, "title": "Nepal" ,"price" : 10},
      { "id": 50, "title": "Venezuela","price" : 10 }
    ]
  
  