export const VIDEO_TRAININGS_HEADER_ITEMS = [
  {
    icon: "fa fa-share",
    label: "All courses",
  },
  {
    icon: "fa fa-shop",
    label: "My learnings",
  },
];

export const APPLICATIONS_HEADER_ITEMS = [
  {
    icon: "fa fa-share",
    label: "All applications",
  },
  {
    icon: "fa fa-shop",
    label: "My applications",
  },
];
