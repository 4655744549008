import { useJoinusContext } from "components/allContext/joinusContext";
import ModalAdvancedSearchCandidates from "components/ModalITgalaxy/ModalAdvancedSearchCandidates/ModalAdvancedSearchCandidates";
import ModalComponent from "components/ModalITgalaxy/ModalComponent";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import removeIcon from "../../../assets/IconITgalaxy/removeRed.png";
import etoile from "../../../assets/IconITgalaxy/Star.png";
import Pagination from "../../../components/Paginations/Pagination";
import { useGetCandidatsQuery } from "../../../redux/api/candidat/candidatApi";
import {
  DeleteProposalToProposalsEntrepriseCreation
} from "../../../redux/slice/propsoalEntrepriseCreationSlice/proposalEntrepriseCreationSlice";
import ValidationEntrepriseProposal from "../../ValidationEntrepriseProposal/ValidationEntrepriseProposal";
import EditProfil from "../EditProfil";
import Card from "./card";
import {
  CardContainers,
  ContainerProfileSelected,
  DeleteProfileSelected,
  ProfilAvatarContainer,
  ProfilContainerButton,
  ProfilContainerContainer,
  ProfilContainerOfContainers, 
  ProfilSelectContainer,
  ProfilSelectionnerText,
  SearchBar,
  SearchButtonContainer,
  SearchContainer,
  SearchIconInput,
  SearchInput,
  SearchInputContainer,
  SecondContainer,
  StyledButtonProfilContainerButton,
  StyledCandidatesCardsContainer,
  StyledIConRemove,
  BackButton,
  AdvancedSearchButtonContainer,
  SubmitButtonContainer,
  AddProposalContainer
} from "./styled";
import ModalProposalEntreprise from "../../ModalITgalaxy/ModalProposalEntreprise/ModalProposalEntreprise";
import ImageProfilCard from "components/ImageProfilCard/ImageProfilCard";

export default function Profilfreelances(props) {

  const proposalsData = useSelector(
    (state) => state.proposalEntrepriseCreation.proposals
  );
  const { setRedirect } = useJoinusContext();

  useEffect(() => {
    setRedirect();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [proposals, setProposals] = useState(proposalsData);
  const maxProfiles = 5;
  const remainingSlots = maxProfiles - proposals.length;
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal,setShowModal]  = useState(false);
  const [showModalValidation,setShowModalValidation]  = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSearch, setFilterSearch] = useState("");
  const [showModalAdvancedSearch, setShowModalAdvancedSearch] = useState(false);
  const [recordsPerPage] = useState(9);
  const [showModalProposal , setShowModalProposal] = useState(false);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [daily_rate_min, setDailyRateMin] = useState(0);
  const [daily_rate_max, setDailyRateMax] = useState(1000);

  
  const [formData, setFormData] = useState({
    jobs: [],
    locations: [],
    languages: [],
    skills: [],
    rising_star_global: "",
    daily_rate_max: 0,
    daily_rate_min: 1000,
   });
  

  const {
    data: candidatsData,
    error: candidatDataError,
    isLoading: condidatDataLoading,
  } = useGetCandidatsQuery(
    `?page=${currentPage}&limit=${recordsPerPage}${filterSearch}${searchQuery}`
  );

  const handelbacktoProjectList = () => {
    props.setCandidatesProfils(false);

  }

  useEffect(() => {
    setProposals(proposalsData);
  }, [proposalsData]);

  const deleteItem = (index, proposals) => {
    dispatch(DeleteProposalToProposalsEntrepriseCreation({ index, proposals }));
  };

  const handelChangeSearch = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const queryParams = e.target.value ? `&search=${e.target.value}` : '';
        setSearchQuery(queryParams);
      }
    },
    []
  );

  const validationSelection = async () => {
    setShowModalValidation(true);
  };

  const closeValidationSelection = async () => {
    setShowModalValidation(false);
  };

  const handleConnecter = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleShowModalAdvancedSearch = () => {
    setShowModalAdvancedSearch(!showModalAdvancedSearch);
  };

  const handelModalProposal =() => {
    setShowModalProposal(true) ;
    setShowModal(false);
  }

  const closeModalProposal =() => {
    setShowModalProposal(false) ;
  }

  const handleSearchSubmit = () => {
    let queryParams = "";
        
    if (formData.jobs.length > 0) {
      queryParams += `&job=${formData.jobs}`;
    }

    if (formData.skills.length > 0) {
      queryParams += `&skills=${formData.skills}`;
    }

    if (formData.locations.length > 0) {
      queryParams += `&locations=${formData.locations}`;
    }

    if (formData.languages.length > 0) {
      queryParams += `&languages=${formData.languages}`;
    }

    if (formData.daily_rate_max) {
      queryParams += `&daily_rate_max=${formData.daily_rate_max}`;
    }

    if (formData.daily_rate_min) {
      queryParams += `&daily_rate_min=${formData.daily_rate_min}`;
    }

    if (formData.rising_star_global) {
      queryParams += `&rising_star_global=${formData.rising_star_global}`;
    }

    if (formData.keywords) {
      queryParams += `&keywords=${formData.keywords}`;

    }
    setFilterSearch(queryParams);
    setShowModalAdvancedSearch(false);
  };

  const Renderfooter = (
    <AddProposalContainer onClick={handelModalProposal}> Create a proposal</AddProposalContainer>
  )

  return (
    <>
         <BackButton onClick={handelbacktoProjectList}> <i class="fa fa-arrow-left" aria-hidden="true"></i>  </BackButton>
          <SecondContainer>
            <SearchContainer>
              <SearchBar>
              <SearchInputContainer onKeyDown={handelChangeSearch}>
                  <SearchIconInput className="fa fa-search"></SearchIconInput>
                  <SearchInput
                    type="search"
                    placeholder="Search here..."
                  />
                </SearchInputContainer>
                <SearchButtonContainer>
                  <AdvancedSearchButtonContainer onClick={handleShowModalAdvancedSearch}>Advanced Search</AdvancedSearchButtonContainer>
                </SearchButtonContainer>
                {showModalAdvancedSearch && (
                  <ModalAdvancedSearchCandidates
                    confirmShow={showModalAdvancedSearch}
                    closeModal={handleShowModalAdvancedSearch}
                    setFormData={setFormData}
                    daily_rate_max={daily_rate_max}
                    daily_rate_min={daily_rate_min}
                    setDailyRateMin={setDailyRateMin}
                    setDailyRateMax={setDailyRateMax}
                    formData={formData}
                    handleSearchSubmit={handleSearchSubmit}
                  />
                )}
              </SearchBar>
            </SearchContainer>

            <ProfilSelectContainer>
              <ProfilContainerContainer>
                <ProfilSelectionnerText>
                  <p>
                    <b> Team Members selected </b>
                  </p>
                </ProfilSelectionnerText>
                <ProfilContainerOfContainers>
                <ProfilAvatarContainer>
                {Array.from({ length: 5 }).map((_, index) => (
                  <ContainerProfileSelected key={index}>
                    {proposals[index] && proposals[index] !== null ? (
                      <> 
                      <ImageProfilCard
                        type={"candidats"}
                        id={proposals[index].userId}
                        typeimg={"composeteam"}
                      />
                      <DeleteProfileSelected>
                      <StyledIConRemove
                        src={removeIcon}
                        onClick={() => deleteItem(index)}
                      />
                    </DeleteProfileSelected>
                    </>
                    ) : (
                    <ImageProfilCard
                      type={"candidats"}
                      typeimg={"composeteam"}
                      />
                    )}
                  </ContainerProfileSelected>
                ))}
              </ProfilAvatarContainer>
                </ProfilContainerOfContainers>
              </ProfilContainerContainer>

              <ProfilContainerButton>
                <StyledButtonProfilContainerButton className="btn">
                  <SubmitButtonContainer
                    className="btn btn-primary"
                    onClick={validationSelection}
                    disabled={proposals.length === 0}
                  >
                    Submit your team
                  </SubmitButtonContainer>
                </StyledButtonProfilContainerButton>
              </ProfilContainerButton>
            </ProfilSelectContainer>

            {condidatDataLoading ? (
              <CardContainers>
                <Spinner />
              </CardContainers>
            ) : (
              <StyledCandidatesCardsContainer>
                {candidatsData && candidatsData.data.length == 0 ? (
                  <CardContainers>
                    <div>no data found</div>
                  </CardContainers>
                ) : (
                  <>
                    <CardContainers >
                      {candidatsData.data.map((item, index) => {
                        return (
                          <Card
                            key={index}
                            item={item}
                            handleConnecter={handleConnecter}
                            etoile={etoile}
                          />
                        );
                      })}
                    </CardContainers>
                    <div className="col-lg-12 col-md-12 col-12 mt-4">
                      {candidatsData?.pagination?.totalPages > 1 && (
                        <Pagination
                          nPages={candidatsData?.pagination?.totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </div>
                  </>
                )}
              </StyledCandidatesCardsContainer>
            )}
          </SecondContainer>

       {!showModalProposal && showModal &&(
        <ModalComponent
          show={showModal}
          closeModal={setShowModal}
          body={(<EditProfil userData={selectedItem} editProfil={false} role={"CANDIDAT"} />)}
          bodyPadding={"0"}
          minWidth={"80vw"}
          Height={"80vh"}
          footerpaddingtop={"0"}
          footerpaddingbottom={"0"}
          footer={Renderfooter}
        />)}

          {proposals.length > 0 && proposals[0] != null && showModalValidation && (
            <ValidationEntrepriseProposal showModalValidation={showModalValidation} closeValidationSelection={closeValidationSelection} />
          )}
  
     {showModalProposal  && !showModal && (
      <ModalProposalEntreprise
        userId={selectedItem.id} 
        show={showModalProposal}
        setShowModalProposal={setShowModalProposal}
        setCandidatesProfils={props.setCandidatesProfils}
        closeModal={closeModalProposal}
      />

     )}
    </>
  );
}
