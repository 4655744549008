import React from 'react';
import 'font-awesome/css/font-awesome.min.css'; // Ensure Font Awesome is imported
import { Star } from "./styled"; // Ensure Star is defined in your styled components

const RenderStars = ({ stars }) => {
    // Create filled stars
    const filledStars = Array.from({ length: stars }, (_, index) => (
        <Star key={index} className="fa fa-star" style={{ color: 'gold', margin: '0 2px' }} /> // Using filled star icon
    ));
    
    // Create empty stars
    const emptyStars = Array.from({ length: 5 - stars }, (_, index) => (
        <Star key={index + stars} className="fa fa-star-o" style={{ color: '#ccc', margin: '0 2px' }} /> // Using empty star icon
    ));

    return (
        <div style={{ display: 'flex' }}>
            {filledStars}
            {emptyStars}
        </div>
    );
};

export default RenderStars;
