import React, { useState } from 'react';
import {
    Title,
    Container,
    DetailRow,
    Label,
    Value,
    ButtonShowPayment
} from './paymentDetails.style';
import Payment from '../Payment/Payment';
import PaymentProposal from '../Payment/PaymentProposal';

const PaymentDetails = (props) => {
    const [showModalPayment, setShowModalPayment] = useState(false);

    // Corrected function name
    const handleModalPayment = () => {
        setShowModalPayment(true);
    };

    // Calculate Tax (20% of price)
    const taxRate = 0.20; // 20%
    const taxe = props.price * taxRate; // Tax calculated directly as 20% of price
    const totalCost = props.price + taxe; // Total cost includes price + Tax

    return (
        <>
            {!showModalPayment ? (
                <>
                    <Container>
                        <Title>{props.title}</Title>
                        <DetailRow>
                            <Label>Price:</Label>
                            <Value>{props.price.toFixed(2)} €</Value>
                        </DetailRow>
                        <DetailRow>
                            <Label>Value Added Tax (20%):</Label>
                            <Value>{taxe.toFixed(2)} €</Value>
                        </DetailRow>
                        <hr style={{ margin: "10px 0", border: "1px solid #ccc" }} />
                        <DetailRow>
                            <Label><strong>Total Cost:</strong></Label>
                            <Value><strong>{totalCost.toFixed(2)} €</strong></Value>
                        </DetailRow>     
                    </Container>
                    <ButtonShowPayment onClick={handleModalPayment}>
                        Payment
                    </ButtonShowPayment>
                </>
            ) : (
                props.paymentProposal ? (
                    <PaymentProposal 
                        totalCost={totalCost.toFixed(2)}
                        onSubmit={props.onSubmit} 
                        formMethods={props.formMethods}
                        proposal={props.proposal} 
                    />
                ) : (
                    <Payment 
                        totalCost={totalCost.toFixed(2)}
                        title={props.title}
                        note={props.note}
                        onSubmit={props.onSubmit} 
                    />
                )
            )}
        </>
    );
}

export default PaymentDetails;