import { InputTypes } from "../../../Inputs/input.type";

export const formConfig = {
  file: {
    inputType: InputTypes.FILE,
    fieldName: "excercice_file",
    config: {
      required: true,
      placeholder:
        "Créez un site wordpress réactif avec une fonctionnalité de réservation/paiement",
    },
  },
  link: {
    inputType: InputTypes.TEXT,
    fieldName: "github_link",
    config: {
      required: true,
      placeholder:
        "Créez un site wordpress réactif avec une fonctionnalité de réservation/paiement",
    },
  },

 
};
