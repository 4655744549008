import React from 'react';
import { useGetSharedProjectQuery} from "../../redux/api/projects/projectApi";
import { useGetSharedAppelOffreQuery } from  "../../redux/api/appeloffres/appeloffreApi";
import ModalApply from 'components/ModalITgalaxy/ModalApply/ModalApply';
import { useForm } from "react-hook-form";


export default function ShareJob() { // Capitalized component name

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const type = queryParams.get('type');
    const formMethods = useForm({});


    // Retrieve the shared project or contract based on the token and type
    const { data: project, isLoading: isLoadingProject, isError: isErrorProject } = useGetSharedProjectQuery(token, {
        skip: type == 'Contrat', // Corrected operator
    });
    
    const { data: contrat, isLoading: isLoadingContract, isError: isErrorContract } = useGetSharedAppelOffreQuery(token, {
        skip: type == 'SHARETASK', // Corrected operator
    });

    // Handling loading and error states
    if (isLoadingProject || isLoadingContract) {
        return <div>Loading...</div>; // Display a loading state
    }

    if (isErrorProject || isErrorContract) {
        return <div>Error occurred while fetching data</div>; // Display an error state
    }

    // If neither data is available, you can return null or some message
    if (!project && !contrat) {
        return <div>No job data available</div>;
    }

    // Determine the job to be passed to ModalApply
    const job = project || contrat; // Assuming you want to use either project or contrat

    return (
     <ModalApply 
        job={job}
        showJob={true}
        methods={formMethods}

      />   
    );
}
