import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./ModalAdvancedSearchCandidates.constants";
import StyledRangeSlider from "../../Inputs/RangeSlider/RangeSlider";
import {
    AdvancedSearchButtonModalContainer,
    SearchContainer,
    SearchItem,
    SearchTitleStyle,
    StayledLabel,
    SearchItemDailyRate
} from "./ModalAdvancedSearchCandidates.style";



const ModalAdvancedSearchCandidates = (props) => {
    const formMethods = useForm({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleChangeDailyRate = (e, type) => {
        const value = Number(e.target.value);

        if (type === "min") {
          // Ensure the new minimum does not exceed the current maximum
          if (value < props.daily_rate_max) {
            props.setDailyRateMin(value);
            props.setFormData((prev) => ({ ...prev, daily_rate_min: value }));
          }
        } else if (type === "max") {
          // Ensure the new maximum does not fall below the current minimum
          if (value > props.daily_rate_min) {
            props.setDailyRateMax(value);
            props.setFormData((prev) => ({ ...prev, daily_rate_max: value }));
          }
        }
      };

    const handleChangeRisingStars = (selectedOptions) => {
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            rising_star_global: selectedOptions.value || [],
        }));
    };

    const handleChangeSkills = (selectedOptions) => {
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            skills: selectedOptions.map(option => option.value) || [],
        }));
    };

    const handleChangeLocations = (selectedOptions) => {
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            locations: selectedOptions.map(option => option.value) || [],
        }));
    };

    const handleChangeLanguages = (selectedOptions) => {
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            languages: selectedOptions.map(option => option.value) || [],
        }));
    };

    const handleChangeJob = (e) => {
        props.setFormData((prevFormData) => ({
            ...prevFormData,
            jobs: e.map(option => option.value),
        }));
    };

    const RenderBodyModalAdvancedSearch = (
        <FormProvider {...formMethods}>
            <SearchContainer>
                <SearchItemDailyRate>
                    <StayledLabel>Daily rate (EURO)</StayledLabel>
                    <StyledRangeSlider
                        min={0}
                        max={1000}
                        daily_rate_min={props.daily_rate_min}
                        setDailyRateMax={props.setDailyRateMax}
                        daily_rate_max={props.daily_rate_max}
                        setDailyRateMin={props.setDailyRateMin}
                        handleChange={handleChangeDailyRate}
                        defaultValue={[props.minValue, props.maxValue]}
                    />
                
                </SearchItemDailyRate>
                <SearchItem>
                    <SearchTitleStyle>Job</SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.jobs,
                            label: "jobs",
                        }}
                        onChange={(e) => handleChangeJob(e)}
                        disabledForm={false}
                    />
                </SearchItem>
                <SearchItem>
                    <SearchTitleStyle>Skills</SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.skills,
                            label: "skills",
                        }}
                        onChange={(e) => handleChangeSkills(e)}
                        disabledForm={false}
                    />
                </SearchItem>
                <SearchItemDailyRate>
                <SearchTitleStyle>Rising stars global</SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.rising_star_global,
                            label: "rising_star_global",
                        }}
                        onChange={(e) => handleChangeRisingStars(e)}
                        disabledForm={false}
                    />
                </SearchItemDailyRate>
                
                <SearchItem>
                    <SearchTitleStyle>Locations</SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.locations,
                            label: "locations",
                        }}
                        onChange={(e) => handleChangeLocations(e)}
                        disabledForm={false}
                    />
                </SearchItem>
                <SearchItem>
                    <SearchTitleStyle>Languages</SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.languages,
                            label: "languages",
                        }}
                        onChange={(e) => handleChangeLanguages(e)}
                        disabledForm={false}
                    />
                </SearchItem>
            
                <SearchItem>
                    <SearchTitleStyle>
                        Tap some keywords that describe your search (use ';' to separate
                        many keywords):
                    </SearchTitleStyle>
                    <GenericInput
                        inputObject={{
                            ...formConfig.keywords,
                            label: "keywords",
                        }}
                        onChange={(e) => handleChange(e)}
                        disabledForm={false}
                    />
                </SearchItem>
            </SearchContainer>
        </FormProvider>
    );

    const RenderFooter = (
        <>
            <AdvancedSearchButtonModalContainer onClick={true}>
                Submit
            </AdvancedSearchButtonModalContainer>
        </>
    );

    return (
        <ModalComponent
            show={props.confirmShow}
            closeModal={props.closeModal}
            body={RenderBodyModalAdvancedSearch}
            footer={RenderFooter}
            bodyPadding={"15px 10px 0px 10px"}
            minWidth={"56vw"}
            Height={"80vh"}
        />
    );
};

export default ModalAdvancedSearchCandidates;
