import styled from "styled-components";
import { fontFamily } from "../assets/TypoITgalaxy/FontITgalaxy";

export const ContainerButtonHome = styled.div`
  display: flex;
  width: 450px;
`;
export const CustomButton = styled.button`
  ${fontFamily}
  margin: 0 auto;
`;
