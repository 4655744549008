import { StackStyle } from "../../Formations.style";
import { NumberStyle, TextStyle } from "./CustomStars.style";
import { generateArray } from "../../../../../utils/helpers/array.helpers";
import { ReactComponent as StarsIcon } from "../../../../../assets/IconITgalaxy/StarRating.svg";

function CustomStars({ stars, starsTotal }) {
  return (
    <StackStyle direction={"row"} spacing={1.25}>
      <StackStyle direction={"row"} spacing={0.625}>
        <NumberStyle>{stars}</NumberStyle>
        {generateArray(5).map((value) => (
          <StarsIcon
            key={value}
            style={{ fill: stars > value ? "#FF9B26" : "#969696" }}
          />
        ))}
      </StackStyle>
      <TextStyle>({starsTotal})</TextStyle>
    </StackStyle>
  );
}
export default CustomStars;
