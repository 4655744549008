import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as RightUpArrow } from "../../../../assets/IconITgalaxy/arrow-up-right.svg";
import { getURL } from "../../../../redux/api/uploads/uploadSlice";
import { StackStyle } from "../Formations.style";
import CustomStars from "./CustomStars/CustomStars";
import { minutesToHours } from "./FormationCard.helpers";
import {
  HeadingStyle,
  ImageBoxStyle,
  RootStyle,
  SubHeadingStyle,
  SupportingTextStyle
} from "./FormationsCard.style";
import UserAvatar from "./UserAvatar/UserAvatar";

function FormationsCard({ formation, animateDirection, category, onClick }) {
  const {
    id,
    title,
    description,
    rising_star_global,
    stars,
    price,
    userId,
    skills,
    image,
    user
  } = formation;
  const duration = minutesToHours(formation.duration);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    getUrl();
  }, []);

  const getUrl = async () => {
    const url = await dispatch(
      getURL({
        location: `applications/${id}/image/`,
      })
    );
    url.Contents &&
      setFileName(
        url.Contents[0].Key.split("/")[
        url?.Contents[0].Key.split("/").length - 1
        ]
      );
  };

  return (
    <RootStyle animateDirection={animateDirection} onClick={() => onClick()}>
      <ImageBoxStyle
        src={image ?? `https://itgalaxy-staging.s3.eu-west-1.amazonaws.com/candidats/${userId}/videos_training/${id}/image/${fileName}`}
      >
      </ImageBoxStyle>
      <StackStyle spacing={2.3}>
        <StackStyle spacing={1.5}>
          <SubHeadingStyle>{category || skills[0]}</SubHeadingStyle>
          <StackStyle
            direction={"row"}
            style={{ justifyContent: "space-between" }}
          >
            <HeadingStyle>{title}</HeadingStyle>
            <RightUpArrow />
          </StackStyle>
          <SupportingTextStyle>{`${description}`}</SupportingTextStyle>
        </StackStyle>
        <CustomStars stars={stars} starsTotal={rising_star_global} />
        <UserAvatar userId={userId} price={price} user={user} />
      </StackStyle>
    </RootStyle>
  );
}

export default FormationsCard;
