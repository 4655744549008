import { Button } from "react-bootstrap";
import styled from "styled-components";

export const InlineBlockContainer = styled.div`
  display: inline-block;
`;
export const InlineFlexContainer = styled.div`
  display: inline-flex;
  margin-top: 20px;
`;
export const CenteredInlineFlexMB20PxContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
`;
export const InlineFlexMarginTopContainer = styled.div`
  display: inline-flex;
  margin-top: 20px;
`;
export const Vh90WidthContainer = styled.div`
  width: 70vh;
  margin-left: 20px;
`;
export const ML20PxContainer = styled.div`
  margin-left: 20px;
`;
export const ML20PxInlineFlexContainer = styled.div`
  margin-left: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
export const ML20PxBoldContainer = styled.b`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RightButtonContainer = styled(Button)`
  float: right;
  background-color: black;
  height: 6vh;
  margin-top: 2%;
  
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-family: Inter; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Textbutton = styled(Button)`
            background-color: transparent;
`;

export const Answer = styled.b` 
  color : #828F9B ;
 marginLeft: 20px ;
`;
