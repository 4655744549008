import AddNumberVideo from "components/DashboardTrainerComponnents/TrainerComponnents/AddNumberVideo/AddNumberVideo";
import "./AddPriceForm.css";

export default function AddPriceForm({ onValidate, onCancel }) {
  const data = {
    type: "composeyourteam",
    modal: false,
    titreForm: "Veuillez entrez le prix (en USD)",
    classnameFirst: "pagechanel",
    classname: "container-fluid  d-flex justify-content-end bloc",
    id: "publicchanel",
  };
  return (
    <AddNumberVideo
      inputForm={data}
      onValidate={onValidate}
      onCancel={onCancel}
    />
  );
}
