import React from "react";
import { Button } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ChapterContent from "../ChapterContent/ChapterContent";
import { ContentFormContainer } from "./Content.style";

const StyledButton = styled(Button)`
  width: 98%;
  margin-left: 15px;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

export default function Content({
  formMethods,
  onValidate,
  onCancel,
  currentSubModule,
  isLastChapter,
  onConfirmChapter,
  onNextStep = () => { }
}) {
  const dispatch = useDispatch();

  const handleConfirmChapter = React.useCallback(() => {
    onConfirmChapter();
  }, [onConfirmChapter]);

  const handleNextStep = () => {
    onValidate();
  };

  return (
    <FormProvider>
      <ContentFormContainer>
        <ChapterContent
          formMethods={formMethods}
          onValidate={onValidate}
          onCancel={onCancel}
          currentSubModule={currentSubModule}
          onConfirmChapter={handleConfirmChapter}
          isLastChapter={isLastChapter}
          onNextStep={handleNextStep}
        />
      </ContentFormContainer>
    </FormProvider>
  );
}
