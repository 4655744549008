import styled, { keyframes } from "styled-components";

const moveInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const moveInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const moveOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const moveOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 553px;
  padding: 24px 24px 32px 24px;
  gap: 32px;
  border-radius: 8px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  animation: ${({ animateDirection }) =>
      animateDirection === "inRight"
        ? moveInRight
        : animateDirection === "inLeft"
        ? moveInLeft
        : animateDirection === "outRight"
        ? moveOutRight
        : moveOutLeft}
    0.5s forwards;
`;

export const ImageBoxStyle = styled.div`
  width: 336px;
  height: 240px;
  padding: 11px 11px 200px 193px;
  gap: 0px;
  border-radius: 4px;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
`;

export const SubHeadingStyle = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #2684ff;
  margin-bottom: 0px;
`;

export const HeadingStyle = styled.p`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-bottom: 0px;
`;

export const SupportingTextStyle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #667085;
  margin-bottom: 0px;
`;

export const BoxImageTime = styled.div`
  display: flex;
  flex-direction: row;
  width: 126px;
  height: 29px;
  top: 11px;
  left: 203px;
  padding: 6px 10px 6px 10px;
  gap: 6px;
  border-radius: 4px;
  background-color: #ffffff;
`;

export const TypographyTime = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #667085;
`;
