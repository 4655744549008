import React, { useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import ModalComponent from '../ModalComponent';
import PaymentDetails from "../../PaymentForm/paymentDetails/paymentDetails";
import { StyledTitle } from "./ModalPayment.style";

const ModalPayment = (props) => {

   
    const renderModalPaymentHeader = (
        <StyledTitle>Details Payment</StyledTitle>
    );

    const renderModalPaymentBody = (
        <PaymentDetails
            title={props.title} 
            note={props.note} 
            price={props.price}
            onSubmit={props.onSubmit}
            paymentProposal={props.paymentProposal}
            formMethods={props.formMethods}
            proposal={props.proposal} 
        />
    );

    return (
        <ModalComponent
            show={props.showModalPayment}
            closeModal={props.handleCloseShowModal}
            body={renderModalPaymentBody}
            header={renderModalPaymentHeader}
            bodyPadding={"0px 0px 0px 0px"}
            Width={"50vw"}
            Height={"auto"} // Toggle full height based on modal state
            footerpaddingtop={"0"}
            footerpaddingbottom={"0px"}
        />
    );
};

export default ModalPayment;