import { CDBSidebarMenuItem } from "cdbreact";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ShowModalVideo from "components/ModalITgalaxy/ShowModalVideo/ShowModalVideo";
import {
  getFileFromS3
} from "../../../../redux/api/uploads/uploadSlice";
import {
  addVideoTraining,
  clearVideosTraining,
} from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import {
  extractFileAttributes,
  fileToBase64,
  s3UrlToFile,
} from "../../../../utils/fileConvertion";
import { getUrlData } from "../../../../utils/getUrlData";
import {
  ContentFormContainer,
  FileInfo,
  PlusIcon,
  StyledDropzone,
  StyledGrid,
  VideoPreview,
  VideoWrapper
} from "./VideoUpload.style";

const VideoUpload = ({ formMethods, onValidate, onCancel }) => {
  const [videos, setVideos] = useState();
  const { id } = useParams();
  let trainingCreation = useSelector((state) => state.trainingCreation.videos);
  const trainingTypeProduct = useSelector(
    (state) => state.trainingCreation.type_product
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getUrlData(trainingTypeProduct, setVideos, id, "video", dispatch, true);
    dispatch(clearVideosTraining());
  }, []);

  const getVideosFile = async (video) => {
    return await extractFileAttributes(
      await getFileFromS3(video.videoUrl),
      video.videoUrl
    );
  };
  const dispatchVideos = () => {
    videos?.map(async (video, index) => {
      dispatch(
        addVideoTraining({
          videoFile: await getVideosFile(video),
          videoUrl: `${process.env.REACT_APP_S3_URL}/${video.videoUrl}`,
          base64URL: await fileToBase64(
            await s3UrlToFile(
              `${process.env.REACT_APP_S3_URL}/${video.videoUrl}`
            )
          ),
        })
      );
    });
  };
  useEffect(() => {
    dispatch(clearVideosTraining());
    dispatchVideos();
  }, [videos]);

  const [fileName, setFileName] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [file, setFile] = useState();
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();

  const handelCloseModal = () => {
    setShowModalVideo(false);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "video/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onload = (e) => setVideoURL(e.target.result);
        reader.readAsDataURL(file);
        setFileName(file.name);
      } else {
        alert("Seulement les formats audios sont autorisés!");
      }
    },
  });

  return (
    <div>
      <FormProvider {...formMethods}>
      <small style={{ color: '#6c757d', marginBottom: '10px', display: 'block' }}>
        Max 5 Videos per chapter , max size per video: 100MB..
      </small>
        <ContentFormContainer>
          <StyledGrid>
            {trainingCreation?.map((training, index) =>
              <div>
                <VideoWrapper
                  className="ml-3"
                  onClick={() => {
                    setSelectedVideo(training);
                    setShowModalVideo(true);
                    setIsEdited(true);
                  }}
                >
                  <VideoPreview src={training.videoUrl} />
                  <PlusIcon>
                    <CDBSidebarMenuItem icon="fa fa-edit"></CDBSidebarMenuItem>
                  </PlusIcon>
                </VideoWrapper>
              </div>
            )}
            {trainingCreation.length < 5 && (<div className="col">
              <StyledDropzone
                isActive={isDragActive}
                onClick={() => {
                  setIsEdited(false);
                  setSelectedVideo(null);
                  setShowModalVideo(true);
                }}
              >
                {videoURL ? (
                  <VideoPreview src={videoURL} controls />
                ) : (
                  <CDBSidebarMenuItem icon="fa fa-plus"></CDBSidebarMenuItem>
                )}
              </StyledDropzone>
            </div>)
            }

            {showModalVideo && (
              <ShowModalVideo
                isEdit={isEdited}
                selectedVideo={selectedVideo}
                show={showModalVideo}
                setShow={setShowModalVideo}
                closeModal={handelCloseModal}
              />
            )}
          </StyledGrid>
        </ContentFormContainer>
      </FormProvider>

      {fileName && <FileInfo>Uploaded: {fileName}</FileInfo>}
    </div>
  );
};

export default VideoUpload;
