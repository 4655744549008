import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCreatePaymentMutation, useCapturePaypalPaymentMutation, useRefundPaypalPaymentMutation } from "../../../redux/api/payment/paymentApi";
import { useAddMessageMutation } from '../../../redux/api/api-chat-mongodb/api-chat-mongodbAPI';
import { LuWallet } from "react-icons/lu";
import { getTokenFromLocalStorage } from 'core/helpers/storage';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {
    StyledContainer, Section
} from './Payment.style';
import useHandleSubmitUpdateProposal from 'hooks/useHandleSubmitUpdateProposal';
import { useAddInvoicingMutation } from '../../../redux/api/invoicing/invoicingApi';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PaymentProposal = (props) => {
    const [paymentStatus, setPaymentStatus] = useState("inprogress");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orderID, setOrderID] = useState(null);
    const [urlNavigate, setUrlNavigate] = useState('');

    const [createPayment] = useCreatePaymentMutation();
    const [capturePaypalPayment] = useCapturePaypalPaymentMutation();
    const [createInvoice] = useAddInvoicingMutation();
    const [refundPaypalPayment] = useRefundPaypalPaymentMutation();
  
    const navigate = useNavigate();

    const token = getTokenFromLocalStorage();
    const decodedToken = token ? jwtDecode(token) : null;
    const payerId = decodedToken?.id;
    const payerType = decodedToken?.role;

    const { handleActivateSubmit } = useHandleSubmitUpdateProposal(props.proposal, orderID, props.formMethods);

    const handleError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => setError(null), 2000); // Clear error after 2 seconds
    };

    useEffect(() => {
        if (paymentStatus === 'success' && urlNavigate) {
            const timer = setTimeout(() => {
                window.location.href = `${urlNavigate}`;      
            }, 1000);
            return () => clearTimeout(timer); // Cleanup timeout
        }
    }, [paymentStatus, urlNavigate, navigate]);

    return (
        <StyledContainer>
            {paymentStatus === 'inprogress' && (
                <Section>
                    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID, currency: "EUR" }}>
                        <div style={{
                            border: '1px solid #eaeaea',
                            borderRadius: '8px',
                            padding: '20px',
                            backgroundColor: '#f9f9f9',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center'
                        }}>
                            <h3 style={{ marginBottom: '15px' }}>ACCEPT THE PROPOSAL</h3>
                            <p style={{ marginBottom: '10px', color: '#666' }}>
                                Note that if you encounter a problem during this project, you will receive a refund.
                            </p>

                            <PayPalButtons
                                style={{
                                    layout: "vertical",
                                    color: "gold",
                                    shape: "rect",
                                    label: "paypal",
                                }}
                                createOrder={async () => {
                                    setLoading(true);
                                    try {
                                        const orderData = await createPayment({
                                            amount: props.totalCost,
                                            payerId,
                                            payerType,
                                            paymentType: 'paypal'
                                        }).unwrap();

                                        if (orderData?.id) {
                                            setOrderID(orderData.id);
                                            return orderData.id;
                                        } else {
                                            throw new Error('Failed to create order');
                                        }
                                    } catch (error) {
                                        handleError('Failed to create PayPal order. Please try again.');
                                        setPaymentStatus('error');
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                                onApprove={async (data, actions) => {
                                    setLoading(true);
                                    try {
                                        let invoice ;
                                        let productCreationSuccess;
                                        const orderData = await capturePaypalPayment(data.orderID).unwrap();
                                        const errorDetail = orderData?.details?.[0];
                                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                            return actions.restart();
                                          }

                                        switch (orderData.status) {
                                            case "COMPLETED":
                                               productCreationSuccess = await handleActivateSubmit(orderData.purchase_units[0].payments.captures[0].id);

                                            if(productCreationSuccess.success) {
                                               invoice = await createInvoice({
                                                payerId,
                                                payerType,
                                                price: props.totalCost,
                                                paymentType: 'paypal',
                                                targetProductType: productCreationSuccess.targetProductType,
                                                targetProductId: productCreationSuccess.targetProductId,
                                                orderId: orderData.purchase_units[0].payments.captures[0].id,
                                                invoicingDescription: productCreationSuccess.invoicingDescription
                                              });
                                    
                                              console.log("invoice created", invoice);
                                              setUrlNavigate(productCreationSuccess.url);
                                              setPaymentStatus('success');
                                              break;

                                         } else {
      
                                            await refundPaypalPayment({
                                                params: orderData.purchase_units[0].payments.captures[0].id,
                                                body: { amount: props.totalCost },
                                              }).unwrap();

                                               invoice = await createInvoice({
                                                payerId,
                                                payerType,
                                                price: props.totalCost,
                                                paymentType: 'paypal',
                                                targetProductType: productCreationSuccess.targetProductType,
                                                targetProductId: productCreationSuccess.targetProductId,
                                                orderId: orderData.purchase_units[0].payments.captures[0].id,
                                                invoicingDescription: productCreationSuccess.invoicingDescription,
                                                status: "REFUND" 
                                             });
                                              console.log("invoice created", invoice);
                                          }
                                    
                                            case "PENDING":
                                            case "DENIED":
                                            case "EXPIRED":
                                            case "FAILED":
                                              handleError(`Payment failed with status: ${orderData.status}. Please check with PayPal for more details.`);
                                              setPaymentStatus('error');
                                              break;
                                    
                                            default:
                                              handleError('Unknown payment status. Please try again.');
                                              setPaymentStatus('error');
                                          }   
                                    } catch (error) {
                                        handleError('Payment capture failed. Please try again.');
                                        setPaymentStatus('error');
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                                onError={(err) => handleError(`An error occurred during payment processing: ${err.message}`)}
                            />
                            {loading && <p>Loading, please wait...</p>}
                        </div>
                    </PayPalScriptProvider>
                </Section>
            )}
            {/* Success/Error Messages */}
            {paymentStatus === 'success' && (
                <div style={{ paddingLeft: '150px', fontWeight: '501', color: 'green', marginBottom: '15px', fontFamily: 'Inter', fontSize: '30px' }}> Payment processed successfully!</div>
            )}
            {paymentStatus === 'error' && (
                <div style={{ paddingLeft: '150px', fontWeight: '501', color: 'red', marginBottom: '15px', fontFamily: 'Inter', fontSize: '30px' }}> Payment capture failed. Please try again. </div>
            )}
            {/* Error Message Display */}
            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </StyledContainer>
    );
};

export default PaymentProposal;