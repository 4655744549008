import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { VIDEO_TRAINING_ITEMS } from "../../../../core/constants/trainerForm.constants";
import { getAccessToken } from "../../../../core/helpers/storage";
import { renderContentList } from "../../../../core/helpers/trainerForm";
import useHandleSubmitTrainingCreation from "../../../../hooks/useHandleSubmitTrainingCreation";
import { clearVideosTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import ModalConfirmationTraining from "../../../ModalITgalaxy/ModalConfirmationTraining";
import {
  BigContainer,
  StyledBoldSubtitle,
  StyledBreadcrumb,
  StyledCircle,
  StyledContainer,
  StyledFormContainer,
  StyledGoBackContainer,
  StyledLineOne,
  StyledStep,
  StyledStepContentCard,
  StyledStepContentColumn,
  StyledStepTitle,
  StyledStepTitleCard,
  StyledStepperCard,
  StyledStepsFormContainer,
  StyledSubtitle,
  StyledTitle,
  StyledTitleAndSubtitleContainer
} from "./TraininerForm.style";

const ParcoursForm = ({ type }) => {
  const [confirmShow, setConfirmShow] = useState(false);
  const trainingType = useSelector((state) => state.trainingCreation.type);
  const [items, setItems] = useState(VIDEO_TRAINING_ITEMS);
  const [subModules, setSubModules] = useState(VIDEO_TRAINING_ITEMS[2].subModules || []);
  const { id } = useParams();
  const [selectedElement, setSelectedElement] = useState(1);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const decodeToken = token ? jwtDecode(token) : null;
  const [currentSubModule, setCurrentSubModule] = useState(null);

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const addChapter = () => {
    const newChapter = {
      id: `3.${subModules.length + 1}`,
      title: `Chapter ${subModules.length + 1}`,
    };
    setSubModules([...subModules, newChapter]);
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[2].subModules = [...subModules, newChapter];
      return updatedItems;
    });
    setCurrentSubModule(newChapter);
  };

  useEffect(() => {
    let mounted = true;
    const fetchToken = async () => {
      const accessToken = await getAccessToken();
      if (mounted) {
        setToken(accessToken);
      }
    };
    fetchToken();
    return () => {
      mounted = false;
    };
  }, []);

  const handleModalRegister = () => {
    setOpenModalRegister((prev) => !prev);
  };

  const handleBack = () => {
    if (selectedElement > 1) {
      setSelectedElement((prev) => prev - 1);
    } else {
      navigate("/training");
    }
  };

  let { handleSubmit } = useHandleSubmitTrainingCreation(
    selectedElement,
    setSelectedElement,
    setConfirmShow,
    openModalRegister,
    setOpenModalRegister,
    id ? true : false
  );

  const handleConfirmChapter = () => {
    // Create new chapter
    const newChapter = {
      id: `3.${subModules.length + 1}`,
      title: `Chapter ${subModules.length + 1}`,
    };

    // Update subModules and items
    setSubModules(prevSubModules => [...prevSubModules, newChapter]);
    setItems(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[2].subModules = [...subModules, newChapter];
      return updatedItems;
    });

    // Redirect to new chapter
    setCurrentSubModule(newChapter);
  };

  const handleNextStep = () => {
    const nextStep = selectedElement + 1;
    if (nextStep === 3) {
      // Always ensure we have at least one chapter
      if (subModules.length === 0) {
        const firstChapter = {
          id: '3.1',
          title: 'Chapter 1',
        };
        setSubModules([firstChapter]);
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[2].subModules = [firstChapter];
          return updatedItems;
        });
        setCurrentSubModule(firstChapter); // Set current submodule to first chapter
      } else {
        // If chapters exist, select the first one
        setCurrentSubModule(subModules[0]);
      }
    }
    setSelectedElement(nextStep);
  };

  const handleStepClick = (stepId, subModuleId = null) => {
    if (stepId === 3) {
      // Create first chapter if none exists
      if (subModules.length === 0) {
        const firstChapter = {
          id: '3.1',
          title: 'Chapter 1',
        };

        // Update both subModules and items atomically
        setSubModules([firstChapter]);
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[2].subModules = [firstChapter];
          return updatedItems;
        });
        setCurrentSubModule(firstChapter);
      } else if (subModuleId) {
        // If a specific submodule is clicked
        setCurrentSubModule(subModules.find(module => module.id === subModuleId));
      } else {
        // If just step 3 is clicked, select the first submodule
        setCurrentSubModule(subModules[0]);
      }
      setSelectedElement(3);
    } else {
      setSelectedElement(stepId);
      setCurrentSubModule(null);
    }
  };

  useEffect(() => {
    if (selectedElement === 3 && subModules.length === 0) {
      const firstChapter = {
        id: '3.1',
        title: 'Chapter 1',
      };
      setSubModules([firstChapter]);
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[2].subModules = [firstChapter];
        return updatedItems;
      });
      setCurrentSubModule(firstChapter);
    }
  }, [selectedElement]);

  return (
    <StyledContainer>
      <BigContainer>
        <StyledBreadcrumb>
          <StyledTitle>Become an ItGalaxy IT Producer</StyledTitle>
          <StyledSubtitle>Join a community of ItGalaxy Producer</StyledSubtitle>
        </StyledBreadcrumb>
        <StyledFormContainer>
          <StyledLineOne>
            {selectedElement > 1 &&
              <StyledGoBackContainer onClick={() => setSelectedElement(selectedElement - 1)}>
                <i className="fal fa-arrow-left"></i>
                <StyledBoldSubtitle>Back</StyledBoldSubtitle>
              </StyledGoBackContainer>
            }
          </StyledLineOne>
          <StyledStepsFormContainer>
            <StyledStepContentColumn>
              <StyledStepTitleCard>
                <StyledBoldSubtitle style={{ color: "#A4ADB6" }}>
                  Step {selectedElement}:
                </StyledBoldSubtitle>
                <StyledBoldSubtitle>
                  {items[selectedElement - 1]?.title}
                </StyledBoldSubtitle>
              </StyledStepTitleCard>
              <StyledStepContentCard>
                {renderContentList(
                  type,
                  trainingType,
                  selectedElement,
                  setSelectedElement,
                  setConfirmShow,
                  setItems,
                  formMethods,
                  addChapter,
                  currentSubModule,
                  handleConfirmChapter,
                  subModules.length
                )}
                <ModalConfirmationTraining
                  confirmShow={confirmShow}
                  onCancel={() => {
                    if (selectedElement > 1) setSelectedElement(selectedElement - 1);
                    setConfirmShow(false);
                  }}
                  onSubmit={
                    !token
                      ? () => {
                        setOpenModalRegister(true);
                        setConfirmShow(false);
                      }
                      : () => {
                        handleSubmit();
                        dispatch(clearVideosTraining());
                      }
                  }
                />
              </StyledStepContentCard>
            </StyledStepContentColumn>
            <StyledStepperCard>
              {items.map((item, index) => (
                <div key={item.id}>
                  <StyledStep
                    active={selectedElement === item.id && !currentSubModule}
                    checked={selectedElement > item.id}
                    onClick={() => handleStepClick(item.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <StyledCircle>{item.id}</StyledCircle>
                    <StyledTitleAndSubtitleContainer>
                      <StyledStepTitle>{item.title}</StyledStepTitle>
                    </StyledTitleAndSubtitleContainer>
                  </StyledStep>

                  {item.id === 3 && (
                    <>
                      {subModules.map((subModule) => (
                        <StyledStep
                          key={subModule.id}
                          style={{
                            marginLeft: '20px',
                            cursor: 'pointer',
                            backgroundColor: currentSubModule?.id === subModule.id ? '#e8f0fe' : 'transparent'
                          }}
                          isSubModule
                          active={currentSubModule?.id === subModule.id}
                          onClick={() => handleStepClick(3, subModule.id)}
                        >
                          <StyledCircle>{subModule.id}</StyledCircle>
                          <StyledTitleAndSubtitleContainer>
                            <StyledStepTitle>{subModule.title}</StyledStepTitle>
                          </StyledTitleAndSubtitleContainer>
                        </StyledStep>
                      ))}
                      {selectedElement === 3 && (
                        <Button onClick={addChapter} style={{ marginLeft: '20px', marginTop: '10px' }}>
                          + Add Chapter
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </StyledStepperCard>
          </StyledStepsFormContainer>
        </StyledFormContainer>
      </BigContainer>
    </StyledContainer>
  );
};

export default ParcoursForm;
