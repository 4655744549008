import React, { useState,useEffect  } from 'react';
import GenericInput from 'components/Inputs/GenericInput/GenericInput';
import { Button } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { depositCompanyFormConfig } from '../DepositCompanyForm.config';
import { StyledLabel, SearchFilterRegion,StyledButtondownland } from '../DepositCompanyForm.style';
import { useDispatch } from 'react-redux';
import { getURL } from '../../../redux/api/uploads/uploadSlice';

const CompanyExistBINForm = ({ isEdit=true,onValidate, data ,companyType, onCancel, formMethods, disableStep, refetchCompany }) => {
    const [showError, setShowError] = useState(false);
    const watchCompanyIdentity = formMethods.watch('identityCompanyType');
    const watchCompanyBIN = formMethods.watch('companyBIN');
    const watchCompanyBINProofAttachment = formMethods.watch('companyBINProofAttachment');
    const status = data ? data[0].status : null ; 
    const [previewCompanyBIN, setPreviewCompanyBIN] = useState(null);

    const folder   = companyType === 'Company Exist' ? 'company'  : 'request_company_creation' ;
    const [loading, setLoading] = useState(true); 
    const dispatch = useDispatch();

  
    const getUrlData = async () => {
     setLoading(true); // Set loading state to true
 
      const fetchUrlData = async (location) => {
            try {
                const result = await dispatch(getURL({ location }));
                return result;
            } catch (error) {
                console.error(`Failed to fetch data from ${location}`, error);
                return null;
            }
        };
          try {
               const urlCompanyLocation = await fetchUrlData(`${folder}/${data[0]?.id}/companyBIN/`);
             const companyLocationPath = urlCompanyLocation?.Contents?.[0]?.Key;

            if (companyLocationPath) {
                setPreviewCompanyBIN(`${process.env.REACT_APP_S3_URL}/${companyLocationPath}`);
            } else {
                console.error("Company Location contents not found");
                setPreviewCompanyBIN(null);
            }
            
        } finally {
            setLoading(false);
        }
      };
      useEffect(() => {
        getUrlData();
      }, []);

    const handleValidateClick = () => {
        if (!watchCompanyIdentity || !watchCompanyBIN || !watchCompanyBINProofAttachment) {
            setShowError(true); 
        } else {
            setShowError(false); 
            onValidate(); 
        }
    };

    return (
        <FormProvider {...formMethods}>
            <StyledLabel>1. What's your Company identity type?</StyledLabel>

            <SearchFilterRegion>
                <GenericInput
                    inputObject={{
                        ...depositCompanyFormConfig.identityCompanyType,
                    }}
                    disabledForm={disableStep}
                    label="What's your Company identity type?"
                />
            </SearchFilterRegion>

            <StyledLabel>2. What's your Company BIN?</StyledLabel>
            <GenericInput
                inputObject={{
                    ...depositCompanyFormConfig.companyBIN,
                }}
                disabledForm={disableStep}
                value={watchCompanyBIN}
            />
            
            <GenericInput
                inputObject={{
                    ...depositCompanyFormConfig.companyBINProofAttachment,
                }}
                isEdit={isEdit}
                disabledForm={disableStep}
                previewInput={previewCompanyBIN}

            />
            {status === 'VALIDATED' && ( 
              <StyledButtondownland
                href={previewCompanyBIN}
                download={previewCompanyBIN}
               >
                Download BIN
            </StyledButtondownland>)}

            {/* Error message section */}
            {showError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    <strong>Please fill in all required fields.</strong>
                </div>
            )}

            <div style={{ float: "right", marginTop: "10%" }}>
                <Button variant="light" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    style={{ marginLeft: "15px" }}
                    variant="primary"
                    onClick={handleValidateClick}
                    disabled={!watchCompanyIdentity || !watchCompanyBIN || !watchCompanyBINProofAttachment} // Disable if required inputs are empty
                >
                    Confirm
                </Button>
            </div>
        </FormProvider>
    );
};

export default CompanyExistBINForm;
