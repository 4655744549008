import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import {
  getAccessToken,
  getIsloggedFromLocalStorage
} from "../../../core/helpers/storage.js";
import ListRecruterContainer from "./ListRecruterContainer/ListRecruterContainer.jsx";
import SideBar from "../../../components/SideBar/sideBar.jsx";
import {
  StyledITgalaxyServicesContainer,
  Styleddashboard,
} from "./ChatRecruter.style.js";
import ListServicesContainer from "./ListServicesContainer/ListServicesContainer.jsx";
import ThemeSwitcher from "./ThemeSwitcher.jsx";

const ChatRecruter = () => {
  
  const [theme, setTheme] = useState("light");
  const [usernameChat, setUsernameChat] = useState("");
  const [idRecruter, setIdRecruter] = useState("");
  const [chatId , setChatId] = useState("");
  const [role , setRole] = useState("");
  const [ isLoggedIn, setIsLoggedIn] = useState("");


  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      const recruterToken = token ? jwtDecode(token) : null;
      if (recruterToken) {
        const id           = recruterToken.id.toString();
        const usernamechat = recruterToken.usernamechat.toString();
        const Idchat       = recruterToken.chatid.toString();
        const role         = recruterToken.role.toString();
        const isLoggedIn   = getIsloggedFromLocalStorage();
        setUsernameChat(usernamechat);
        setIdRecruter(id);
        setChatId(Idchat);
        setIsLoggedIn(isLoggedIn);
        setRole(role);
      }
    };
    fetchToken();
  }, []);

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" ,  overflowY: "hidden"  ,  maxHeight: "100vh"}}
    >
      <SideBar isLoggedIn={isLoggedIn} path={'/DashboardRecruter'} role={role}  id={idRecruter} />
      <ThemeSwitcher toggleTheme={toggleTheme} theme={theme} />
      <StyledITgalaxyServicesContainer theme={theme}>
        <Styleddashboard>
          <ListRecruterContainer
            idRecruter={idRecruter}
            usernameChat={usernameChat}
            chatId={chatId}
            theme={theme}
          />
          <ListServicesContainer theme={theme} />
        </Styleddashboard>
      </StyledITgalaxyServicesContainer>
    </div>
  );
};

export default ChatRecruter;
