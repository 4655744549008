import {
  ArrowBlock,
  BorderedFWPad2BR10Container,
  CenteredH5vhContainer,
  InlineBlockContainer,
} from "./ChooseOptionChat.style";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";

export const ChooseOptionChat = (props) => {
  return (
    <BorderedFWPad2BR10Container>
      <b>{props.header1}</b>
      <p>{props.header2}</p>
      <InlineBlockContainer>
        {props.items.map((item) => (
          <CenteredH5vhContainer
            onClick={() => {
              props.setSelected(true);
              props.setSelectedOption(item);
            }}
          >
            {item}
            <ArrowBlock>
              <ArrowIcon />
            </ArrowBlock>
          </CenteredH5vhContainer>
        ))}
        <br />
        <br />
      </InlineBlockContainer>
    </BorderedFWPad2BR10Container>
  );
};
