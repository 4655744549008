export const fontFamily = `
font-family: Poppins;
font-style: normal;
`;

export const fontSizeSlogan = `
font-size: 40px;
font-weight: 600;
font-style: normal;
`;

export const fontSizeTextCom = `
font-size : 25px;
font-style: normal;
`;

export const fontSizeTitleBtn = `
font-size: 16px;
font-style: normal;
`;

export const fontSizeInfo = `
font-size : 12px;
font-style: normal;
`;
