export const ENDPOINTS = {
  // Auth
  LOGIN: "/login",
  SIGNUP_ENTREPRISE: "/entreprise/signup",
  SIGNUP_CANDIDAT: "/user/signup",
  SIGNUP_RECRUITER: "/recruter/signup",
  FORGET_PASSWORD: "/forget-password",
  SIGNUP_ACCOUNTING: "/accounting/signup",
  LOGIN_ACCOUNTING: "accounting/login",
  LOGOUT: "/logout",

  VERIFYEMAIL_USER: "/user/verify-email",
  VERIFYEMAIL_ENTREPRISE: "/entreprise/verify-email",
  VERIFYEMAIL_RECRUITER: "/recruter/verify-email",
  VERIFYEMAIL_ACCOUNTING: "/accounting/verify-email",

  RESET_PASSWORD_RECRUTER: "/recruter/reset-password",
  RESET_PASSWORD_USER: "/user/reset-password",
  RESET_PASSWORD_ACCOUNTING: "/accounting/reset-password",
  RESET_PASSWORD_ENTREPRISE: "/entreprise/reset-password",

  CREATE_PROJECT: "/projects",

  CANDIDATS: "/users/",
  ENTREPRISES: "/entreprises/",
  COMPANY: "/company",
  TRAININGS: "/trainings/",
  PRODUCTS: "/products",
  SOLDED_PRODUCTS: "/soldedproducts",
  PUBLIC_PRODUCTS: "/products/publicfields",
  PUBLIC_USERS: "/users/publicfields",
  CONTACTS: "/contacts",
  APPELOFFRES: "/appeloffres",
  JOBS: "/projects/all",

  CREATE_PROPOSAL_CANDIDAT: "/proposals",
  CREATE_PROPOSAL_ENTREPRISE: "/proposalsentreprise",
  CREATE_PROPOSALAO: "/proposalsappeloffre",

  WITHDRAWAL_METHODS: "/withdrawalmethods",
  WITHDRAWAL: "/withdrawals",

  USERS: "users",
  INVOICINGS:"invoicing/",

  PAYMENT: "/payment/",
  SESSION_TRAINING_PROPOSAL: "/sessiontrainingproposals",

  CREATE_RECRUTEMENT: "/recruitement",
  CREATE_APPELOFFRE: "/appeloffres",
  CREATE_ACCOUNTING: "/accountings",
  CREATE_ACCOUNTINGJOBS: "/accountingjobs",


  CREATE_REQUESTCOMPANYCREATION: "/requestcompanycreation",
  CREATE_RECRUTER: "/recruters",

  CREDENTIAL: "/credentials/validate",
  TOPICS: "/topics",
  MESSAGES: "/messages",
  CHATIDS: "/chatid",
  SUBSCRIPTIONS: "/subscriptions",
};
