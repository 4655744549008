import { useCombobox, useMultipleSelection } from "downshift";
import React, { useEffect } from "react";
import {
  Container,
  CustomAutoCompleteContainer,
  DeleteSelectedElementContainer,
  InputContainer,
  ListContainer,
  ListItem,
  SelectedElementContainer,
  StyledUl,
  TopContainer,
} from "./CustomAutoComplte.style";

import { useNavigate } from "react-router-dom";
import { skillsData as skills } from "../../../data/skillData";
const initialSelectedItems = [];

function getFilteredskills(selectedItems, inputValue) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return skills.filter(function filterBook(book) {
    return (
      !selectedItems?.includes(book) &&
      (book.title.toLowerCase().includes(lowerCasedInputValue) ||
        book.title.toLowerCase().includes(lowerCasedInputValue))
    );
  });
}

const CustomAutoComplete = ({ isMobile, recieveSkills, setIsFocused, active, initialItems, searchType, redirect = false, border = "none", placeholder = "", backgroundColor, width = "80vh", height, radius = "0px", padding = "0.375rem" }) => {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState(
    initialItems
      ? initialItems.map((item) => ({
        id: skills?.filter((skill) => skill?.title == item)[0].id,
        title: item,
      }))
      : []
  );
  const navigate = useNavigate();

  const items = React.useMemo(
    () => getFilteredskills(selectedItems, inputValue),
    [selectedItems, inputValue]
  );
  useEffect(() => {
    recieveSkills(selectedItems);
  }, [selectedItems]);
  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedItems(newSelectedItems);
            break;
          default:
            break;
        }
      },
    });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.title : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (redirect) {
              if (searchType === "Freelancer") navigate(`/ProfileFreelances?search=${newSelectedItem.title}`)
              else
                navigate(`/products?search=${newSelectedItem.title}`);
            }



            setSelectedItems([...selectedItems, newSelectedItem]);
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);

          break;
        default:
          break;
      }
    },
  });

  return (
    <CustomAutoCompleteContainer>
      <Container>
        <ListContainer>
          <TopContainer padding={padding} redirect={redirect} radius={radius}>
            {selectedItems?.map((selectedItemForRender, index) => (
              <SelectedElementContainer
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: selectedItemForRender,
                  index,
                })}
              >
                {selectedItemForRender.title}
                <DeleteSelectedElementContainer
                  style={{ padding: "0.25rem", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItemForRender);
                  }}
                >
                  &#10005;
                </DeleteSelectedElementContainer>
              </SelectedElementContainer>
            ))}
            <InputContainer width={width} height={height} backgroundColor={backgroundColor} radius={radius}
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
              placeholder={placeholder} border={border}
              onFocus={() => { if (isMobile) setIsFocused(true) }}
            // onBlur={() => {if(setIsFocused) setIsFocused(false)}}                
            />
          </TopContainer>
          <StyledUl
            isOpen={isOpen}
            itemsLength={items.length}
            {...getMenuProps()}
          >
            {isOpen &&
              items.map((item, index) => (
                <ListItem
                  key={`${item.value}${index}`}
                  highlighted={highlightedIndex === index}
                  selected={selectedItem === item}
                  {...getItemProps({ item, index })}
                >
                  <span>{item.title}</span>
                </ListItem>
              ))}
          </StyledUl>
        </ListContainer>
      </Container>
    </CustomAutoCompleteContainer>
  );
};

export default CustomAutoComplete;
