export const formations = [
  {
    id: 1,
    userId: 1,
    type: "VIDEOSTRAINING",
    title: "React 2024: Maîtrisez-le",
    description: "React - Le guide complet 2024 (incl. React Router & Redux)",
    languages: "English",
    repo: null,
    status: "OPENED",
    skills: ["Development", "Redux"],
    price: 17.84,
    currency: "USD",
    duration: 482,
    duration_type: "hours",
    rising_star_global: 16.325,
    stars: 4.3,
    createdAt: new Date(),
    updatedAt: null,
    deletedAt: null,
    image: "/images/formations/reactjs.png",
    user: {
      id: 1,
      first_name: "John",
      name: "abady",
      job: "Instructor",
      image: "/images/users/john.png",
    },
  },
  {
    id: 2,
    userId: 1,
    type: "VIDEOSTRAINING",
    title: "Python Pro",
    description: "100 jours de code : le bootcamp complet de Python Pro",
    languages: "English",
    repo: null,
    status: "OPENED",
    skills: ["Development", "Redux"],
    price: 8.99,
    currency: "USD",
    duration: 363,
    duration_type: "hours",
    rising_star_global: 832,
    stars: 3.9,
    createdAt: new Date(),
    updatedAt: null,
    deletedAt: null,
    image: "/images/formations/python.png",
    user: {
      id: 1,
      first_name: "Mariem",
      name: "amrouni",
      job: "Instructor",
      image: "/images/users/Mariem.png",
    },
  },
  {
    id: 3,
    userId: 1,
    type: "VIDEOSTRAINING",
    title: "Créez des apps Web ...",
    description:
      "Créez des applications Web et mobiles élégantes et puissantes à l aide AJAX.",
    languages: "English",
    repo: null,
    status: "OPENED",
    skills: ["Design", "Redux"],
    price: 11.7,
    currency: "USD",
    duration: 62,
    duration_type: "hours",
    rising_star_global: 125,
    stars: 4.2,
    createdAt: new Date(),
    updatedAt: null,
    deletedAt: null,
    image: "/images/formations/python.png",
    user: {
      id: 1,
      first_name: "Nicolas",
      name: "dilemare",
      job: "Instructor",
      image: "/images/users/nicolas.png",
    },
  },
];
