import styled from "styled-components";

export const StyledInput = styled.div`
  width: 100%;
  height: 156px;
  background-color: #f4f5f5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Set position relative to make it the parent for absolute positioning */

  input[type="file"] {
    display: none; /* Hide the default file input */
  }

  &:hover {
    background-color: #d1d1d1;
  }

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
  }
`;

export const StyledUploadButton = styled.button`
  position: absolute; /* Position the button absolutely inside the wrapper */
  bottom: 10px; /* Position it at the bottom */
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0.25rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const FilePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #6c757d;
  font-size: 14px;

  i {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`;


export const linkStyle = {
  color: '#007bff', // Bootstrap primary color
  textDecoration: 'underline',
  marginTop: '5px',
  display: 'inline-block',
};
