import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useUpdateProposalEntrepriseMutation
} from "../../../redux/api/proposals/proposalApi";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import StarRating from "../../StarRatingComonant";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./ActivatePaymentModal.constants";
import {
  StyledContainer,
  StyledDiv,
  StyledFormBody,
  StyledTypography,
  ActiveVersementButton
} from "./ActivatePaymentModal.style";
import useHandleSubmitUpdateProposal from "../../../hooks/useHandleSubmitUpdateProposal";

const ActivatePaymentModal = (props) => {
  const [reviewComment, setReviewComment] = useState("");
  const [starsRating, setStarsRating] = useState(0);  
  const formMethods = useForm({ mode: "onChange", shouldFocusError: true });
  
  const handleProposalDescriptionChange = (e) => {
    setReviewComment(e.target.value);
  };

  let { handleFinishSubmit, loading } = useHandleSubmitUpdateProposal(
    props.proposal,
    formMethods,
  );

  const handleActivatePaiement = async () => {
 
    try {
      const response = await handleFinishSubmit(reviewComment,starsRating) ;
 
    if(response.success) {
      props.handleShowModal(); 
      window.location.href = `/projects`;

     }

    } catch (error) {
      console.error("Error updating proposal:", error);
      // Optionally show some error message
    }
  };

  const RenderBodyActivatePaiement = (
    <StyledDiv>
      <StyledFormBody>
        <StyledContainer>
          <StyledTypography>Add a comment for this proposal</StyledTypography>
          <FormProvider {...formMethods}>
            <GenericInput
              inputObject={{
                ...formConfig.comment,
                defaultValue: reviewComment,
              }}
              onChange={handleProposalDescriptionChange}
              disabledForm={false}
            />
            <div style={{ margin: "20px 0" }}>
              <h3>Recommendation:</h3>
              <StarRating
                count={5}
                size={15}
                value={starsRating}
                activeColor={"yellow"}
                inactiveColor={"#a0a0a0"}
                onChange={setStarsRating}
              />
            </div>
          </FormProvider>
        </StyledContainer>
      </StyledFormBody>
    </StyledDiv>
  );

  const RenderHeader = <>Activate Versement</>;

  const RenderFooter = (
    <ActiveVersementButton onClick={handleActivatePaiement} >
      {"Activate Versement"}
    </ActiveVersementButton>
  );

  return (
    <ModalComponent
      show={props.showModalActiveVersement}
      closeModal={props.handleShowModal}
      header={RenderHeader}
      body={RenderBodyActivatePaiement}
      footer={RenderFooter}
      bodyPadding={"0px"}
      minWidth={"50vw"}
      footerpaddingtop={"0"}
      footerpaddingbottom={"0"}
    />
  );
};

export default ActivatePaymentModal;
