import ImageProfilCard from "components/ImageProfilCard/ImageProfilCard";
import React, { useEffect, useState } from "react";
import { IoGridOutline } from "react-icons/io5";
import { LuAppWindow, LuBuilding2, LuCode2, LuMessageCircle, LuWallet } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ItGalaxyLogoWhite from "../../../assets/logo/ItGalaxy-LogoWhite.png";
import {
  AppName,
  BottomMenu,
  Dropdown,
  DropdownItem,
  MenuCount,
  MenuIcon,
  MenuItem,
  MenuItems,
  MenuText,
  ProfilContainer,
  ProfileInfo,
  SeparateurMenu,
  SidebarContainer,
  SidebarHeader
} from "../styled";

import ModalForgotPassword from "components/ModalITgalaxy/ModalForgotPassword/ModalForgotPassword";
import { FaCaretDown } from 'react-icons/fa'; // Importing down caret icon from Font Awesome
import { useGetCandidatsQuery, useGetCheckProfilCompletQuery } from "../../../redux/api/candidat/candidatApi";
import SideBarStatusCard from "../SideBarStatusCard/SideBarStatusCard";
import { saveBalanceToLocalStorage, saveCountProductToLocalStorage } from "../../../core/helpers/storage";

const SidebarCandidats = ({ path, id, onLogout }) => {
  const [dataUser, setDataUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(path);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [completeProfil, setCompleteProfil] = useState(true);


  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the menu visibility
  };

  const handleLogout = () => {
    onLogout();
  };

  const { data: user, isLoading, isError } = useGetCandidatsQuery(id, {
    skip: !id, // Skip if the id is not available
  });


  useEffect(() => {
    if (user && user.data) {
      setDataUser(user.data);
      saveCountProductToLocalStorage(user.data.count_sharedproduct);
      saveBalanceToLocalStorage(user.data.balance_details);
    }
  }, [user]);


  const { data, errorCheck, isLoadingCheck } = useGetCheckProfilCompletQuery(id);

  useEffect(() => {
    if (!isLoadingCheck && !errorCheck) {
      setCompleteProfil(data?.bol_user_complete);
    }
  }, [id, isLoadingCheck, errorCheck, data]);

  const menuItems = [
    {
      section: "Projects",
      items: [
        {
          icon: <IoGridOutline />,
          text: "Projects",
          path: "/projects",
        },
        {
          icon: <LuCode2 />,
          text: "IT Producer",
          path: "/trainer",
          count: `${user?.data?.count_sharedproduct}`
        },
        {
          icon: <LuMessageCircle />,
          text: "Messages",
          path: "/DashboardCandidat",
        },
        {
          icon: <LuAppWindow />,
          text: "IT Products",
          path: "/applications",
        },
        {
          icon: <LuCode2 />,
          text: "ItGalaxy Marketplace",
          path: "/sessioncloud",
        },
        {
          icon: <LuWallet />,
          text: "Wallet",
          path: "/wallet",
          count: `${user?.data?.balance_details}$`
        },
        {
          icon: <LuBuilding2 />,
          text: "Company details",
          path: "/company",
        },
      ],
    },
    {
      section: "bottom",
      items: [
        {
          icon: <img
            src={ItGalaxyLogoWhite}
            width="208"
            height="40"
            style={{ cursor: "pointer", margin: "16px" }}
          />, text: ""
        },
      ],
    },
  ];

  const handleNavigationProfil = () => {
    setSelectedMenuItem("/profil"); // Set the selected item
    navigate("/profil"); // Navigate to the profile page
  };

  const handleNavigationChangePassword = () => {
    setShowModalResetPassword(true);
  };

  const handleCloseChangePassword = () => {
    setShowModalResetPassword(false);
  };


  const handleNavigation = (path) => {
    setSelectedMenuItem(path);
    navigate(path);
  };

  return (
    <SidebarContainer>
      <SidebarHeader>
        <ProfilContainer onClick={handleMenuToggle}>
          <ImageProfilCard
            id={id}
            type={"candidats"}
            typeimg={"profilSidebar"}
          />
          <ProfileInfo>
            <AppName>{dataUser?.name}{" "}{dataUser?.first_name} </AppName>
          </ProfileInfo>
          <FaCaretDown style={{ marginLeft: "auto", cursor: "pointer" }} /> {/* React icon */}
        </ProfilContainer>
        {isMenuOpen && (
          <Dropdown>
            <DropdownItem
              onClick={handleNavigationProfil} // This will keep the menu open
              style={{
                backgroundColor: selectedMenuItem === "/profil" ? 'lightgray' : 'transparent',
              }}
            >
              Profil
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigationChangePassword()}>
              Reset password
            </DropdownItem>
            <DropdownItem onClick={handleLogout}>
              Logout
            </DropdownItem>
          </Dropdown>
        )}
        {showModalResetPassword && (
          <ModalForgotPassword show={true} onBack={handleCloseChangePassword} onHide={handleCloseChangePassword} />
        )}
      </SidebarHeader>
      <hr />
      <MenuItems>
        {menuItems.slice(0, -1).map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {section.items.map((item, itemIndex) => (
              <MenuItem
                key={`${sectionIndex}-${itemIndex}`}
                onClick={() => handleNavigation(item.path)}
                style={{
                  backgroundColor: selectedMenuItem === item.path ? 'gray' : 'transparent',
                }}
              >
                <MenuIcon>{item.icon}</MenuIcon>
                <MenuText>{item.text}</MenuText>
                {item.count !== undefined && (
                  <MenuCount>{item.count}</MenuCount>
                )}
              </MenuItem>
            ))}
            {sectionIndex < menuItems.length - 2 && <SeparateurMenu />}
          </React.Fragment>
        ))}
      </MenuItems>
      {!completeProfil && (
        <SideBarStatusCard status="COMPLETE_PROFIL_ACTION_REQUIRED" />
      )}

      <SideBarStatusCard status="IN_PROGRESS" />
      <BottomMenu>
        {menuItems[menuItems.length - 1].items.map((item, index) => (
          <MenuItem key={index} onClick={() => handleNavigation(item.path)}>
            <MenuIcon>{item.icon}</MenuIcon>
            <MenuText>{item.text}</MenuText>
          </MenuItem>
        ))}
      </BottomMenu>
    </SidebarContainer>
  );
};

export default SidebarCandidats;
