import React, { useCallback, useEffect, useState } from "react";
import Pagination from "components/Paginations/Pagination";
import { useGetJobsQuery } from "../../redux/api/jobs/JobsApi";
import ModalAdvancedSearch from "components/ModalITgalaxy/ModalAdvancedSearch/ModalAdvancedSearch";
import ModalApply from "components/ModalITgalaxy/ModalApply/ModalApply";
import { Spinner, Alert } from "react-bootstrap"; // Add Alert for error notifications
import { FormProvider, useForm } from "react-hook-form";
import { formConfig } from "./Jobs.constants";
import GenericInput from "../Inputs/GenericInput/GenericInput";
import DisplayRawHtml from 'components/DisplayRawHtml/DisplayRawHtml';

import {
  SearchBar,
  SearchIconInput,
  SearchInputContainer,
  Wrapper,
  CardContainers,
  ContainerWrapper,
  NotFoundTextStyle,
  AdvancedSearchButtonContainer,
  Budget,
  Bloc,
  Bloc2,
  ViewLabelMyJobs,
  BlocTitle,
  ViewLabelJobs,
  CardJobs,
  CardJob,
  DescriptionJob,
  Tag,
  AllTags,
  TitleJob,
  SearchFilter,
  JobLabelsContainer,
  DatePost,
  ListingJobs,
  StyleJobsCount,
  StyleLineCount,
  StyleCount,
  StyleCount1,
  StyleI,
  StyleProposalDone,
  BudgetWrapper,
  BackButton,
  NewJobButtonContainer
} from "./styled";
import { useGetAppelOffresbyuseridQuery } from "../../redux/api/appeloffres/appeloffreApi";
import { useGetUserAllTasksProjectsQuery } from "../../redux/api/candidat/candidatApi";
import AddNewJob from "../AddNewJob/AddNewJob";

export default function Jobs({ id, role, setAlljobs }) {
  const [showModalAdvancedSearch, setShowModalAdvancedSearch] = useState(false);
  const [showModalApply, setShowModalApply] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [filterType, setFilterType] = useState("All");
  const [jobsData, setJobsData] = useState();
  const [addNewJob, setAddNewJob] = useState(false);
  const formMethods = useForm({});
   
  const [formData, setFormData] = useState({
    filterType: [],
    positions: [],
    applications: '',
    skills: [],
    locations: [],
    languages: [],
    createdAt: '',
    keywords: '',
  });

  // Fetch jobs data
  const {
    data: jobData,
    error: jobsDataError,
    isLoading: jobsDataLoading,
    refetch
  } = useGetJobsQuery({
    filterType: filterType,
    params: `?page=${currentPage}&limit=${recordsPerPage}${searchQuery}`,
  });

  // Fetch proposal data
  const {
    data: proposalAOData = {},
  } = useGetAppelOffresbyuseridQuery({
    filterType: role,
    params: `&proposalAppelOffreApplierId=${id}`,
  });

  const proposalAOArray = proposalAOData.data?.map(proposal => proposal.id) || [];

  const {
    data: proposalArray = [],
  } = useGetUserAllTasksProjectsQuery({
    id: id,
    params: `&userType=${role}`, 
  });

  const closeAddNewJob = () => {
    setAddNewJob(false);
  };

  const handleChangeFilter = (e) => {
    setFilterType(e?.target?.value);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);
  const descriptionLimit = 80;

  const handelMyJobs = () => {
    setAlljobs(false);
  };

  const handleShowModalAdvancedSearch = () => {
    setShowModalAdvancedSearch(!showModalAdvancedSearch);
  };

  const handleAddNewJob = () => {
    setAddNewJob(true);
  };

  const handleShowModalApply = () => {
    setShowModalApply(!showModalApply);
    setSelectedItem(null); // Reset selected item when closing modal
  };

  useEffect(() => {
    if (!jobsDataLoading && jobData) {
      setJobsData(jobData);
    }
  }, [jobData, jobsDataLoading, currentPage, searchQuery]);

  const handleChangeSearch = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const queryParams = e.target.value ? `&description=${e.target.value}` : '';
        setSearchQuery(queryParams);
      }
    },
    []
  );

  const handleSearchSubmit = (e) => {
    let queryParams = "";

    // Set filter type based on formData
    if (formData.filterType.length === 0 || formData.filterType.length === 2) {
      setFilterType("All");
    } else {
      setFilterType(formData.filterType[0].value);
    }

    // Collect search filters from formData
    const paramsMap = {
      positions: 'category',
      skills: 'skills',
      locations: 'locations',
      languages: 'languages',
    };

    Object.keys(paramsMap).forEach(key => {
      if (formData[key].length > 0) {
        const arrayValue = formData[key].map(item => item.value).join(',');
        queryParams += `&${paramsMap[key]}=${arrayValue}`;
      }
    });

    // Add other filters
    if (formData.applications) {
      queryParams += `&applications=${formData.applications}`;
    }

    if (formData.createdAt) {
      queryParams += `&createdAt=${formData.createdAt}`;
    }

    if (formData.keywords) {
      queryParams += `&keywords=${formData.keywords}`;
    }

    setSearchQuery(queryParams);
    setShowModalAdvancedSearch(false);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModalApply(true);
  };

  return (
    <>
     {!addNewJob ? (
      <Wrapper>
        <ContainerWrapper className="container">
            <ListingJobs>
              <SearchBar>
                <FormProvider {...formMethods}>
                  <SearchInputContainer onKeyDown={handleChangeSearch}>
                    <GenericInput
                      inputObject={{ ...formConfig.search, label: "search" }}
                      disabledForm={false}
                    />
                    <SearchIconInput className="fa fa-search" />
                  </SearchInputContainer>
                  <SearchFilter>
                    <GenericInput
                      inputObject={{ ...formConfig.filter, label: "filter" }}
                      onChange={handleChangeFilter}
                      disabledForm={false}
                    />
                  </SearchFilter>
                </FormProvider>
                <AdvancedSearchButtonContainer onClick={handleShowModalAdvancedSearch}>
                  Advanced search 
                </AdvancedSearchButtonContainer>
                <NewJobButtonContainer onClick={handleAddNewJob}>
                  Add New Job
                </NewJobButtonContainer>

                {showModalAdvancedSearch && (
                  <ModalAdvancedSearch
                    confirmShow={showModalAdvancedSearch}
                    closeModal={handleShowModalAdvancedSearch}
                    setFormData={setFormData}
                    handleSearchSubmit={handleSearchSubmit}
                  />
                )}
              </SearchBar>
              <hr />
              {jobsDataLoading ? (
                <CardContainers>
                  <Spinner animation="border" />
                </CardContainers>
              ) : jobsDataError ? ( 
                // Handling error display
                <CardContainers>
                  <Alert variant="danger">
                    There was an error fetching jobs. Please try again later.
                  </Alert>
                </CardContainers>
              ) : (
                <>
                  <StyleLineCount>
                    <JobLabelsContainer>
                      <ViewLabelJobs>View all jobs</ViewLabelJobs>
                      <ViewLabelMyJobs onClick={handelMyJobs}>My jobs</ViewLabelMyJobs>
                    </JobLabelsContainer>
                    <StyleJobsCount>
                      {jobsData && (
                        <StyleCount1>
                          <StyleCount>{jobsData.pagination.totals}</StyleCount> jobs found
                        </StyleCount1>
                      )}
                    </StyleJobsCount>
                  </StyleLineCount>

                  {jobsData && jobsData.data.length === 0 ? (
                    <CardContainers>
                      <NotFoundTextStyle>
                        Please change the criteria to find more Jobs ...
                      </NotFoundTextStyle>
                    </CardContainers>
                  ) : (
                    <>
                      <CardJobs>
                        {jobsData?.data.map((item) => (
                          <CardJob 
                            key={item.id + item.type} 
                            onClick={() => handleItemClick(item)} 
                            canApply={
                              (item.type === 'Contrat' && proposalAOArray.includes(item.id)) || 
                              (item.type === 'SHARETASK' && proposalArray.includes(item.id)) || 
                              (item.type === 'Contrat' && id === item.recruter.publierid)
                            }
                          >
                            <BlocTitle>
                              <TitleJob>{item.title}</TitleJob>

                              {(item.type === 'Contrat' &&  proposalAOArray?.includes(item.id)) || 
                              (item.type === 'SHARETASK' && proposalArray?.includes(item.id)) ? (
                                <StyleProposalDone>
                                  <i className="fa fa-check-circle"></i>
                                  <span>Proposal Done</span>
                                </StyleProposalDone>
                              ) : (item.type === 'Contrat' && id === item.recruter.publierid) ? (
                                <StyleProposalDone>
                                  <i className="fa fa-check-circle"></i>
                                  <span>Your Offer</span>
                                </StyleProposalDone>
                              ) : item.type === 'SHARETASK' ? (
                                <BudgetWrapper>
                                  <Budget>Budget: {item.price}$</Budget>
                                </BudgetWrapper>
                              ) : (
                                <BudgetWrapper>
                                  <Budget>Contract</Budget>
                                </BudgetWrapper>
                              )}
                            </BlocTitle>  

                            <DescriptionJob>
                              <DisplayRawHtml 
                                content={showFullDescription ? item.description : item.description.substring(0, descriptionLimit) + "..."} 
                              />                         
                            </DescriptionJob>

                            <AllTags>
                              {item.skills.map((tag, index) => (
                                <Tag key={index}>{tag}</Tag>
                              ))}
                            </AllTags>

                            <Bloc>
                              <Bloc2>
                                <DatePost>
                                  Proposals: {item.proposal_count ||
                                    (item.appeloffreProposals && item.appeloffreProposals.length) ||
                                    (item.proposals && item.proposals.length) || 0}
                                </DatePost>
                                <DatePost>
                                  Posted: {item.createdAt ? new Date(item.createdAt).toDateString() : new Date(item.createdat).toDateString()}
                                </DatePost>
                                <StyleI>
                                  <i className="fa fa-map-marker icon"></i>
                                  {item.location}
                                </StyleI>
                              </Bloc2>
                            </Bloc>
                          </CardJob>
                        ))}
                      </CardJobs>
                      <div className="col-lg-12 col-md-12 col-12 mt-4">
                        {jobsData?.pagination?.totalPages > 1 && (
                          <Pagination
                            nPages={jobsData.pagination.totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </ListingJobs>    
          </ContainerWrapper>
        </Wrapper>
        ) : (
          <Wrapper>
            <ContainerWrapper className="container">
              <ListingJobs>
                <BackButton onClick={closeAddNewJob}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </BackButton>
                <AddNewJob id={id} refetch={refetch} setAddNewJob={setAddNewJob} />
              </ListingJobs>
            </ContainerWrapper>
          </Wrapper>      
        )}
        {selectedItem && (
          <ModalApply
            job={selectedItem}
            methods={formMethods}
            confirmShow={showModalApply}
            closeModal={handleShowModalApply}
            canApply={
              (selectedItem.type === 'Contrat' && proposalAOArray.includes(selectedItem.id)) || 
              (selectedItem.type === 'SHARETASK' && proposalArray.includes(selectedItem.id))
            }
            myOffer={(selectedItem.type === 'Contrat' && id === selectedItem.recruter.publierid)}
          />    
        )}
    </>
  );
}
