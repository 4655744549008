import React, { useEffect, useState } from "react";
import GenericInput from 'components/Inputs/GenericInput/GenericInput';
import { Button } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { depositCompanyFormConfig } from '../DepositCompanyForm.config';
import { StyledLabel, SearchFilterRegion } from '../DepositCompanyForm.style';
import { useDispatch } from 'react-redux';
import { getURL, s3Upload } from "../../../redux/api/uploads/uploadSlice";
import { useUpdateCompanyMutation } from "../../../redux/api/company/companyApi";
import { useUpdateRequestCompanyCreationMutation } from "../../../redux/api/requestCompanyCreation/requestCompanyCreationApi";
import { fileToBase64 } from '../../../utils/fileConvertion'; // Make sure this utility function exists

const CompanyExistAddressForm = ({ isEdit = true, data, companyType, onValidate, onCancel, formMethods, disableStep , refetchCompany , refetchCompanyRequestCreation}) => {
  const [showError, setShowError] = useState(false);
  const watchCompanyCountry = formMethods.watch('companyCountry');
  const watchCompanyAddress = formMethods.watch('companyAddress');
  const watchcompanyAddressProofAttachment = formMethods.watch('companyAddressProofAttachment');
  const status       = data ? data[0]?.company_location_status : null;
  const defaultValue = data ?  [{'title' : data[0]?.company_country , 'label' : data[0]?.company_country  , 'value' : data[0]?.company_country }] : [];

  const folder = companyType === 'Company Exist' ? 'company' : 'request_company_creation';
  const [previewCompanyLocation, setPreviewCompanyLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [updateCompany] = useUpdateCompanyMutation();
  const [updateRequestCompany] = useUpdateRequestCompanyCreationMutation();

  // Fetch URL data for company location
  const getUrlData = async () => {
    setLoading(true);
    try {
      const result = await dispatch(getURL({ location: `${folder}/${data[0]?.id}/companyAddress/` }));
      const companyLocationPath = result?.Contents?.[0]?.Key;

      if (companyLocationPath) {
        setPreviewCompanyLocation(`${process.env.REACT_APP_S3_URL}/${companyLocationPath}`);
      } else {
        console.error("Company Location contents not found");
      }
    } catch (error) {
      console.error("Error fetching URL data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUrlData();
  }, [data]);

  const handleValidateClick = async () => {
    // Check if required fields are empty
    if (
      !watchCompanyCountry ||
      !watchCompanyAddress ||
      !watchcompanyAddressProofAttachment
    ) {
      setShowError(true);
      return; // Exit the function if validation fails
    } else {
      setShowError(false);
    }

    if (status === 'ACTION_REQUIRED' || status === 'VERIFICATION') {
      const body = {
        company_location_status: 'VERIFICATION',
        company_country: watchCompanyCountry.value,
        company_location: watchCompanyAddress,
      };

      let response;
      try {
        if (folder === 'company') {
          response = await updateCompany({ companyId: data[0]?.id, companyData: body });
          const basePath = `company/${response.data.id}/`;
          await uploadAddressProof(basePath);
        } else if (folder === 'request_company_creation') {
          response = await updateRequestCompany({ requestCompanyCreationId: data[0]?.id, requestCompanyCreationData: body });
          const basePath = `request_company_creation/${response.data.id}/`;
          await uploadAddressProof(basePath);
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
      refetchCompany();
      onValidate(); // Call onValidate after successful update
    } else {
      onValidate(); // Call onValidate after successful update

    }
  };

  const uploadAddressProof = async (basePath) => {
    const addressProofFile = convertJSONtoFile(watchcompanyAddressProofAttachment);
    const base64URL = await fileToBase64(watchcompanyAddressProofAttachment); 

    await dispatch(s3Upload({
      file: addressProofFile,
      type: "companyAddress/", // Ensure this matches
      location: basePath,
      base64URL: base64URL,
    }));
  };

  const convertJSONtoFile = (jsonFile) => {
    const content = new Blob([" ".repeat(jsonFile?.size)], { type: jsonFile?.type });
    return new File([content], jsonFile?.name, {
      lastModified: jsonFile?.lastModified,
      type: jsonFile?.type,
      lastModifiedDate: jsonFile?.lastModifiedDate,
      webkitRelativePath: jsonFile?.webkitRelativePath,
      size: jsonFile?.size,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <StyledLabel>1. What's your company country?</StyledLabel>
      <SearchFilterRegion>
        <GenericInput
          inputObject={{
            ...depositCompanyFormConfig.companyCountry,
            defaultValue: defaultValue, // Default value here if your GenericInput supports it
          }}
          disabledForm={disableStep}
          label="What's your company country?"
        />
      </SearchFilterRegion>

      <StyledLabel>2. What's your company address?</StyledLabel>
      <GenericInput
        inputObject={{
          ...depositCompanyFormConfig.companyAddress,
        }}
        disabledForm={disableStep}
        label="What's your company address?"
      />

      <GenericInput
        inputObject={{
          ...depositCompanyFormConfig.companyAddressProofAttachment,
        }}
        disabledForm={disableStep}
        label="Upload your address proof?"
        previewInput={previewCompanyLocation}
        isEdit={!disableStep}
      />

      {/* Error message section */}
      {showError && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          <strong>Please fill in all required fields.</strong>
        </div>
      )}

      <div style={{ float: "right", marginTop: "10%" }}>
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: "15px" }}
          variant="primary"
          onClick={handleValidateClick}
          disabled={(!status && !watchCompanyCountry) || (!status && !watchCompanyAddress) || (!status && !watchcompanyAddressProofAttachment) || (status && status === 'VERIFICATION')} // Disable if inputs are empty
        >
          Confirm
        </Button>
      </div>
    </FormProvider>
  );
};

export default CompanyExistAddressForm;
