export const ABOUT_IT_GALAXY = `Frustrated with the traditional hiring process? Looking to break
                free and build your dream freelance career? We've got you
                covered! Our platform connects you with a vast network of
                pre-vetted IT freelancers, allowing you to streamline projects
                and access top talent. But that's not all! We offer a
                comprehensive suite of online courses designed specifically for
                both freelancers and clients. Upskill and learn in-demand
                expertise, discover strategies for finding the perfect match,and
                unlock the full potential of the freelance world. Empower your
                business or unleash your freelance potential – it all starts
                here.`;

export const HIRE_FREELANCERS_ARRAY = [
  "Explore all",
  "Top Independents",
  "Design",
  "Engineering",
  "Marketing",
];

export const COMPANY_ARRAY = [
  "Mission",
  "Careers",
  "Terms & conditions",
  "Privacy policy",
  "Cookie policy",
];

export const CONTACT_ARRAY = [
  "Request a demo",
  "Contact sales",
  "Contact support",
];
