import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./DeclinePaymentModal.constants";
import {
  StyledContainer,
  StyledDiv,
  StyledFormBody,
  ActiveVersementButton
} from "./DeclinePaymentModal.style";
import useHandleSubmitUpdateProposal from "../../../hooks/useHandleSubmitUpdateProposal";


const DeclinePaymentModal = (props) => {
  const [reviewComment, setReviewComment] = useState("");
  const formMethods = useForm({ mode: "onChange", shouldFocusError: true });

  let { handleDecline, loading } = useHandleSubmitUpdateProposal(
    props.proposal,
    formMethods,
  );


  const handleProposalDescriptionChange = (e) => {
    setReviewComment(e.target.value);
  };

  const handleDeclinePaiement = async () => {

    try {
      const response = await handleDecline();
      if (response.success) {
        props.handleShowModal();
      }

    } catch (error) {
      console.error("Error updating proposal:", error);
      // Optionally show some error message
    }
  };

  const RenderBodyDeclinePaiement = (
    <StyledDiv>
      <StyledFormBody>
        <StyledContainer>
          <FormProvider {...formMethods}>
            <GenericInput
              inputObject={{
                ...formConfig.comment,
                defaultValue: reviewComment,
              }}
              onChange={handleProposalDescriptionChange}
              disabledForm={false}
            />
          </FormProvider>
        </StyledContainer>
      </StyledFormBody>
    </StyledDiv>
  );

  const RenderHeader = <>Provide a clear reason for declining this Proposal</>;

  const RenderFooter = (
    <ActiveVersementButton onClick={handleDeclinePaiement} >
      {"Decline Versement"}
    </ActiveVersementButton>
  );

  return (
    <ModalComponent
      show={props.showModalDecline}
      closeModal={props.handleShowModal}
      header={RenderHeader}
      body={RenderBodyDeclinePaiement}
      footer={RenderFooter}
      bodyPadding={"0px"}
      minWidth={"50vw"}
      footerpaddingtop={"0"}
      footerpaddingbottom={"0"}
    />
  );
};

export default DeclinePaymentModal;
