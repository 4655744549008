import styled from "styled-components";

export const StyledFormationDetailContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
`;

export const StyledFormationDetailContentContainer = styled.div`
  /* width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // change padding according to screen width
  @media (max-width: 768px) {
    padding-inline: 20px;
  }
  @media (min-width: 768px) {
    padding-inline: 50px;
  }
  @media (min-width: 1024px) {
    padding-inline: 80px;
  }
  @media (min-width: 1440px) {
    padding-inline: 18%;
  }
`;

export const BackButton = styled.button`
  align-self: flex-start;
  margin-top: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
`;
