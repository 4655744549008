import React from "react";
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { ErrorCode, ErrorMessage, GoHomeButton, NotFoundContainer } from './notFound.style';
import FooterHome from "../../components/DashboardHome/FooterHome/FooterHome";

export default function NotFound() {
  const navigate = useNavigate();

  return (<>
    <Header />
    <NotFoundContainer>
      <ErrorCode>404</ErrorCode>
      <ErrorMessage>Page Not Found</ErrorMessage>
      <GoHomeButton onClick={() => navigate('/')}>Go Home</GoHomeButton>
    </NotFoundContainer>
    <FooterHome />
  </>
  );
}