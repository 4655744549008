// CoursePreviewCard.jsx
import { CardContainer, CardImage, CardSubtitle, CardTitle, CourseModulesLink, DurationBadge, ImageWrapper, PlayButton, StyledHorizontalLine } from './CoursePreviewCard.style';
const CoursePreviewCard = ({ imageSrc, duration, onModulesClick }) => {
  return (
    <CardContainer>
      <CardTitle>Course</CardTitle>
      <CardSubtitle>Gain insight into a topic and learn the fundamentals</CardSubtitle>
      <StyledHorizontalLine />
      <ImageWrapper>
        <CardImage src={imageSrc} alt="Course preview" />
        <DurationBadge>{duration}</DurationBadge>
        <PlayButton>▶️</PlayButton>
      </ImageWrapper>
      <StyledHorizontalLine />
      <CourseModulesLink onClick={onModulesClick}>View course modules</CourseModulesLink>
    </CardContainer>
  );
};

export default CoursePreviewCard;
