import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ItGalaxyLogo from "../../assets/logo/ItGalaxy-Logo.png";
import ItGalaxyLogoWhite from "../../assets/logo/ItGalaxy-LogoWhite.png";
import ItGalaxyMobile from "../../assets/logo/logoWhite.png";
import {
  RootRoute
} from "../../core/constants/routes.constants";
import useLoggedIn from "../../hooks/useLoggedIn";
import { logout } from "../../redux/features/user";
import Login from "../Authentification/modals/login";
import Register from "../Authentification/modals/register";
import SearchBarAdvanced from "../SearchBarAdvanced/SearchBarAdvanced";
import {
  ButtonInscription,
  ButtonLogin,
  Logo,
  RootStyle,
  StackStyle,
} from "./Header.style";

export default function Header({ active }) {
  const isLoggedIn = useLoggedIn();
  const dispatch = useDispatch();
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [searchType, setSearchType] = useState("Training");
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);

  const handleModalLogin = () => {
    setOpenModalLogin(!openModalLogin);
  };

  const handleModalRegister = () => {
    setOpenModalRegister(!openModalRegister);
  };

  const switchBetweenModals = () => {
    if (openModalLogin) {
      setOpenModalLogin(false);
      setOpenModalRegister(true);
    } else if (openModalRegister) {
      setOpenModalRegister(false);
      setOpenModalLogin(true);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("getstreamtoken");
    localStorage.removeItem("isloggedin");
    localStorage.removeItem("resources");
    navigate(RootRoute, { replace: true });
  };
  const handleHomePageRedirection = () => {
    /*navigate(
      isLoggedIn
        ? storeUser.type === "entreprise"
          ? DashboardCompanyRoutes.chat
          : DashboardFreelancerRoutes.chat
        : RootRoute,
      { replace: true }
    );*/
  };
  const [scrolling, setScrolling] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // Change 50 to the scroll position you want
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const isMobile = window.innerWidth <= 768
  return (
    <RootStyle
      isLoggedIn={isLoggedIn}
      active={active}
      scrolling={scrolling}
      isFocused={isFocused}
    // style={{
    //   width: isLoggedIn ? "95%" : "100%",
    //   paddingInline: isLoggedIn ? "8%" : undefined,
    //   backgroundColor: scrolling
    //     ? (active === 'FREELANCERS' ? 'black' : 'white')
    //     : 'transparent', // Transparent before scrolling
    //   // transition: 'background-color 0.2s ease', // Smooth transition
    //   position: scrolling ? 'fixed' : 'unset', // Fixed after scrollin
    //   height: scrolling ? '80px' : '0px', // Fixed after scrolling
    //   paddingLeft:"9.875em", paddingRight:"9.875em"
    //         }}
    //         isFocused={isFocused}

    >
      {(!isFocused || !isMobile) && <Logo
        src={isMobile ? ItGalaxyMobile : active === 'FREELANCERS' ? ItGalaxyLogoWhite : ItGalaxyLogo}
        onClick={handleHomePageRedirection}
        isFocused={isFocused}
        isMobile={isMobile}
      />}
      <div style={{ display: "flex", width: "100%", justifyContent: isFocused && isMobile ? "center" : "flex-end", padding: isFocused && isMobile ? "0px 15px 0px 15px" : "0px" }}>
        <SearchBarAdvanced searchType={searchType} isFocused={isFocused} active={active} setIsFocused={setIsFocused} isMobile={isMobile} />
        <div style={{ display: "flex", gap: "16px" }}>

          {((isFocused && isMobile) || !isMobile) && <Form.Select
            aria-label="Search type"
            style={{ minWidth: "120px", width: "24%", height: "46px", backgroundColor: active === 'FREELANCERS' ? 'black' : 'white', color: active === 'FREELANCERS' ? 'white' : 'black' }}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="Training">Training</option>
            <option value="Freelancer">IT Workers</option>
            <option value="Produit">Product</option>
            <option value="Session Formation">Cloud</option>
          </Form.Select>}

          {(!isFocused || !isMobile) &&
            <>
              <StackStyle direction={"row"} spacing={2}>
                <ButtonInscription onClick={handleModalRegister} active={active} >
                  Sign up
                </ButtonInscription>
                {!isMobile && <ButtonLogin onClick={handleModalLogin} active={active}>Login</ButtonLogin>}
              </StackStyle>

            </>
          }

        </div>

      </div>
      <Login
        openModalLogin={openModalLogin}
        setOpenModalLogin={setOpenModalLogin}
        handleModalLogin={handleModalLogin}
        switchBetweenModals={switchBetweenModals}
        proxy={"dashboard"}
      />
      <Register
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalRegister}
        switchBetweenModals={switchBetweenModals}
        proxy={"dashboard"}
      />
    </RootStyle>
  );
}
