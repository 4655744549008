import React, { useCallback, useEffect, useState } from "react";
import ModalComponent from "components/ModalITgalaxy/ModalComponent";
import { Spinner } from "react-bootstrap";
import { useGetItGalaxyContactsQuery } from "../../redux/api/contact/contactApi";
import EditProfil from "../ComponnentProfilItems/EditProfil";
import Pagination from "components/Paginations/Pagination";
import Card from "./card";
import {
  CardContainers,
  SecondContainer,
  SearchContainer,
  SearchBar,
  SearchInputContainer,
  SearchIconInput,
  SearchInput,
  StyledCandidatesCardsContainer,
  AddProposalContainer,
  SearchFilter,
} from "./styled";
import ModalContactor from "../ModalITgalaxy/ModalContactor/ModalContactor";
import { formConfig } from "./ItGalaxyContactor.constants";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { FormProvider, useForm } from 'react-hook-form';

export default function ItGalaxyContactorComponents(props) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSearch, setFilterSearch] = useState("ALL");
  const [countryDetails, setCountryDetails] = useState("");
  const [showModalProposal, setShowModalProposal] = useState(false);
  // Default contacts to an empty object to prevent undefined reference
  const [contacts, setContacts] = useState({ data: [], pagination: {} });

  const recordsPerPage = 10;

  const {
    data: contactsData,
    error: contactsDataError,
    isLoading: contactDataLoading,
  } = useGetItGalaxyContactsQuery(
    `?page=${currentPage}&limit=${recordsPerPage}${filterSearch}${searchQuery}`
  );

  // Function to handle search input changes
  const handleChangeSearch = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const newSearchTerm = e.target.value ? `&search=${e.target.value}` : '';
        setSearchQuery(prevQuery => {
          return prevQuery.includes('search=') ? prevQuery.replace(/&search=[^&]*/, newSearchTerm) : prevQuery + newSearchTerm;
        });
      }
    },
    []
  );

  // Function to update country details in the query
  const handleChangeCountryDetails = (e) => {
    setCountryDetails(e);
    const queryParams = `&country_details=${e}`;
    setSearchQuery(prevQuery => {
      return prevQuery.includes('country_details=') ? prevQuery.replace(/&country_details=[^&]*/, queryParams) : prevQuery + queryParams;
    });
  };

  // Function to update the filter type
  const handleChangeFilterSearch = (e) => {
    setFilterSearch(e);
    const queryParams = `&type=${e}`;
    setSearchQuery(prevQuery => {
      return prevQuery.includes('type=') ? prevQuery.replace(/&type=[^&]*/, queryParams) : prevQuery + queryParams;
    });
  };

  // Updating the contacts state once we receive data from the API
  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData); // Set contacts to the data received from an API
    }
  }, [contactsData]);

  const handleConnecter = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleShowModalApply = () => {
    setShowModalProposal(true);
    setShowModal(false);
  };

  const closeModalProposal = () => {
    setShowModalProposal(false);
    setShowModal(false);
  };

  const RenderFooter = (
    <AddProposalContainer onClick={handleShowModalApply}>
      Create a proposal
      </AddProposalContainer>
  );

  return (
    <FormProvider {...useForm()}> {/* Provide form context */}
      <SecondContainer>
        <SearchContainer>
          <SearchBar>
            <SearchInputContainer onKeyDown={handleChangeSearch}>
              <SearchIconInput className="fa fa-search" />
              <SearchInput
                type="search"
                placeholder="Search here..."
              />
            </SearchInputContainer>
            <SearchFilter>
              <GenericInput
                inputObject={{
                  ...formConfig.filter, label: "Filter" }}
                value={filterSearch}
                onChange={(e) => handleChangeFilterSearch(e.value)}
                disabledForm={false}
              />
            </SearchFilter>
            <SearchFilter>
              <GenericInput
                inputObject={{ ...formConfig.locations, label: "Locations" }}
                value={countryDetails}
                onChange={(e) => handleChangeCountryDetails(e.value)}
                disabledForm={false}
              />
            </SearchFilter>
          </SearchBar>
        </SearchContainer>

        {contactDataLoading ? (
          <CardContainers>
            <Spinner />
          </CardContainers>
        ) : contactsDataError ? (
          <CardContainers>
            <div>Error loading contacts. Please try again.</div>
          </CardContainers>
        ) : (
          <StyledCandidatesCardsContainer>
            {contacts.data?.length === 0 ? (
              <CardContainers>
                <div>No Contacts found</div>
              </CardContainers>
            ) : (
              <>
                <CardContainers>
                  {contacts.data.map((item) => (
                    <Card
                      key={item.id} 
                      item={item}
                      handleConnecter={handleConnecter}
                    />
                  ))}
                </CardContainers>
                {contacts.pagination?.totalPages > 1 && (
                  <div className="col-lg-12 col-md-12 col-12 mt-4">
                    <Pagination
                      nPages={contacts.pagination.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                )}
              </>
            )}
          </StyledCandidatesCardsContainer>
        )}
      </SecondContainer>

      {showModal && (
        <ModalComponent
          show={showModal}
          closeModal={setShowModal}
          body={<EditProfil userData={selectedItem} editProfil={false} role={selectedItem?.type} />}
          bodyPadding="0"
          minWidth="80vw"
          height={"100%"}
          min-height={"100%"}
          footerpaddingtop={"0"}
          footerpaddingbottom={"0"}
          footer={RenderFooter}
        />
      )}

      {showModalProposal && (
        <ModalContactor
          id={props.id}
          role={props.role}
          userdata={selectedItem}
          show={showModalProposal}
          setCandidatesProfils={props.setCandidatesProfils}
          closeModal={closeModalProposal}
        />
      )}
    </FormProvider>
  );
}
