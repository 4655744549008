import { useEffect, useState } from "react";
import { Label, OptionContainer, StyledInput, StyledRadioContainer } from "./CustomMultiRadioInput.style";

const CustomMultiRadioInput = ({ options, name, selectedValue, onChange, disabled }) => {
    const [checkedValue, setCheckedValue] = useState(selectedValue || "");

    // Sync the internal state when `selectedValue` changes
    useEffect(() => {
        setCheckedValue(selectedValue);
    }, [selectedValue]);

    const handleChange = (value) => {
        if (!disabled) {  // Only allow changing if not disabled
            setCheckedValue(value);
            if (onChange) onChange(value);
        }
    };

    return (
        <StyledRadioContainer>
            {options.map((option) => (
                <OptionContainer key={option.id}>
                    <StyledInput
                        type="radio"
                        id={option.id}
                        name={name}
                        value={option.value}
                        checked={checkedValue === option.value}
                        onChange={() => handleChange(option.value)}
                        disabled={disabled} // Apply the disabled prop to the input
                    />
                    <Label
                        htmlFor={option.id}
                        onClick={() => handleChange(option.value)}
                        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} // Show a 'not-allowed' cursor if disabled
                    >
                        <div>
                            <span className="text d-block">
                                <b>{option.label}</b>
                            </span>
                        </div>
                    </Label>
                </OptionContainer>
            ))}
        </StyledRadioContainer>
    );
};

export default CustomMultiRadioInput;
