import React from "react";
import { Wrapper, FileList, FileInfo, FileName, FileItem, StyledInput, DeleteButton } from "./CustomFileInput.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileImage, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';

const getIconByFileType = (fileType) => {
  if (fileType.includes('image')) {
    return faFileImage;
  } else if (fileType.includes('pdf')) {
    return faFilePdf;
  } else {
    return faFile;
  }
};

const CustomFileMultipleInput = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  accept,
  label,
  selectedFiles,  // Now received as prop
  setSelectedFiles // Now received as prop
}) => {
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = [...selectedFiles, ...files];
    setSelectedFiles(updatedFiles);
    onChange(updatedFiles); // Propagate the change to parent
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, fileIndex) => fileIndex !== index);
    setSelectedFiles(updatedFiles);
    onChange(updatedFiles); // Propagate the change to parent
  };
  
  return (
    <React.Fragment>
      <StyledInput
        type="file"
        id="file-upload"
        multiple
        name="files"
        onChange={handleFileChange}
        accept=".jpg,.png,.pdf,.doc,.docx,.txt"
      />
      <FileList>
        {selectedFiles.map((file, index) => (
          <FileItem key={index}>
            <FileInfo>
              <FontAwesomeIcon icon={getIconByFileType(file.type)} size="lg" />
              <FileName>{file.name}</FileName>
            </FileInfo>
            <DeleteButton onClick={() => handleDeleteFile(index)}>
              <FontAwesomeIcon icon={faTrash} />
            </DeleteButton>
          </FileItem>
        ))}
      </FileList>
    </React.Fragment>
  );
};

export default CustomFileMultipleInput;
