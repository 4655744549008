import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { getTokenFromLocalStorage } from "../core/helpers/storage";
import { useUpdateProposalEntrepriseMutation, useUpdateProposalMutation } from "../redux/api/proposals/proposalApi";
import { useRefundPaypalPaymentMutation } from "../redux/api/payment/paymentApi";
import { useAddInvoicingMutation } from '../redux/api/invoicing/invoicingApi';

const useHandleSubmitUpdateProposal = (proposal, formMethods) => {
  const [loading, setLoading] = useState(false);
  const [updateEntrepriseProposal] = useUpdateProposalEntrepriseMutation();
  const [updateProposalShareTask] = useUpdateProposalMutation();
  const [refundPaypalPayment] = useRefundPaypalPaymentMutation();
  const [createInvoice] = useAddInvoicingMutation();


  const updateProposal = async (status, additionalData, typeProposal, comment) => {
    setLoading(true);
    try {
      const updateBody = {
        proposalData: { status, ...additionalData },
        comment: comment
      };

      const response = typeProposal === 'COMPOSED_FREELANCE'
        ? await updateEntrepriseProposal({ body: updateBody, id: proposal.id }).unwrap()
        : await updateProposalShareTask({ body: updateBody, id: proposal.id }).unwrap();
      return response;
    } catch (error) {
      console.error('Error during proposal update:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    try {
      // Update the proposal status to 'DECLINED' with a reason comment
      const declineResponse = await updateProposal("DECLINED", {
        proposal_description: 'Reason decline: ' + formMethods.watch("comment"),
      }, proposal.type);

      // Check if the proposal was successfully declined
      if (declineResponse?.data?.status === 'DECLINED') {
        try {
          // If there's an orderID, initiate a PayPal refund
          if (proposal.orderID) {
            await refundPaypalPayment({
              params: proposal.orderID,
              body: { amount: proposal.totalPrice },
            }).unwrap();
          }

          // Create an invoice reflecting the refund
          const invoice = await createInvoice({
            payerId: declineResponse.data.EntrepriseId,
            payerType: 'ENTREPRISE',
            price: declineResponse.data.totalPrice,
            paymentType: 'paypal',
            targetProductType: declineResponse.data.type,
            targetProductId: declineResponse.data.id,
            orderId: proposal.orderID,
            invoicingDescription: 'Proposal Declined',
            status: 'REFUND'
          });

          console.log("Invoice created", invoice);
          window.location.href = `/projects`;      


          return { success: true };
        } catch (error) {
          console.error('Error during refund or invoicing:', error.message);
        }
      } else {
        console.error('Failed to decline the proposal:', declineResponse);
      }
    } catch (error) {
      console.error('Error updating proposal:', error.message);
    }

    return { success: false };
  };

  const handleActivateSubmit = async (orderID) => {

    const submitResponse = await updateProposal("VALIDATED", { orderID: orderID }, proposal.type);

    if (submitResponse && submitResponse.data && submitResponse.data.status === 'VALIDATED') {

      return {
        success: true,
        url: 'projects',
        targetProductType: submitResponse.data.type,
        targetProductId: submitResponse.data.id,
        invoicingDescription: "VALIDATED the proposal",
      };
    }

    return { 
      success: false,
      targetProductType: proposal.type,
      targetProductId: proposal.id,
      invoicingDescription: "REFUND : error create proposal ", 
    };
  };

  const handleFinishSubmit = async (comment, starts) => {
    const submitResponse = await updateProposal("FINISHED", {}, proposal.type, {
      stars: starts,
      comment_text: comment,
    },
    );
    if (submitResponse && submitResponse.data && submitResponse.data.status === 'FINISHED') {
      try {

        return { success: true, url: 'projects' };
      } catch (error) {
        console.error('Error during message sending:', error.message);
        return { success: false };
      }
    }

    return { success: false };
  };

  return { handleDecline, handleActivateSubmit, handleFinishSubmit, loading, setLoading };
};

export default useHandleSubmitUpdateProposal;
