import styled from "styled-components";

export const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
`;

export const StyledInput = styled.input`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: 1px solid #ced4da;
  border-radius: 50%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:checked::before {
    content: "✓";
    text-align: center;
    display: block;
    background-color: #009ff5;
    border-radius: 50%;
    color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Label = styled.label`
  display: block;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 200px;
`;
