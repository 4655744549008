import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GenericSwitcherLayout from "../../components/DashboardTrainerComponnents/GenericSwitcherLayout/GenericSwitcherLayout";
import SideBar from "../../components/SideBar/sideBar";
import { getAccessToken } from "../../core/helpers/storage";
import { useGetSoldedProductsQuery } from "../../redux/api/soldedProducts/soldedProductsApi";
import {
  useDeleteTrainingMutation,
  useGetTrainingsQuery
} from "../../redux/api/training/trainingApi";
import { APPLICATIONS_HEADER_ITEMS, VIDEO_TRAININGS_HEADER_ITEMS } from './DashboardTraining.constants.js';
const DashboardTraining = ({ profil }) => {
  const [type, setType] = useState("Video trainings");
  const [currentPage, setCurrentPage] = useState(1);
  const token = getAccessToken();
  const decodedToken = token ? jwtDecode(token) : null;
  const {
    data: trainingsData,
    error,
    isLoading,
    refetch,
  } = useGetTrainingsQuery(
    `?page=${currentPage}&&type=${type === "Video trainings"
      ? "VIDEOSTRAINING" : "APPLICATIONS"
    }&&userId=${decodedToken?.id}`
  );
  const { data: soldedProductsData } = useGetSoldedProductsQuery(
    `?page=${currentPage}`
  );

  const [showModal, setShowModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [products, setProducts] = useState();
  const [soldedProducts, setSoldedProducts] = useState();
  const [selectedItemHeader, setSelectedItemHeader] = useState(0);
  const [
    deleteItem,
    {
      data: deletedItem,
      error: errorDelete,
      isLoading: isLoadingDelete,
      reset,
    },
  ] = useDeleteTrainingMutation();
  const handleDelete = (e, id) => {
    deleteItem(id);
    refetch();
    reset();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setProducts(trainingsData);
  }, [trainingsData]);
  useEffect(() => {
    setProducts(soldedProductsData);
  }, [soldedProductsData]);

  const navigate = useNavigate();

  const [itemHeaders, setItemHeaders] = useState(VIDEO_TRAININGS_HEADER_ITEMS);
  useEffect(() => {
    switch (type) {
      case "Video trainings":
        setItemHeaders(VIDEO_TRAININGS_HEADER_ITEMS);
        break;
      case "Applications":
        setItemHeaders(APPLICATIONS_HEADER_ITEMS);
        break;
      default:
        setItemHeaders([]);
    }
  }, [type]);

  useEffect(() => {
    console.log('Fetched trainings/applications data:', trainingsData);
  }, [trainingsData]);

  return (
    <div style={{ display: "flex", overflowY: 'hidden', height: '100vh', overflowX: 'hidden' }} >
      <SideBar path={'/applications'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
      <GenericSwitcherLayout
        isSelected={true}
        items={itemHeaders}
        pagination={trainingsData?.pagination}
        type={type}
        setType={setType}
        selectedItemHeader={selectedItemHeader}
        setSelectedItemHeader={setSelectedItemHeader}
        data={trainingsData?.data}
        isLoading={isLoading}
        error={error}
        handleDelete={handleDelete}
        userRole={decodedToken?.role}
      />
    </div>
  );
};

export default DashboardTraining;
