// ProjectContext.js
import React, { createContext, useContext, useState } from "react";

const JoinusContext = createContext();

export const useJoinusContext = () => useContext(JoinusContext);

export const JoinusProvider = ({ children }) => {

  const [chattype, setChattype] = useState();
  const [redirect,setRedirect] =  useState()


  return (
    <JoinusContext.Provider
      value={{
        chattype,
        setChattype,
        redirect,
        setRedirect
      }}
    >
      {children}
    </JoinusContext.Provider>
  );
};
