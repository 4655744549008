import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import photoavatar from "assets/IconITgalaxy/photoavatar.png";
import {useUpdatePhotoMutation} from "../../../../redux/api/api-chat-mongodb/api-chat-mongodbAPI" ;
import {
  InputContainer,
  StayledLabel,
  StyledLeftSideBarImage,
  StyledProfilImgCircle,
  StyledSubmitEditProfileButton,
} from "./styled";
import { getURL, s3Upload } from "../../../../redux/api/uploads/uploadSlice";
import { useDispatch } from "react-redux";
import { fileToBase64 } from "../../../../utils/fileConvertion";

const EditImageProfilEntreprise = ({
  data,
  editModalProfilePictureOpen,
  onSaveChanges,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const [imageUrl, setImageUrl] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // State to hold the preview image URL
  const [updatePhoto] = useUpdatePhotoMutation();



  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const url = await dispatch(
          getURL({
            location: `entreprises/${data?.id}/profil/img-profil/`,
          })
        ) 
        if (url.Contents && url.Contents.length > 0 && url.Contents[0].Key) {
          setPreviewImage(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
        } else {
          setPreviewImage(photoavatar);
        }
      } catch (error) {
        console.error("Error fetching image URL:", error);
        setPreviewImage(photoavatar);
      }
    };

    fetchImageUrl();
}, [dispatch, data.id]); 

  const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result); // Set preview image URL
      };
      reader.readAsDataURL(file);
      setSelectedFile(file); // Set selected file
    }
  };

  const onSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profileImage", selectedFile);
      await dispatch(
        s3Upload({
          file: selectedFile,
          type: "img-profil/",
          base64URL: await fileToBase64(selectedFile),
          location: `entreprises/${data?.id}/profil/`,
        })
      );
      await updatePhoto({ _id: data?.chatid, newPhotoRef: previewImage });
      onSaveChanges(formData);
      onClose();
    }
  };

  return (
    <Modal show={editModalProfilePictureOpen} onHide={onClose} size="s" centered={true} >
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputContainer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                <StyledLeftSideBarImage style={{ position: "relative" }}>
                  <StyledProfilImgCircle src={previewImage} />
                </StyledLeftSideBarImage>
              </InputContainer>
            </Col>
            <Col md={12}>
              <Form.Group controlId="profileImage">
                <Form.Label>Choose Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </Form.Group>
            </Col>
          </Row>
          <StyledSubmitEditProfileButton
            variant="primary"
            type="submit"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            Save Changes
          </StyledSubmitEditProfileButton>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditImageProfilEntreprise;
