import React from "react";
import { StyledInput } from "./CustomTextInput.style";

const CustomTextInput = ({
  value,
  name,
  disabled,
  onChange,
  placeholder,
  defaultValue,
  error // New prop for error message
}) => {
  return (
    <React.Fragment>
      <StyledInput
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
      {/* Display error message if exists */}
      {error && <span className="error-message" style={{ fontSize: '13px', color: 'red' }}>{error}</span>}
    </React.Fragment>
  );
};

export default CustomTextInput;
