import lodash from "lodash";

export const isObjectExist = (object) => {
  return typeof object !== "undefined";
};

export const objectIsEmpty = (object) => {
  return Object.keys(object).length === 0;
};

export const toCamelCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => toCamelCase(item));
  }

  if (data !== null && typeof data === "object") {
    const camelData = {};

    lodash.forEach(data, (value, key) => {
      camelData[lodash.camelCase(key)] = toCamelCase(value);
    });

    return camelData;
  }

  return data;
};

export const toSnakeCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => toSnakeCase(item));
  }

  if (data !== null && typeof data === "object") {
    const snakeData = {};

    lodash.forEach(data, (value, key) => {
      const snakeKey = lodash.snakeCase(key);
      snakeData[snakeKey] = toSnakeCase(value);
    });

    return snakeData;
  }

  return data;
};
