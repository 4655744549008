import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../core/constants/endpoints";
import { baseQueryConfig } from "../BaseQueryConfig";
import { getAccessToken } from "../../../core/helpers/storage";

const customBaseQuery = fetchBaseQuery({
  ...baseQueryConfig,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const sessionTrainingProposalApi = createApi({
  reducerPath: "sessionTrainingProposalApi",
  baseQuery: customBaseQuery,
  tagTypes: ["SESSIONTRAINING"],
  endpoints: (builder) => ({
    // UPDATE ENTREPRISE
    addSessionTrainingProposal: builder.mutation({
      query: (sessionTrainingProposalData) => ({
        url: ENDPOINTS.SESSION_TRAINING_PROPOSAL,
        method: "POST",
        body: sessionTrainingProposalData,
      }),
      invalidatesTags: ["SESSIONTRAINING"],
    }),
    updateSessionTrainingProposal: builder.mutation({
      query: ({ id, sessionTrainingProposalData }) => ({
        url: `${ENDPOINTS.SESSION_TRAINING_PROPOSAL}/${id}`,
        method: "PATCH",
        body: sessionTrainingProposalData,
      }),
      providesTags: ["SESSIONTRAINING"],
    }),
    // Get ENTREPRISE
    getSessionTrainingProposals: builder.query({
      query: (params) => ({
        url: `${ENDPOINTS.SESSION_TRAINING_PROPOSAL}/${params}`,
      }),
    }),
    getSessionTrainingProposalById: builder.query({
      query: (id) => ({
        url: `${ENDPOINTS.SESSION_TRAINING_PROPOSAL}/${id}`,
      }),
    }),
    deleteSessionTrainingProposal: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.SESSION_TRAINING_PROPOSAL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SESSIONTRAINING"],
    }),
  }),
});

// Export hooks for using the API endpoints
export const {
  useAddSessionTrainingProposalMutation,
  useUpdateSessionTrainingProposalMutation,
  useGetSessionTrainingProposalsQuery,
  useGetSessionTrainingProposalByIdQuery,
  useDeleteSessionTrainingProposalMutation,
} = sessionTrainingProposalApi;
