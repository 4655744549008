import {
  DashboardCompanyRoutes,
  DashboardFreelancerRoutes,
} from "core/constants/routes.constants";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAccessToken } from "../core/helpers/storage";
import {
  useAddTrainingMutation,
  useUpdateTrainingMutation,
} from "../redux/api/training/trainingApi";
import { getFileFromS3 } from "../redux/api/uploads/uploadSlice";
import { addProxy } from "../redux/slice/proxySlice/proxySlice";
import { extractFileAttributes } from "../utils/fileConvertion";
import { useState } from "react";


// EXAMPLE : useHandleSubmitCompany

const useHandleSubmit = (
  selectedElement,
  setSelectedElement,
  setConfirmShow,
  openModalRegister,
  setOpenModalRegister,
  isEdit
) => {
  const [saveTraining] = useAddTrainingMutation();
  const [updateTraining, { data, error, isLoading, reset }] =
    useUpdateTrainingMutation();
  const userType = useSelector((state) => state.user.type);
  const isFreelancer = userType === "user";
  const isCompany = userType === "company";
  const [loading, setLoading] = useState(false); // State to control loading
  const getVideosFile = async (video) => {
    return await extractFileAttributes(
      await getFileFromS3(video.videoUrl),
      video.videoUrl
    );
  };
  const training = useSelector((state) => state.trainingCreation);
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = getAccessToken();
  const decodeToken = token ? jwtDecode(token) : null;
  const navigate = useNavigate();
  const convertJSONtoFile = (jsonFile) => {
    const content = new Blob([" ".repeat(jsonFile?.size)], {
      type: jsonFile?.type,
    });
    const file = new File([content], jsonFile?.name, {
      lastModified: jsonFile?.lastModified,
      type: jsonFile?.type,
      lastModifiedDate: jsonFile?.lastModifiedDate,
      webkitRelativePath: jsonFile?.webkitRelativePath,
      size: jsonFile?.size,
    });
    return file;
  };

  const handleSubmit = async () => {
    let data;
    // if (token) {
    //   setSelectedElement(selectedElement + 1);
    //   if (!isEdit) {
    //     data = await saveTraining({
    //       type: training?.type_product,
    //       title: training?.details?.title,
    //       description: training?.details?.description,
    //       userId: decodeToken?.id,
    //       skills: training?.details?.skills?.map((skill) => skill?.title),
    //       languages: "FRANCAIAS",
    //       price: training?.price?.price,
    //       currency: "USD",
    //       duration: training?.details?.nbDays,
    //       duration_type: "minute",
    //     });
    //   } else {
    //     let body = {
    //       type: training?.type_product,
    //       title: training?.details?.title,
    //       description: training?.details?.description,
    //       userId: decodeToken?.id,
    //       skills: training?.details?.skills?.map((skill) => skill?.title),
    //       languages: "FRANCAIAS",
    //       price: training?.price?.price,
    //       currency: "USD",
    //       duration: training?.details?.nbDays,
    //       duration_type: "minute",
    //     };

    //     data = await updateTraining({ id, body });
    //     reset();
    //   }
    //   let basePath = "";
    //   switch (training.type_product) {
    //     case "VIDEOSTRAINING":
    //       basePath = `videos_training/${data?.data?.id}/`;
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(training?.files?.fileData),
    //           type: "support/",
    //           location: basePath,
    //           base64URL: training?.files?.base64URL,
    //         })
    //       );
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(
    //             training?.exercices.exercice?.exerciceData
    //           ),
    //           type: "exercice/",
    //           location: basePath,
    //           base64URL: training?.exercices?.exercice?.base64URL,
    //         })
    //       );
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(training?.details?.image?.file),
    //           type: "image/",
    //           location: basePath,
    //           base64URL: training?.details?.image?.base64URL,
    //         })
    //       );
    //       training.videos.map((video, index) => {
    //         dispatch(
    //           s3Upload({
    //             file: convertJSONtoFile(video?.videoFile),
    //             type: `video/${index}/`,
    //             location: basePath,
    //             base64URL: video?.base64URL,
    //             metadata: {
    //               title: video.titleVideo,
    //               description: video.descriptionVideo,
    //             },
    //           })
    //         );
    //       });
    //       break;
    //     case "SESSIONTRAINING":
    //       basePath = `session_training/${data?.data?.id}/`;
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(training?.files?.fileData),
    //           type: "support/",
    //           location: basePath,
    //           base64URL: training?.files?.base64URL,
    //         })
    //       );
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(training?.details?.image),
    //           type: "image/",
    //           location: basePath,
    //           base64URL: training?.details?.image?.base64URL,
    //         })
    //       );
    //       training.videos.map((video, index) => {
    //         dispatch(
    //           s3Upload({
    //             file: convertJSONtoFile(video?.videoFile),
    //             type: `video/${index}/`,
    //             location: basePath,
    //             base64URL: video?.base64URL,
    //             metadata: {
    //               title: video.titleVideo,
    //               description: video.descriptionVideo,
    //             },
    //           })
    //         );
    //       });
    //       break;
    //     case "APPLICATIONS":
    //       basePath = `applications/${data?.data?.id}/`;
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(
    //             training?.exercices?.exercice?.exerciceData
    //           ),
    //           type: "exercice/",
    //           location: basePath,
    //           base64URL: training?.exercices?.exercice?.base64URL,
    //         })
    //       );
    //       dispatch(
    //         s3Upload({
    //           file: convertJSONtoFile(training?.details?.image?.file),
    //           type: "image/",
    //           location: basePath,
    //           base64URL: training?.details?.image?.base64URL,
    //         })
    //       );
    //       training.videos.map(async (video, index) => {
    //         dispatch(
    //           s3Upload({
    //             file: convertJSONtoFile(video?.videoFile),
    //             type: `video/${index}/`,
    //             location: basePath,
    //             base64URL: video?.base64URL,
    //             metadata: {
    //               title: video.titleVideo,
    //               description: video.descriptionVideo,
    //             },
    //           })
    //         );
    //       });
    //       dispatch(clearVideosTraining());

    //       break;
    //   }
    setLoading(true); // Start loading
    let response;
    let success = false;

    try {
      response = await saveTraining(/*requestproject*/);           
      success = true;
      return {
        success: success,
        url: 'products',     
        sendMessageToTopic: false,
        productType: 'PRODUCT',
        productId: response.data.id,
        payerId: response.data.userId,
        payerType: 'CANDIDAT',
        
        //data invoicing

      };

    } catch (error) {
      // Handle error properly (you can also set error state if needed)
      console.error('Error during proposal creation:', error);

    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
    // } else setOpenModalRegister(true);
  };
  return { handleSubmit };
};

export default useHandleSubmit;
