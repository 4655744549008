import { ReactComponent as TiktokIcon } from "../../../../assets/IconITgalaxy/tiktokIcon.svg";
import { ReactComponent as XIcon } from "../../../../assets/IconITgalaxy/XIcon.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/IconITgalaxy/instagramIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../../assets/IconITgalaxy/linkedInIcon.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/IconITgalaxy/youtubeIcon.svg";
import { ReactComponent as PinterestIcon } from "../../../../assets/IconITgalaxy/pinterestIcon.svg";

export const getSocialMediaIcon = (id) => {
  switch (id) {
    case 1:
      return <TiktokIcon />;
    case 2:
      return <XIcon />;
    case 3:
      return <InstagramIcon />;
    case 4:
      return <LinkedInIcon />;
    case 5:
      return <YoutubeIcon />;
    case 6:
      return <PinterestIcon />;
    default:
      return null;
  }
};
