export const languagesData = [

      { "id": 1, "title": "Arabe" },
      { "id": 2, "title": "Spanish" },
      { "id": 3, "title": "English" },
      { "id": 4, "title": "Hindi" },
      { "id": 5, "title": "Arabic" },
      { "id": 6, "title": "Bengali" },
      { "id": 7, "title": "Portuguese" },
      { "id": 8, "title": "Russian" },
      { "id": 9, "title": "Japanese" },
      { "id": 10, "title": "Punjabi" },
      { "id": 11, "title": "German" },
      { "id": 12, "title": "Javanese" },
      { "id": 13, "title": "Chinese" },
      { "id": 14, "title": "Malay" },
      { "id": 15, "title": "Telugu" },
      { "id": 16, "title": "Vietnamese" },
      { "id": 17, "title": "Korean" },
      { "id": 18, "title": "French" },
      { "id": 19, "title": "Tamil" },
      { "id": 20, "title": "Turkish" },
      { "id": 21, "title": "Italian" },
      { "id": 30, "title": "Malayalam" },
      { "id": 32, "title": "Albanian" },
      { "id": 33, "title": "Serbian" },
      { "id": 35, "title": "Bulgarian" },
      { "id": 36, "title": "Czech" },
      { "id": 37, "title": "Swedish" },
      { "id": 38, "title": "Hungarian" },
      { "id": 39, "title": "Finnish" },
      { "id": 40, "title": "Norwegian" },
      { "id": 41, "title": "Danish" },
      { "id": 47, "title": "Galician" }
    ]
  