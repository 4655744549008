import {
  CopyObjectCommand,
  DeleteObjectsCommand,
  GetObjectCommand,
  GetObjectTaggingCommand,
  ListObjectsV2Command,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { createSlice } from "@reduxjs/toolkit";
import { addImageURL } from "../../slice/TrainingCreation/trainingCreationSlice";

// Fetch AWS configuration from environment variables
const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESSKEYID;
const secretAccessKey = process.env.REACT_APP_AWS_S3_SECRETACCESSKEY;
const awsregion = process.env.REACT_APP_AWS_S3_REGION;
const BucketS3 = process.env.REACT_APP_AWS_S3_BUCKET;
const url_bucket = process.env.REACT_APP_URL_BUCKET;

export const UploadSlice = createSlice({
  name: "Upload",
  initialState: {
    uploading: false,
    success: false,
    error: null,
    fileUrl: "",
  },
  reducers: {
    uploadStart: (state) => {
      state.uploading = true;
      state.success = false;
      state.error = null;
    },
    uploadSuccess: (state, action) => {
      state.uploading = false;
      state.success = true;
      state.fileUrl = action.payload;
    },
    uploadFailure: (state, action) => {
      state.uploading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

// Initialize the S3 client
const s3 = new S3Client({
  region: awsregion,
  credentials: {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  },
});

// Function to list objects in a specified S3 location
export const listObjectsV2Command = async (s3, location) => {
  const listCommand = new ListObjectsV2Command({
    Bucket: BucketS3,
    Prefix: location,
  });
  return await s3.send(listCommand);
};

// Function to delete objects from S3
export const deleteOldProfil = async (s3, list) => {
  if (list.KeyCount > 0) {
    const deleteCommand = new DeleteObjectsCommand({
      Bucket: BucketS3,
      Delete: {
        Objects: list.Contents.map((item) => ({ Key: item.Key })),
        Quiet: false,
      },
    });

    const deleted = await s3.send(deleteCommand);
    if (deleted.Errors) {
      deleted.Errors.forEach((error) =>
        console.log(`${error.Key} could not be deleted - ${error.Code}`)
      );
    }
  }
};

// Function to upload files to S3
const UploadToS3 = async (file, type, base64URL, metadata, location) => {
  const list = await listObjectsV2Command(s3, location + type);
  await deleteOldProfil(s3, list);

  const command = new PutObjectCommand({
    Bucket: BucketS3,
    Key: location + type + file.name,
    Body: base64URL,
    ContentDisposition: "inline",
    ContentType: file.type,
    ...(metadata && {
      ContentEncoding: "base64",
      Tagging: `titleVideo=${metadata.title}&descriptionVideo=${metadata.description}`,
    }),
  });

  try {
    await s3.send(command);
  } catch (err) {
    console.error("Error uploading to S3:", err);
    throw err;
  }

  return url_bucket + location + type + file.name;
};

// Redux action creators
export const { uploadStart, uploadSuccess, uploadFailure } = UploadSlice.actions;

// Function to handle S3 uploads
export const s3Upload =
  ({ file, type, location, base64URL, metadata }) =>
  async (dispatch) => {
    dispatch(uploadStart());
    try {
      const imageUrl = await UploadToS3(file, type, base64URL, metadata, location);
      dispatch(uploadSuccess(imageUrl));
      dispatch(addImageURL(imageUrl));
    } catch (error) {
      dispatch(uploadFailure(error.message));
    }
  };

// Function to get objects from S3
export const getURL =
  ({ location }) =>
  async (dispatch) => {
    const list = await listObjectsV2Command(s3, location);
    return list;
  };

// Function to get a file from S3
export const getFileFromS3 = async (fileKey) => {
  try {
    const params = {
      Bucket: BucketS3,
      Key: fileKey,
    };

    const data = await s3.send(new GetObjectCommand(params));
    const fileBlob = new Blob([await streamToBuffer(data.Body)], { type: data.ContentType });
    const file = new File([fileBlob], data.ContentLength, {
      type: data.ContentType,
    });

    return file;
  } catch (error) {
    console.error("Error getting file from S3:", error);
    throw error;
  }
};

// Helper function to convert ReadableStream to Buffer
const streamToBuffer = async (stream) => {
  const chunks = [];
  for await (const chunk of stream) {
    chunks.push(chunk);
  }
  return Buffer.concat(chunks);
};

// Function to get object tags from S3
export const getObjectTags = async (objectKey) => {
  try {
    const command = new GetObjectTaggingCommand({
      Bucket: BucketS3,
      Key: objectKey,
    });

    const data = await s3.send(command);
    console.log(
      "tags",
      data.TagSet.map((tag) => tag.Value)
    );
    return data.TagSet.map((tag) => tag.Value);
  } catch (error) {
    console.error("Error getting object tags:", error);
    return [];
  }
};

// Function to check if a folder is empty in S3
export const checkIfFolderIsEmpty = async (prefix) => {
  try {
    const command = new ListObjectsV2Command({
      Bucket: BucketS3,
      Prefix: prefix,
      MaxKeys: 1, // We only need to check if at least one object exists
    });

    const response = await s3.send(command);

    return response.Contents.length === 0;
  } catch (error) {
    console.error("Error checking if folder is empty:", error);
    return false; // Return false if there's an error
  }
};


export const copyFilesRecursively = async (sourcePrefix, destinationPrefix) => {
  try {
      // Fetch files and subfolders from the specified prefix
      const folderData = await s3.send(
          new ListObjectsV2Command({
              Bucket: BucketS3,
              Prefix: sourcePrefix,
          })
      );

      if (!folderData.Contents || folderData.Contents.length === 0) {
          return;
      }

      // Iterate over each item in the folder
      for (const item of folderData.Contents) {
          const sourceKey = item.Key;
          const destinationKey = sourceKey.replace(sourcePrefix, destinationPrefix); // Change the prefix for the destination

          // Execute the copy command
          await s3.send(new CopyObjectCommand({
              Bucket: BucketS3,
              CopySource: `${BucketS3}/${sourceKey}`,
              Key: destinationKey,
          }));
      }

  } catch (error) {
      console.error("Error copying files: ", error);
  }
};


export default UploadSlice.reducer;
