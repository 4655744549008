import {
  RootStyle,
  ContainerStyle,
  TypographyStyle,
  ImageStyle,
} from "./RecruitmentCard.style";
import image from "../../../../assets/IconITgalaxy/recImage.png";

function RecruitmentCard({message}) {
  return (
    <RootStyle>
      <ContainerStyle>
        <TypographyStyle>{message}</TypographyStyle>
        <ImageStyle src={image}></ImageStyle>
      </ContainerStyle>
    </RootStyle>
  );
}

export default RecruitmentCard;
