import React from "react"; 
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import { formations } from "utils/fake_data"; 
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import {
  ButtonStyle,
  SearchBar,
  SearchFilterBar,
  SearchFilter,
  Container,
  StyledSearchBarContainer,
  Wrapper,
  ContainerWrapper,
  CreateTrainingButtonContainer,
  SearchFilterSkills
} from "./ListingMyTrainer.style";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { formConfig } from "./ListingMyTrainer.config";

const ListingMyTrainer = (props) => {
  const formMethods = useForm({});
  
  const handleNavigate = (id) => {
    console.log("Navigating to:", id);
  };

  const handelTrainerForm = () => {

    props.setTrainerForm(true) ;
  }

  return (
    <Wrapper>
      <ContainerWrapper className="container">
        <SearchBar>
          <FormProvider {...formMethods}>
            <StyledSearchBarContainer>
            <SearchFilterBar> 
              <GenericInput
                inputObject={{ ...formConfig.title, label: "Filter by Title" }}
                disabledForm={false}
              />
              </SearchFilterBar>
              <SearchFilterSkills>
              <GenericInput
                inputObject={{ ...formConfig.skills, label: "Filter by Skills" }}
                disabledForm={false}
              /> 
              </SearchFilterSkills>
             <SearchFilter>
              <GenericInput
                inputObject={{ ...formConfig.type, label: "Filter by Type" }}
                disabledForm={false}
              />
                </SearchFilter>
                <CreateTrainingButtonContainer onClick={handelTrainerForm}>
                Create a Product
              </CreateTrainingButtonContainer>
            </StyledSearchBarContainer>

          </FormProvider>
        </SearchBar>
        <Container>
          {formations && formations.length > 0 ? (
            formations.map((value) => (
              <FormationsCard
                key={value.id}
                formation={value}
                animateDirection="fade-in" // Assuming you handle animations via this prop
                onClick={() => handleNavigate(value.id)}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", margin: "20px" }}>
              <h2>No Available Formations</h2>
              <p>Please check back later or contact support for more information.</p>
            </div>
          )}
        </Container>
      </ContainerWrapper>
    </Wrapper>
  );
}

export default ListingMyTrainer;
