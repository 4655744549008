// CourseModules.jsx
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
`;

const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
`;

const ModulesCard = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 0px 0px black;
`;

const ModuleRow = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #f9f9f9;
  }
`;

const ModuleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
`;

const ModuleSubTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin-top: 5px;
`;

const ModuleDetails = styled.div`
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
  line-height: 1.5;
`;

const DetailsText = styled.p`
  margin-bottom: 10px;
`;

const IncludedItems = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  margin-top: 10px;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
`;

const Arrow = styled.span`
  font-size: 10px;
  color: black;
`;

const CourseModules = () => {
  const [expandedModule, setExpandedModule] = useState(null);

  const toggleModule = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  const modules = [
    {
      id: 1,
      title: "Chapter One - Why we Program?",
      subtitle: "Module 1 • 1 hour to complete",
      details: "In this module, you'll be introduced to the importance of programming and understand why learning to code can be beneficial for problem-solving in various fields.",
      included: { videos: 3, quizzes: 1, readings: 1 }
    },
    {
      id: 2,
      title: "Installing Python",
      subtitle: "Module 2 • 2 hours to complete",
      details: "This module covers the steps to install Python on your computer. You will learn about different operating systems and how to set up the environment for Python programming.",
      included: { videos: 2, quizzes: 1, readings: 1 }
    },
    {
      id: 3,
      title: "Chapter One: Why We Program (continued)",
      subtitle: "Module 3 • 3 hours to complete",
      details: "This course aims to teach everyone the basics of programming computers using Python. We cover the basics of how one constructs a program from a series of simple instructions in Python. The course has no pre-requisites and avoids all but the simplest mathematics.",
      included: { videos: 4, quizzes: 1, readings: 2 }
    },
    {
      id: 4,
      title: "Chapter Two: Variables and Expressions",
      subtitle: "Module 4 • 3 hours to complete",
      details: "This module dives into variables and expressions. You will learn how to store information in variables and use expressions to manipulate that information.",
      included: { videos: 3, quizzes: 1, readings: 2 }
    },
    {
      id: 5,
      title: "Chapter Three: Conditional Code",
      subtitle: "Module 5 • 3 hours to complete",
      details: "Learn about conditional statements, such as if-else structures, to make decisions in your code. This module helps you write programs that can respond differently based on different inputs.",
      included: { videos: 3, quizzes: 1, readings: 1 }
    },
    {
      id: 6,
      title: "Chapter Four: Functions",
      subtitle: "Module 6 • 2 hours to complete",
      details: "Functions are a crucial part of programming. This module will teach you how to define and use functions to organize your code and make it more modular and reusable.",
      included: { videos: 3, quizzes: 1, readings: 1 }
    },
    {
      id: 7,
      title: "Chapter Five: Loops and Iteration",
      subtitle: "Module 7 • 2 hours to complete",
      details: "In this module, you'll learn about loops and iteration. Understand how to repeat tasks in your code using for and while loops, making your programs more efficient.",
      included: { videos: 2, quizzes: 1, readings: 1 }
    }
  ];


  return (
    <Container>
      <Header>There are 7 modules in this course</Header>
      <Description>
        This course aims to teach everyone the basics of programming computers using Python. We cover the basics of how one constructs a program from a series of simple instructions in Python. The course has no pre-requisites and avoids all but the simplest mathematics. Anyone with moderate computer experience should be able to master the materials in this course. This course will cover Chapters 1-5 of the textbook "Python for Everybody". Once a student completes this course, they will be ready to take more advanced programming courses. This course covers Python 3.
      </Description>
      <ModulesCard>
        {modules.map((module) => (
          <ModuleRow key={module.id} onClick={() => toggleModule(module.id)}>
            <ModuleTitle>
              {module.title}
              <Arrow>Module Details {expandedModule === module.id ? '∧' : '∨'}</Arrow>
            </ModuleTitle>
            <ModuleSubTitle>{module.subtitle}</ModuleSubTitle>

            {expandedModule === module.id && module.details && (
              <ModuleDetails>
                <DetailsText>{module.details}</DetailsText>
                <IncludedItems>
                  <IconText>📹 {module.included.videos} videos</IconText>
                  <IconText>📝 {module.included.quizzes} quiz</IconText>
                  <IconText>📖 {module.included.readings} readings</IconText>
                </IncludedItems>
              </ModuleDetails>
            )}
          </ModuleRow>
        ))}
      </ModulesCard>
    </Container>
  );
};

export default CourseModules;
