import { Button } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import AddFolderApplication from "../AddFolderApplication/AddFolderApplication";
import { ChapterTitle, StyledLabel } from "../TrainerForm/TraininerForm.style";
import VideoUpload from "../VideoUpload/VideoUpload";
import { formConfig } from "./ChapterContent.constants";
import { ContentFormContainer } from "./ChapterContent.style";

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-top: 20px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &.secondary {
    background-color: #6c757d;
    &:hover {
      background-color: #5a6268;
    }
  }
`;

export default function ChapterContent({
  formMethods,
  onValidate,
  onCancel,
  currentSubModule,
  onConfirmChapter,
  isLastChapter,
  onNextStep
}) {
  const handleConfirmChapter = () => {
    // Here you would validate and save the chapter data
    onConfirmChapter();
  };

  const handleNextStep = () => {
    onNextStep();
  };

  return (
    <>
      <ChapterTitle>Chapter {currentSubModule?.id}</ChapterTitle>
      <FormProvider {...formMethods}>
        <ContentFormContainer>
          <StyledLabel>1. Chapter title</StyledLabel>
          <GenericInput inputObject={{ ...formConfig.title }} disabledForm={false} />
          <StyledLabel>2. Chapter Description</StyledLabel>
          <GenericInput inputObject={{ ...formConfig.description }} disabledForm={false} />
          <StyledLabel>3. Chapter Duration (hours)</StyledLabel>
          <GenericInput inputObject={{ ...formConfig.durationTraining }} disabledForm={false} />
          <StyledLabel>4. Chapter Video</StyledLabel>
          <VideoUpload />
          <StyledLabel>5. Chapter Support</StyledLabel>
          <AddFolderApplication formMethods={formMethods} />

          <ButtonContainer>
            <StyledButton onClick={handleConfirmChapter}>
              Confirm & Next Chapter
            </StyledButton>
            {isLastChapter && (
              <StyledButton
                onClick={handleNextStep}
                className="secondary"
              >
                Next Step
              </StyledButton>
            )}
          </ButtonContainer>
        </ContentFormContainer>
      </FormProvider>
    </>
  );
}
