import { Button } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0; // light gray bottom border
`;

export const SearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.5%;
  margin-left: 2%;
  margin-bottom: 1%;
`;

// display flex the first element will take 80 percent of the width and the other 2 will take 10% each
export const StyledSearchBarContainer = styled.div`
  display: flex;
  width: 90%;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
  & > *:nth-child(1) {
    flex-basis: 50%;
  }

  & > *:nth-child(2) {
    flex-basis: ${(props) => (props.childCount === 4 ? "10%" : "25%")};
  }

  & > *:nth-child(3) {
    flex-basis: ${(props) => (props.childCount === 4 ? "20%" : "25%")};
  }

  & > *:nth-child(4) {
    flex-basis: 20%;
  }
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ButtonContainerStyle = styled(Button)`
  border-radius: 10px;
`;

export const TopContainer2 = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
`;
export const VerticalLine = styled.div`
  border-left: 2px solid black;
  height: 100vh;
  margin: 20px;
`;
export const TopElementSwitchContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 40vh;
`;

export const ButtonStyle = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  font-weight: ${({ selected }) =>
    selected ? "600" : "400"}; // bold if selected
  color: ${({ selected }) =>
    selected ? "#000000" : "#A0A0A0"}; // black if selected, gray otherwise
  margin-right: 20px; // space between buttons
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${({ selected }) =>
      selected ? "#000000" : "transparent"}; // underline if selected
  }

  &:hover {
    color: #000000; // turn black on hover
  }
`;

export const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.spacing * 8}px;
  overflow: auto;
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    align-items: start;
    justify-content: start;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 53px; /* Space between grid items */
    padding-left: 2px;
    width: 100%; /* Ensure full width */
    height: 100%; /* Auto-height */
    margin: 0; /* Remove any margins */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: hidden; /* Allow vertical scrolling */
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  min-height: 100vh; /* Full height to avoid padding issues */
  background: #f3f3f3; /* Background color */
  width: 100%; /* Full width */
`;

export const ContainerWrapper = styled.div`
  padding-top: 30px;
  width: 100%;
  max-width: 100%; /* Prevents any max width constraints */
  padding-left: 150px;
`;
