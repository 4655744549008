import ItGalaxyChat from '@Itgalaxy1/itgalaxychat';
import { IntlProvider } from 'react-intl';
import './base.css';
import React, { useEffect, useState } from "react";


const ChatItGalaxy = ({
  username,
  channelsList,
  projectId,
  projectType,
  setShowModalDetails,
  setChannelSelected,
  setIds,
  type,
  setLoading
}) => {
  const language = 'en';

  

  const handeldetails = (channelSelectedData) => {

    // Regular Expressions to capture the respective parts
    const regexId1 = /(COMPOSED_FREELANCE|SHARETASK|Contrat|PRODUCT|Recruitment|Contactor|AccountingJob)(\d+)/;
    const regexId2 = /(proposalentreprise|proposal|appeloffreproposal|soldedproduct|contactedCANDIDAT|recruted|contactedENTREPRISE|contactedRECRUTER|workingFolderCOMPANY|workingFolderREQUEST_COMPANY_CREATION)(\d+)/g;

    // Match projectId based on the first regex
    const id1Match = channelSelectedData.match(regexId1); 
    // Match all occurrences for proposalId from the second regex
    const id2Matches = [...channelSelectedData.matchAll(regexId2)]; 

    // Capture the type and numeric part for projectId
    const extractedType = id1Match ? id1Match[1] : 'Not found'; // Type 
    const extractedProjectId = id1Match ? id1Match[2] : 'Not found'; // Numeric part 
    // Get last matched numeric part for proposalId
    const extractedProposalId = id2Matches.length > 0 ? id2Matches[id2Matches.length - 1][2] : 'Not found'; // Last match 

    // Set the extracted values in state
    setIds({ type: extractedType, projectId: extractedProjectId, proposalId: extractedProposalId });
    setShowModalDetails(true);

  };


  return (
    <IntlProvider locale={language} textComponent={React.Fragment}>
      <ItGalaxyChat 
        username={username} 
        setChannelSelected={setChannelSelected} 
        channelsList={channelsList} 
        projectId={projectId} 
        projectType={projectType} 
        handeldetails={handeldetails}  
        type={type}
        setLoading={setLoading} 
      />
    </IntlProvider>
  );
};

export default ChatItGalaxy;
