import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput.jsx";
import VideoUpload from "components/DashboardTrainerComponnents/TrainerComponnents/VideoUpload/VideoUpload.jsx";
import { formConfig } from "./ShowModalVideo.constants.js";
import VideoUploadFile from "components/DashboardTrainerComponnents/TrainerComponnents/VideoUpload/VideoUploadFile.jsx";
import { VideoPreview } from "components/DashboardTrainerComponnents/TrainerComponnents/VideoUpload/VideoUploadFile.style.js";
import { useDispatch } from "react-redux";
import { StyledLabel } from "components/DashboardTrainerComponnents/TrainerComponnents/TrainerForm/TraininerForm.style";

import {
  addVideoTraining,
  editVideoTraining,
} from "../../../redux/slice/TrainingCreation/trainingCreationSlice.js";
import { CDBSidebarMenuItem } from "cdbreact";

const ShowModalVideo = ({ show, closeModal, setShow, isEdit, selectedVideo }) => {
  const dispatch = useDispatch();
  const [showprofils, setshowprofils] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [videoUrl, setVideoURL] = useState("");
  const [videoFile, setVideoFile] = useState("");

  const reciveVideoURl = (data) => {
    setVideoURL(data);
  };
  const reciveVideoFile = (data) => {
    setVideoFile(data);
  };
  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const handleChange = (e) => {
    console.log('======e=', e);
  };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const arrayBuffer = reader.result;
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const base64URL = await fileToBase64(videoFile);
    !isEdit
      ? dispatch(
        addVideoTraining({
          ...formMethods.getValues(),
          videoUrl,
          title: formMethods.getValues("titleVideo"),
          description: formMethods.getValues("descriptionVideo"),
          base64URL,
          videoFile: {
            path: videoFile?.path,
            lastModified: videoFile?.lastModified,
            lastModifiedDate: videoFile?.lastModifiedDate,
            name: videoFile?.name,
            size: videoFile?.size,
            type: videoFile?.type,
            webkitRelativePath: videoFile?.webkitRelativePath,
          },
        })
      )
      : dispatch(
        addVideoTraining({
          ...formMethods.getValues(),
          videoUrl: selectedVideo?.videoUrl,
          title: formMethods.getValues("titleVideo"),
          description: formMethods.getValues("descriptionVideo"),
          base64URL,
          videoFile: {
            path: videoFile?.path,
            lastModified: videoFile?.lastModified,
            lastModifiedDate: videoFile?.lastModifiedDate,
            name: videoFile?.name,
            size: videoFile?.size,
            type: videoFile?.type,
            webkitRelativePath: videoFile?.webkitRelativePath,
          },
        })
      );

    setShow(false);
  };

  return (
    <Modal show={show} onHide={closeModal} centered >
      <Modal.Header >
        <Modal.Title>
          {isEdit ? "Update video" : "Add new video"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <FormProvider {...formMethods}>
        <StyledLabel>1. Video title</StyledLabel>
          <GenericInput
            inputObject={{
              ...formConfig.title,
            }}
            disabledForm={false}
          />
          {isEdit && (
            <CDBSidebarMenuItem
              onClick={() => setIsDeleted(true)}
              icon="fa fa-trash"
            ></CDBSidebarMenuItem>
          )}
         <StyledLabel>3. Upload Video </StyledLabel>

          {!selectedVideo?.videoUrl || isDeleted ? (
            <VideoUploadFile
              sendVideoUrlToParent={reciveVideoURl}
              sendVideoFileToParent={reciveVideoFile}
            />
          ) : (
            <VideoPreview src={selectedVideo?.videoUrl} controls />
          )}
        </FormProvider>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Annuler
        </Button>
        <Button
          variant="primary"
          disabled={
            !formMethods.getValues("titleVideo") &&
            !formMethods.getValues("descriptionVideo") &&
            !videoUrl
          }
          onClick={handleSubmit}
        >
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShowModalVideo;
